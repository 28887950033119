import { FC, useEffect, useMemo, useState } from 'react';
import { useCustomer, useNewHttpClient } from 'hooks';
import { processLogs } from './helper';
import { CUSTOMER_API } from 'configs/api';
import { IListResponse } from 'types/common';
import { ICariUnlimitedSubscriptionLogEntry } from './types';
import { Card, Empty, Timeline, TimelineItemProps } from 'antd';

const CariUnlimitedHistoryTab: FC = () => {
	const { customerId } = useCustomer();

	const orderListRequestConfig = useMemo(() => CUSTOMER_API.getSubscriptionLog(customerId), [customerId]);

	const [timeLineItems, setTimeLineItems] = useState<TimelineItemProps[]>([]);

	// ! http client
	const fetchHttpClient = useNewHttpClient<IListResponse<ICariUnlimitedSubscriptionLogEntry>>();

	// ! handlers

	// ! useEffects
	useEffect(() => {
		fetchHttpClient.request({
			requestConfig: orderListRequestConfig,
			successCallback: (response) => {
				setTimeLineItems(processLogs(response));
			},
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [orderListRequestConfig]);

	return (
		<>
			<Card loading={fetchHttpClient.isLoading}>
				{!timeLineItems.length && <Empty />}

				<Timeline
					mode='left'
					items={timeLineItems}
				/>
			</Card>
		</>
	);
};

export default CariUnlimitedHistoryTab;
