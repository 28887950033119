import { THttpRequestParams } from 'hooks';
import { DEFAULT_PAGINATOR_HEIGHT } from './configs';
import { Nullable } from 'types/common';
import { LAYOUT_CONTENT_ID } from 'pages/Layout/configs';
import { ColumnGroupType, ColumnType } from 'antd/es/table';
import { SortOrder } from 'antd/es/table/interface';

export const applyColumnConfigItemFilters = <T>(
	columnConfigItem: ColumnGroupType<T> | ColumnType<T>,
	params: URLSearchParams
) => {
	if (!columnConfigItem.filters?.length) return columnConfigItem;

	const filteredValue = params.getAll(`${columnConfigItem.key}`);
	if (!filteredValue) return columnConfigItem;

	return { ...columnConfigItem, filteredValue };
};

export const applyColumnConfigItemSorter = <T>(
	columnConfigItem: ColumnGroupType<T> | ColumnType<T>,
	params: URLSearchParams
) => {
	if (!columnConfigItem.sorter) return columnConfigItem;

	const sortByParamValue = params.get(`sort_by`);
	const sortOrderParamValue = params.get(`order_by`);

	if (!sortByParamValue || !sortOrderParamValue) return { ...columnConfigItem, sortOrder: null };

	if (sortByParamValue !== columnConfigItem.key) return columnConfigItem;

	const sortOrder: SortOrder = sortOrderParamValue === 'ASC' ? 'ascend' : 'descend';

	return { ...columnConfigItem, sortOrder };
};

export const removeEmptySearchParams = (groupedSearchParams: THttpRequestParams) => {
	return Object.entries(groupedSearchParams).reduce<THttpRequestParams>((acc, [key, value]) => {
		// valid for array and string, not include if no length
		if (!value?.length) return acc;

		// length is checked before. If this is string - it is valid
		if (typeof value === 'string') acc[key] = value;

		// filter array value to remove empty strings from it
		if (Array.isArray(value)) {
			const filteredArray = value.filter(Boolean);
			if (filteredArray.length) acc[key] = filteredArray;
		}

		return acc;
	}, {});
};

export const getOnlyPrefixedParams = (groupedParams: THttpRequestParams, tableSearchParamPrefix: string) => {
	const tablePrefixLength = tableSearchParamPrefix?.length;

	return Object.entries(groupedParams).reduce<THttpRequestParams>((acc, [key, value]) => {
		if (key.startsWith(tableSearchParamPrefix)) {
			acc[key.slice(tablePrefixLength)] = value;
		}

		return acc;
	}, {});
};

export const calculateTableHeight = (tableElement: Nullable<HTMLDivElement>) => {
	if (!tableElement) return;

	const { top } = tableElement.getBoundingClientRect();
	const pageContainer = tableElement.closest(`#${LAYOUT_CONTENT_ID}`);

	if (!pageContainer) return;

	const tableHeaderHeight = tableElement.querySelector('thead')?.clientHeight ?? 0;
	const pageContainerTop = pageContainer?.getBoundingClientRect().top ?? 0;

	const topHeight = top - pageContainerTop;
	const pageContainerHeight = pageContainer.clientHeight ?? 0;

	const newHeight = pageContainerHeight - tableHeaderHeight - topHeight - DEFAULT_PAGINATOR_HEIGHT;

	const tableBodyHeight = tableElement.querySelector('tbody')?.clientHeight ?? 0;

	return tableBodyHeight > newHeight ? newHeight : undefined;
};
