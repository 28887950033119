import { Property } from 'csstype';
import { ECustomerWalletTransaction } from 'types/api';

export const WALLET_TRANSACTION_TYPE_COLORS: Record<ECustomerWalletTransaction, Property.Color> = {
	[ECustomerWalletTransaction.CREDIT]: 'green',
	[ECustomerWalletTransaction.DEBIT]: 'orange',
	[ECustomerWalletTransaction.DECLINED]: 'red',
	[ECustomerWalletTransaction.REFUND]: 'blue',
	[ECustomerWalletTransaction.CASHBACK]: 'cyan',
};
