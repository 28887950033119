import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IDispatcherOrderTimelineProps } from './types';
import { CustomSteps } from 'components/CustomSteps';
import styles from './DispatcherOrderTimeline.module.css';
import useToken from 'antd/es/theme/useToken';

export const DispatcherOrderTimeline: FC<IDispatcherOrderTimelineProps> = memo(({ timeline }) => {
	const [, brandColors] = useToken();
	const { t: tDispatcher } = useTranslation('dispatcher');

	const indexOfLastNonInvalidDate = useMemo(() => {
		const index = timeline.findIndex((s) => !s.description);
		return index < 0 ? timeline.length - 1 : index - 1;
	}, [timeline]);

	// ! render
	return (
		<CustomSteps
			labelPlacement='vertical'
			current={indexOfLastNonInvalidDate}
			colorPrimary={brandColors.colorPrimary}
			items={timeline.map((step) => ({
				...step,
				title: tDispatcher(`order_card.${step.key}`),
			}))}
			className={styles.timeline_steps_container}
		/>
	);
});
