import { FC, Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import { DEFAULT_TABS_PROPS } from 'configs/common';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { CARI_UNLIMITED_TAB_ICONS } from './configs';
import { ITabItem } from 'types/common';
import { ECariUnlimitedTabsKeys } from './types';
import styles from './CariUnlimitedManagementPageLayout.module.css';
import { Tabs } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

const CariUnlimitedManagementPageLayout: FC = () => {
	const navigate = useNavigate();
	const { t: tCariUnlimited } = useTranslation('cari-unlimited');

	const match = useMatch(ABSOLUTE_ROUTES.MANAGEMENT_CARI_UNLIMITED_TABS + '/*');

	// ! memos
	const tabItems = useMemo<ITabItem[]>(() => {
		return Object.values(ECariUnlimitedTabsKeys).map((tabKey) => ({
			key: tabKey,
			label: (
				<>
					{CARI_UNLIMITED_TAB_ICONS[tabKey]}
					{tCariUnlimited(`tabs.${tabKey}`)}
				</>
			),
			style: DEFAULT_TABS_PROPS,
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// ! handlers
	const onTabChange = (activeKey: ECariUnlimitedTabsKeys) => navigate(activeKey);

	// ! render
	return (
		<>
			<PageHeader title={tCariUnlimited('page_title')} />

			<Tabs
				items={tabItems}
				destroyInactiveTabPane
				activeKey={match?.params.tab}
				defaultActiveKey={tabItems[0].key}
				onTabClick={(activeKey) => onTabChange(activeKey as ECariUnlimitedTabsKeys)}
			/>

			<div className={styles.tabs_container}>
				<Suspense>
					<Outlet />
				</Suspense>
			</div>
		</>
	);
};

export default CariUnlimitedManagementPageLayout;
