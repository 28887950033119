import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { OrderProvider } from 'hooks/useOrder';
import { LIST_DEFAULT_PARAMS } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import { IntegrationFetchWrapper } from 'appWrappers/IntegrationFetchWrapper';
import ListDatesFilterWrapper from 'components/ListDatesFilterWrapper';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import { EOrderTabsKeys, ORDER_TABS_ROUTES } from './routes.order-tabs';
import { ShoppingOutlined } from '@ant-design/icons';

// ! Lazy module imports
const OrderList = lazy(() => import('pages/Orders/OrderList'));
const OrderDetailsPageLayout = lazy(() => import('pages/Orders/OrderDetails'));

export const ORDER_ROUTES: TRouteObject = {
	path: 'orders',
	element: (
		<ListParamsRouteWrapper key='orders'>
			<IntegrationFetchWrapper>
				<ListDatesFilterWrapper />
			</IntegrationFetchWrapper>
		</ListParamsRouteWrapper>
	),
	handle: {
		translationKey: 'orders',
		permissions: [APP_PERMISSIONS.order.view],
		icon: <ShoppingOutlined />,
		showOnNav: true,
	},
	children: [
		{
			index: true,
			element: <OrderList />,
			handle: {
				translationKey: 'orders',
				permissions: [APP_PERMISSIONS.order.view],
				icon: <ShoppingOutlined />,
				defaultListParams: LIST_DEFAULT_PARAMS,
				showOnNav: false,
			},
		},
		{
			path: ':orderId',
			element: (
				<OrderProvider>
					<OrderDetailsPageLayout />
				</OrderProvider>
			),
			handle: {
				translationKey: 'order_details',
				permissions: [APP_PERMISSIONS.order.view],
				showOnNav: false,
			},
			children: [
				{
					index: true,
					element: (
						<Navigate
							to={EOrderTabsKeys.DETAILS}
							replace
						/>
					),
				},
				...ORDER_TABS_ROUTES,
			],
		},
	],
};
