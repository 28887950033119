import { EStatus } from 'types/common';

export enum EPaymentsSupportStatus {
	SUPPORTED = 'supported',
	NOT_SUPPORTED = 'not_supported',
}

export enum EPaymentsEnabledStatus {
	ENABLED = 'enabled',
	DISABLED = 'disabled',
}

export interface IPaymentListItemResponse {
	id: number;
	name: string;
	title: string;
	status: EStatus;

	supports_refund: EPaymentsSupportStatus; // control on BE level
	refund_enabled: EPaymentsEnabledStatus;

	supports_scheduled_orders: EPaymentsSupportStatus;

	supports_wallet_credit: EPaymentsSupportStatus; // control on BE level
	wallet_credit_enabled: EPaymentsEnabledStatus;
}

export interface IUpdatePaymentData
	extends Partial<
		Pick<
			IPaymentListItemResponse,
			'status' | 'refund_enabled' | 'supports_scheduled_orders' | 'wallet_credit_enabled'
		>
	> {}

export interface IRefundMethodItem {
	id: number;
	title: string;
}
