import { Property } from 'csstype';

export interface IColorSet {
	colorPrimary: Property.Color;
	colorPrimaryActive: Property.Color;
	colorPrimaryHover: Property.Color;
}

export enum EButtonColorTypes {
	btn_green,
	btn_details,
	btn_purple,
	btn_cyan,
	btn_danger,
	btn_can_clear_dispute,
}
