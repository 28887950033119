import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { FeatureVariantWrapper } from 'hooks/FeatureVariantControl/FeatureVariantWrapper';
import { LIST_DEFAULT_PARAMS } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import { EVerticalLevel } from 'types/api';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import { SubVerticalProvider } from 'pages/Verticals/hooks/useSubVertical';
import { VerticalProvider } from 'pages/Verticals/hooks/useVertical';
import { VerticalDetailsPageLayout } from './VerticalDetails/VerticalDetailsPageLayout';
import { EVerticalDefaultTabs } from './VerticalDetails/types';
import { BuildOutlined } from '@ant-design/icons';

// ! lazy imports
const VerticalList = lazy(() => import('./VerticalsList'));
const AddVerticalPage = lazy(() => import('./AddVerticalPage'));
const ManageVerticalVendors = lazy(() => import('./ManageVerticalVendors'));
const VerticalSubList = lazy(() => import('./VerticalDetails/SubVerticals'));
const VerticalDetails = lazy(() => import('./VerticalDetails'));

export const VERTICAL_ROUTES: TRouteObject = {
	path: 'verticals',
	element: (
		<FeatureVariantWrapper>
			<ListParamsRouteWrapper key='verticals' />
		</FeatureVariantWrapper>
	),
	handle: {
		translationKey: 'verticals',
		permissions: [APP_PERMISSIONS.vertical.manage_vendor, APP_PERMISSIONS.vendor.view],
		showOnNav: true,

		icon: <BuildOutlined />,
		defaultListParams: LIST_DEFAULT_PARAMS,
	},
	children: [
		{
			index: true,
			element: <VerticalList />,
			handle: {
				translationKey: 'verticals',
				permissions: [APP_PERMISSIONS.vertical.view],
				icon: <BuildOutlined />,
				defaultListParams: LIST_DEFAULT_PARAMS,
				showOnNav: false,
			},
		},
		{
			path: 'new',
			element: <AddVerticalPage level={EVerticalLevel.VERTICAL} />,
			handle: {
				translationKey: 'add_new_vertical',
				permissions: [APP_PERMISSIONS.vertical.add],
				showOnNav: false,
			},
		},
		{
			path: ':verticalId',
			element: (
				<VerticalProvider>
					<VerticalDetailsPageLayout />
				</VerticalProvider>
			),
			handle: {
				translationKey: 'vertical_details',
				permissions: [],
				showOnNav: false,
			},
			children: [
				{
					index: true,
					element: (
						<Navigate
							to={EVerticalDefaultTabs.DETAILS}
							replace
						/>
					),
				},
				{
					path: EVerticalDefaultTabs.DETAILS,
					element: <VerticalDetails />,
				},
				{
					path: EVerticalDefaultTabs.INDEPENDENT_VENDORS,
					element: <ManageVerticalVendors />,
				},
				{
					path: EVerticalDefaultTabs.SUB_VERTICALS,

					children: [
						{
							index: true,
							element: <VerticalSubList />,
						},
						{
							path: 'new',
							element: <AddVerticalPage level={EVerticalLevel.SUB_VERTICAL} />,
						},
						{
							path: ':subVerticalId',
							element: (
								<SubVerticalProvider verticalIdSearchParameterName='subVerticalId'>
									<ManageVerticalVendors />
								</SubVerticalProvider>
							),
							handle: {
								permissions: [APP_PERMISSIONS.vertical.manage_vendor, APP_PERMISSIONS.vendor.view],
								showOnNav: false,
							},
						},
					],
				},
			],
		},
	],
};
