import { createContext, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { IVerticalContextData, IVerticalProviderProps } from './types';
import { useVerticalService } from './useVerticalService';

const VerticalContext = createContext<IVerticalContextData>({} as IVerticalContextData);

const useVertical = () => {
	return useContext(VerticalContext);
};

const VerticalProvider = ({
	children = <Outlet />,
	id,
	verticalIdSearchParameterName = 'verticalId',
}: IVerticalProviderProps) => {
	const providerData = useVerticalService({
		id,
		verticalIdSearchParameterName,
	});

	return (
		<VerticalContext.Provider value={providerData ?? ({} as IVerticalContextData)}>
			{children}
		</VerticalContext.Provider>
	);
};

export default useVertical;
export { useVertical, VerticalProvider };
