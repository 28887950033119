import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_EMPTY_VALUE_PLACEHOLDER, DEFAULT_TAG_STYLES } from 'configs/common';
import { COMPENSATION_STATUS_COLORS_CONFIG } from './configs';
import { ICompensationRequestStatusProps } from './types';
import { Tag } from 'antd';

const CompensationRequestStatusTag: FC<ICompensationRequestStatusProps> = ({ status }) => {
	const { t: tCompensations } = useTranslation('compensation-requests');

	// ! render
	if (!status) return <>{DEFAULT_EMPTY_VALUE_PLACEHOLDER}</>;

	const color = COMPENSATION_STATUS_COLORS_CONFIG[status];

	return (
		<Tag
			color={color}
			className='flex-center'
			style={{
				...DEFAULT_TAG_STYLES,
				minWidth: '72px',
				textTransform: 'uppercase',
			}}
		>
			{tCompensations(`status.${status}`)}
		</Tag>
	);
};

export default memo(CompensationRequestStatusTag);
