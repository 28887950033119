const BYTES_IN_KB = 1024;
const UNITS: string[] = ['B', 'Kb', 'Mb', 'Gb', 'Tb'];

export const formatSizeToString = (size: number): string => {
	if (size <= 0) {
		return '0' + UNITS[0];
	}

	let i: number = 0;
	while (size >= BYTES_IN_KB && i < UNITS.length - 1) {
		size /= BYTES_IN_KB;
		i++;
	}

	const formattedSize: string = Number.isInteger(size) ? size.toString() : size.toFixed(2);

	return formattedSize + UNITS[i];
};
