import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_DESCRIPTIONS_PROPS, DEFAULT_EMPTY_VALUE_PLACEHOLDER, DEFAULT_TAG_STYLES } from 'configs/common';
import { EChoiceOption } from 'types/common';
import { IMobilePresenceCardProps } from './types';
import { Card, Descriptions, Space, Tag } from 'antd';

const MobilePresenceCard: FC<IMobilePresenceCardProps> = ({ availableOnMobile = EChoiceOption.NO }) => {
	const { t: tPresence } = useTranslation('vendors', { keyPrefix: 'presence' });

	// ! render
	return (
		<Card
			title={tPresence('titles.mobile')}
			size='small'
		>
			<Space
				align='start'
				size='large'
			>
				<Descriptions {...DEFAULT_DESCRIPTIONS_PROPS}>
					<Descriptions.Item label={tPresence('available_on_mobile')}>
						<Tag style={DEFAULT_TAG_STYLES}>
							{availableOnMobile?.toUpperCase() || DEFAULT_EMPTY_VALUE_PLACEHOLDER}
						</Tag>
					</Descriptions.Item>
				</Descriptions>
			</Space>
		</Card>
	);
};

export default MobilePresenceCard;
