import { ReactNode } from 'react';
import { Property } from 'csstype';
import { EUserTypes } from 'types/common';
import { CarOutlined, ShopOutlined, UserOutlined } from '@ant-design/icons';

export const USER_TYPES_TAG_COLORS: Record<EUserTypes, Property.Color> = {
	[EUserTypes.ADMIN]: 'orange',
	[EUserTypes.SYSTEM]: 'orange',
	[EUserTypes.GUEST]: 'cyan',
	[EUserTypes.CUSTOMER]: 'cyan',
	[EUserTypes.STORE]: 'purple',
	[EUserTypes.DRIVER]: 'geekblue',
	[EUserTypes.INTEGRATION]: 'orange',
	[EUserTypes.OPERATIONS]: 'orange',
	[EUserTypes.VENDOR]: 'orange',
};

export const USER_TYPES_TAG_ICONS: Record<EUserTypes, ReactNode> = {
	[EUserTypes.ADMIN]: <UserOutlined />,
	[EUserTypes.CUSTOMER]: <UserOutlined />,
	[EUserTypes.DRIVER]: <CarOutlined />,
	[EUserTypes.GUEST]: <UserOutlined />,
	[EUserTypes.STORE]: <ShopOutlined />,
	[EUserTypes.SYSTEM]: <UserOutlined />,
	[EUserTypes.INTEGRATION]: <UserOutlined />,
	[EUserTypes.OPERATIONS]: <UserOutlined />,
	[EUserTypes.VENDOR]: <UserOutlined />,
};
