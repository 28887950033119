import { FC } from 'react';
import { Property } from 'csstype';
import { ConfigProvider, Steps, StepsProps } from 'antd';

interface ICustomStepsProps extends StepsProps {
	colorPrimary?: Property.Color;
}

export const CustomSteps: FC<ICustomStepsProps> = ({ colorPrimary = '#32ba81', size = 'small', ...props }) => {
	return (
		<ConfigProvider
			theme={{
				token: {
					colorPrimary,
				},
			}}
		>
			<Steps
				size={size}
				{...props}
			/>
		</ConfigProvider>
	);
};
