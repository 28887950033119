import { IFormItem } from 'types/common';
import TranslatedLabel from 'components/TranslatedLabel';
import { Input } from 'antd';

const AMOUNT_MAX_VALUE = 999;

export const CREDIT_MODAL_FORM_CONFIG: IFormItem[] = [
	{
		key: 'amount',
		label: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey='shared.amount'
				extra={{
					keyPrefix: 'customer_details.tabs.wallet_history',
				}}
			/>
		),
		inputProps: {
			type: 'number',
			min: 0,
			maxLength: 3,
		},
		validationsRules: [
			{ required: true },
			() => ({
				validator(_, value) {
					if (!value) {
						return Promise.resolve();
					}
					const parsedValue = Number.parseFloat(value);

					if (Number.isNaN(parsedValue) || parsedValue > AMOUNT_MAX_VALUE) {
						return Promise.reject(
							<TranslatedLabel
								nameSpace='customers'
								i18nKey='new_credit_modal.correct_number'
								extra={{
									keyPrefix: 'customer_details.tabs.wallet_history',
								}}
							/>
						);
					}

					if (parsedValue < 0) {
						return Promise.reject(
							<TranslatedLabel
								nameSpace='customers'
								i18nKey='new_credit_modal.minimal_value'
								extra={{
									keyPrefix: 'customer_details.tabs.wallet_history',
								}}
							/>
						);
					}

					return Promise.resolve();
				},
			}),
		],
	},
	{
		key: 'comment',
		label: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey='shared.comment'
				extra={{
					keyPrefix: 'customer_details.tabs.wallet_history',
				}}
			/>
		),
		validationsRules: [{ required: true }],
		inputElement: Input.TextArea,
		textAreaProps: {
			rows: 4,
			maxLength: 150,
			showCount: true,
		},
	},
];
