import { lazy } from 'react';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import { UnlockOutlined } from '@ant-design/icons';

const ManageRoles = lazy(() => import('pages/ManageUsersAndRoles/ManageRoles/ManageRoles'));

export const MANAGE_ROLES_ROUTES: TRouteObject = {
	path: 'roles',
	element: (
		<ListParamsRouteWrapper key='manage-roles'>
			<ManageRoles />
		</ListParamsRouteWrapper>
	),
	handle: {
		translationKey: 'manage_roles',
		permissions: [APP_PERMISSIONS.role.view],
		icon: <UnlockOutlined />,
		showOnNav: true,
	},
};
