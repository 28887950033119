import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { CustomerProvider } from 'hooks/useCustomer';
import { LIST_DEFAULT_PARAMS } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import { CUSTOMER_TAB_ROUTES } from './CustomerDetails/Tabs/configs';
import { ECustomerTabsKeys } from './CustomerDetails/Tabs/types';

const CustomerList = lazy(() => import('pages/Customers/CustomerList'));
const CustomerPageLayout = lazy(() => import('pages/Customers/CustomerDetails'));

export const CUSTOMER_ROUTES: TRouteObject = {
	path: 'customers',
	element: <ListParamsRouteWrapper key='customers' />,
	handle: {
		translationKey: 'customers',
		defaultListParams: LIST_DEFAULT_PARAMS,
		permissions: [APP_PERMISSIONS.customer.view],
		showOnNav: true,
	},
	children: [
		{
			index: true,
			element: <CustomerList />,
			handle: {
				translationKey: 'customers',
				defaultListParams: LIST_DEFAULT_PARAMS,
				permissions: [APP_PERMISSIONS.customer.view],
				showOnNav: false,
			},
		},
		{
			path: ':customerId',
			element: (
				<CustomerProvider>
					<CustomerPageLayout />
				</CustomerProvider>
			),
			handle: {
				translationKey: 'customer_details',
				permissions: [APP_PERMISSIONS.customer.view],
				showOnNav: false,
			},
			children: [
				{
					index: true,
					element: (
						<Navigate
							to={ECustomerTabsKeys.DETAILS}
							replace
						/>
					),
				},
				...CUSTOMER_TAB_ROUTES,
			],
		},
	],
};
