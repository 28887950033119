import { PropsWithChildren, createContext, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { throttle } from 'utils/throttle';
import { DRIVER_API } from 'configs/api';
import { IDriver } from 'types/api';
import { Nullable } from 'types/common';
import { useNewHttpClient } from './useHttpClient';
import { IHttpClientData } from './useHttpClient/types';

interface IDriverContextData {
	driverId: number;
	data: Nullable<IDriver>;
	fetchHttpClient: IHttpClientData<IDriver>;

	// handlers
	fetchDriver: () => void;
}

const Context = createContext<IDriverContextData>({} as IDriverContextData);

const useDriver = () => {
	return useContext(Context);
};

const DriverProvider = ({ children }: PropsWithChildren) => {
	const { driverId } = useParams();

	// ! http clients
	const fetchHttpClient = useNewHttpClient<IDriver>();

	// ! handlers

	const fetchDriver = throttle(() => {
		if (!driverId || fetchHttpClient.isLoading) return;

		fetchHttpClient.request({
			requestConfig: DRIVER_API.get(+driverId),
		});
	});

	// ! useEffects
	useEffect(() => {
		fetchDriver();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [driverId]);

	// ! render
	if (!driverId) return null;

	const data = { driverId: +driverId, data: fetchHttpClient.response, fetchDriver, fetchHttpClient };

	return <Context.Provider value={data}>{children}</Context.Provider>;
};

export { DriverProvider, useDriver };
