import { generatePath } from 'react-router-dom';
import { LocalTimeDate } from 'hooks/useLocaleTimeUtils';
import { ID_COLUMN_WIDTH } from 'configs/common';
import { APP_PERMISSIONS } from 'configs/permissions';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { ICompensationRequestTabTableData } from './types';
import LinkButton from 'components/LinkButton';
import CompensationRequestStatusTag from 'components/Tags/CompensationRequestStatusTag';
import TranslatedLabel from 'components/TranslatedLabel';
import {
	COMPENSATION_REQUEST_PAYMENT_METHOD_FILTERS,
	COMPENSATION_REQUEST_STATUS_FILTERS,
} from 'pages/CompensationRequests';
import { ColumnsType } from 'antd/es/table';

export const COMPENSATION_TAB_TABLE_COLS_CONFIG = (
	countryIsoTwoCode: string
): ColumnsType<ICompensationRequestTabTableData> => [
	{
		key: 'id',
		title: (
			<TranslatedLabel
				nameSpace='compensation-requests'
				i18nKey='id'
				extra={{ keyPrefix: 'shared.entity' }}
			/>
		),
		dataIndex: 'id',
		width: ID_COLUMN_WIDTH,
	},
	{
		key: 'date',
		title: (
			<TranslatedLabel
				nameSpace='compensation-requests'
				i18nKey='date'
				extra={{ keyPrefix: 'shared.entity' }}
			/>
		),
		width: 200,
		render: (_, { date }) => <LocalTimeDate date={date} />,
	},
	{
		key: 'order_code',
		title: (
			<TranslatedLabel
				nameSpace='compensation-requests'
				i18nKey='order_code'
				extra={{ keyPrefix: 'shared.entity' }}
			/>
		),
		render: (_, { order }) => (
			<LinkButton
				to={generatePath(ABSOLUTE_ROUTES.ORDER_DETAILS, { countryIsoTwoCode, orderId: order.id })}
				label={order.code}
				permissions={APP_PERMISSIONS.order.view}
			/>
		),
	},
	{
		key: 'refund_payment_method_name',
		title: (
			<TranslatedLabel
				nameSpace='compensation-requests'
				i18nKey='refund_payment_method'
				extra={{ keyPrefix: 'shared.entity' }}
			/>
		),
		dataIndex: 'refund_payment_method',
		width: 200,
		filters: COMPENSATION_REQUEST_PAYMENT_METHOD_FILTERS,
	},
	{
		key: 'status',
		title: (
			<TranslatedLabel
				nameSpace='compensation-requests'
				i18nKey='status'
				extra={{ keyPrefix: 'shared.entity' }}
			/>
		),
		render: (_, { status }) => <CompensationRequestStatusTag status={status} />,
		width: 120,
		filters: COMPENSATION_REQUEST_STATUS_FILTERS,
	},
];
