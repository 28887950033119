import { lazy } from 'react';
import { LIST_DEFAULT_PARAMS } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import { NotificationOutlined } from '@ant-design/icons';

const AddPushNotification = lazy(() => import('pages/PushNotifications/AddPushNotification'));
const PushNotificationsList = lazy(() => import('pages/PushNotifications/PushNotificationsList'));

export const PUSH_NOTIFICATIONS_ROUTES: TRouteObject = {
	path: 'notifications',
	element: <ListParamsRouteWrapper key='notifications' />,
	handle: {
		translationKey: 'push_notifications',
		icon: <NotificationOutlined />,
		permissions: [APP_PERMISSIONS.push_notification.view],
		defaultListParams: LIST_DEFAULT_PARAMS,
		showOnNav: true,
	},
	children: [
		{
			index: true,
			element: <PushNotificationsList />,
		},
		{
			path: 'new',
			element: <AddPushNotification />,
			handle: {
				translationKey: 'add_push_notification',
				permissions: [APP_PERMISSIONS.push_notification.send],
				showOnNav: false,
			},
		},
	],
};
