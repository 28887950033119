import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { convertTimeSlotToHours, generateTimeSlotsInMinutes, transformTimingsDataToWorkShifts } from './helper';
import { DEFAULT_MODAL_PROPS } from 'configs/common';
import { Nullable, TShortWeekDay } from 'types/common';
import { IWorkshiftController } from '../types';
import { IBranchTimingConvertedToNumbers, IBranchTimingsScheduleConverted } from './types';
import TimingsDayWithConfirmation from '../TimingsDayWithConfirmation';
import { MAX_SHIFTS_PER_DAY, WEEK_DAYS } from '../config';
import styles from './WorkshiftsTableEditor.module.css';
import { Alert, Modal, Space, Tooltip } from 'antd';

interface IWorkshiftTableEditorProps {
	workshiftController: IWorkshiftController;
	isEdit: boolean;
}

const WorkshiftTableEditor: FC<IWorkshiftTableEditorProps> = ({ workshiftController, isEdit }) => {
	const [initialTimeSlot, setInitialTimeSlot] = useState<Nullable<number>>(null);
	const [finalTimeSlot, setFinalTimeSlot] = useState<Nullable<number>>(null);

	const [isLimitWarningDisplay, setLimitWarningDisplay] = useState<boolean>(false);

	const [openModal, setOpenModal] = useState(false);

	const { t: tWorkshifts } = useTranslation('vendors', { keyPrefix: 'vendor_details.tabs.working_shifts' });
	const { t: tCommon } = useTranslation('common');

	const TIME_SLOTS_IN_MINUTES: number[] = generateTimeSlotsInMinutes();

	const convertedTimingsData = useMemo<IBranchTimingConvertedToNumbers[]>(() => {
		return transformTimingsDataToWorkShifts(workshiftController.timingsData);
	}, [workshiftController.timingsData]);

	const getSlotCurrentOpenStatus = (scheduleInMinutes: IBranchTimingsScheduleConverted[], slot: number) => {
		const isOpen = scheduleInMinutes.find(
			(item) => item.rounded_open_time <= slot && item.rounded_close_time > slot
		);

		return !!isOpen;
	};

	// Mouse Events
	const handleMouseEnter = (slot: number) => {
		if (initialTimeSlot !== null) setFinalTimeSlot(slot);
	};

	const handleMouseDown = (day: TShortWeekDay, slot: number) => {
		workshiftController.setSelectedDay(day);
		setInitialTimeSlot(slot);
	};

	const handleMouseUp = (slot: number) => {
		if (finalTimeSlot === null || finalTimeSlot === initialTimeSlot) {
			setOpenModal(true);
		}

		if (initialTimeSlot !== null && finalTimeSlot !== null && finalTimeSlot !== initialTimeSlot) {
			const [openTimeSlot, closeTimeSlot] = [initialTimeSlot!, slot].sort((a, b) => a - b);

			const dayIndex = WEEK_DAYS.findIndex((d) => d === workshiftController.selectedDay);
			const scheduleInMinutes: IBranchTimingsScheduleConverted[] = convertedTimingsData[dayIndex].schedule;

			try {
				workshiftController.toggleSlot(
					dayIndex,
					openTimeSlot,
					closeTimeSlot,
					scheduleInMinutes,
					getSlotCurrentOpenStatus(scheduleInMinutes, openTimeSlot)
				);
			} catch (error) {
				setLimitWarningDisplay(true);
			} finally {
			}
		}
		// clear
		setFinalTimeSlot(null);
		setInitialTimeSlot(null);
	};

	// Modal Events
	const handleCloseModal = () => {
		// reset values on close
		workshiftController.setTimesOfSelectedDay(
			workshiftController.timingsData.filter((item) => item.day === workshiftController.selectedDay)[0]
		);
		setOpenModal(false);
	};

	const handleConfirmModal = () => {
		workshiftController.applyScheduleWithConfirmation();
		handleCloseModal();
	};

	const isPaintingNewWorkshift = initialTimeSlot;

	return (
		<>
			<Space
				direction='vertical'
				size='large'
				className='w-100'
			>
				{isLimitWarningDisplay && (
					<Alert
						type='warning'
						showIcon
						message={tWorkshifts('errors.more_than_three_workshifts', { limit: MAX_SHIFTS_PER_DAY })}
					/>
				)}
				<table className={styles.table}>
					<thead>
						<tr className={styles.table_header_row}>
							<th></th>
							{WEEK_DAYS.map((day) => (
								<th
									key={day}
									className={styles.table_header_weekday}
								>
									{day}
								</th>
							))}
						</tr>
					</thead>

					<tbody>
						{TIME_SLOTS_IN_MINUTES.map((slot) => {
							const isRowEven = slot % 60 === 0;

							return (
								<tr key={slot}>
									<td>
										<div
											className={styles.table_hours_column}
											data-time={isRowEven ? convertTimeSlotToHours(slot) : ' '}
										/>
									</td>

									{WEEK_DAYS.map((day, dayIndex) => {
										const isOpenSlot = getSlotCurrentOpenStatus(
											convertedTimingsData[dayIndex].schedule,
											slot
										);

										const tooltipTitleTimesGroup = convertedTimingsData[dayIndex].schedule.find(
											({ open_time, close_time }) => open_time <= slot && close_time > slot
										);

										const isCellPrePainted =
											finalTimeSlot !== null &&
											initialTimeSlot !== null &&
											day === workshiftController.selectedDay &&
											((finalTimeSlot <= slot && slot <= initialTimeSlot) ||
												(initialTimeSlot <= slot && slot <= finalTimeSlot));

										const renderCell = (
											<td
												key={`${slot}-${day}`}
												onMouseEnter={() => (isEdit ? handleMouseEnter(slot) : undefined)}
												onMouseDown={() => (isEdit ? handleMouseDown(day, slot) : undefined)}
												onMouseUp={() => (isEdit ? handleMouseUp(slot) : undefined)}
												className={[
													styles.table_cells,
													isOpenSlot ? styles.table_cells_open : styles.table_cells_close,
													isCellPrePainted ? styles.pre_paint : '',
												].join(' ')}
											/>
										);

										if (isPaintingNewWorkshift) {
											return renderCell;
										}

										return (
											<Tooltip
												key={`tooltip-${slot}-${day}`}
												placement='right'
												title={
													tooltipTitleTimesGroup
														? `${convertTimeSlotToHours(
																tooltipTitleTimesGroup.open_time
														  )} - ${convertTimeSlotToHours(
																tooltipTitleTimesGroup.close_time
														  )}`
														: undefined
												}
											>
												{renderCell}
											</Tooltip>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			</Space>

			<Modal
				{...DEFAULT_MODAL_PROPS}
				open={openModal}
				title={tWorkshifts('edit_modal.title', { day: tCommon(`weekdays.${workshiftController.selectedDay}`) })}
				okText={tCommon('action_buttons.confirm')}
				cancelButtonProps={{ style: { display: 'none' } }}
				onCancel={handleCloseModal}
				onOk={handleConfirmModal}
			>
				<TimingsDayWithConfirmation
					timingsItem={workshiftController.timesOfSelectedDay}
					onTimeChange={workshiftController.timeChangeWithConfirmation}
					onAddScheduleItem={workshiftController.addScheduleItemWithConfirmation}
					onRemoveScheduleItem={workshiftController.removeScheduledItemWithConfirmation}
				/>
			</Modal>
		</>
	);
};

export default WorkshiftTableEditor;
