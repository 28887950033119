import { FC, ReactNode, createContext, useState } from 'react';
import { TEmptyFunction } from 'types/common';

interface IAreaDrawerContextData {
	openSelectAreasDrawer: boolean;
	openDrawer: TEmptyFunction;
	closeDrawer: TEmptyFunction;
}

export const AreasDrawerContext = createContext<IAreaDrawerContextData>({} as IAreaDrawerContextData);

export const SelectAreasDrawerProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const [openSelectAreasDrawer, setOpenSelectAreasDrawer] = useState<boolean>(false);

	const openDrawer = () => setOpenSelectAreasDrawer(true);
	const closeDrawer = () => setOpenSelectAreasDrawer(false);

	const drawerData = { openSelectAreasDrawer, openDrawer, closeDrawer };

	return <AreasDrawerContext.Provider value={drawerData}>{children}</AreasDrawerContext.Provider>;
};
