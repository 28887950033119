import { LocalTimeDate } from 'hooks';
import { DEFAULT_TAG_STYLES } from 'configs/common';
import { IListResponse } from 'types/common';
import { ICariUnlimitedSubscriptionLogEntry } from './types';
import TranslatedLabel from 'components/TranslatedLabel';
import styles from './CariUnlimitedHistoryTab.module.css';
import { Flex, Typography as T, Tag, TimelineItemProps } from 'antd';
import { CheckCircleOutlined, UserOutlined } from '@ant-design/icons';

export const processLogs = (list: IListResponse<ICariUnlimitedSubscriptionLogEntry>): TimelineItemProps[] => {
	return list.data.map((log, index) => {
		const isLastLog = list.count === index + 1;

		const { comment, date, user_role } = log;

		return {
			key: 'item-' + index,
			className: styles.timeline_item,
			color: isLastLog ? 'var(--ant-orange-4)' : 'green',
			dot: isLastLog ? <CheckCircleOutlined className={styles.last_item_icon} /> : null,
			children: (
				<Flex
					gap={8}
					vertical
				>
					<Flex align='center'>
						<Tag
							icon={<UserOutlined />}
							style={DEFAULT_TAG_STYLES}
						>
							<TranslatedLabel
								i18nKey={user_role}
								extra={{ keyPrefix: 'user_types' }}
							/>
						</Tag>

						<T.Text className={styles.date}>
							<LocalTimeDate date={date} />
						</T.Text>
					</Flex>

					<T.Paragraph>{comment}</T.Paragraph>
				</Flex>
			),
		};
	});
};
