import { transformAreasToOptionsList } from 'utils/transformAreasToSelectOptions';
import { IArea, IGenericBranch, IGenericVendor, IGenericVertical } from 'types/api';
import { ISelectOption } from 'types/common';

export const getBannerPositionsOptionList = (length: number) =>
	Array.from({ length }, (_, i) => i + 1).map<ISelectOption>((num) => ({
		label: `${num}`,
		value: num,
	}));

export const getAreasOptionList = (areas: IArea[], areasToConcat: IArea[]) => {
	const areasMap: Record<React.Key, IArea> = {};

	areas.concat(areasToConcat).reduce((acc, area) => {
		acc[area.id] = area;
		return acc;
	}, areasMap);

	const newAreaList = Object.values(areasMap);
	return transformAreasToOptionsList(newAreaList);
};

const getEntitySelectOptionList = (
	entityList?: Array<IGenericBranch | IGenericVendor | IGenericVertical>
): ISelectOption[] => {
	if (!entityList?.length) return [];

	return entityList.map(({ id, name, name_ar }) => ({
		value: id,
		label: `#${id} - ${name} | ${name_ar}`,
	}));
};

export const getVendorsOptionList = (vendors: IGenericVendor[], vendorsToConcat: IGenericVendor[]) => {
	const vendorsMap: Record<React.Key, IGenericVendor> = {};

	vendors.concat(vendorsToConcat).reduce((acc, vendor) => {
		acc[vendor.id] = vendor;
		return acc;
	}, vendorsMap);

	const newVendorList = Object.values(vendorsMap);
	return getEntitySelectOptionList(newVendorList);
};

export const getStoresOptionList = (stores: IGenericBranch[], storesToConcat: IGenericBranch[]) => {
	const storesMap: Record<React.Key, IGenericBranch> = {};

	stores.concat(storesToConcat).reduce((acc, store) => {
		acc[store.id] = store;
		return acc;
	}, storesMap);

	const newStoreList = Object.values(storesMap);
	return getEntitySelectOptionList(newStoreList);
};

export const getVerticalsOptionList = (verticals: IGenericVertical[], verticalsToConcat: IGenericVertical[]) => {
	const verticalsMap: Record<React.Key, IGenericVertical> = {};

	verticals.concat(verticalsToConcat).reduce((acc, store) => {
		acc[store.id] = store;
		return acc;
	}, verticalsMap);

	const newVerticalsList = Object.values(verticalsMap);
	return getEntitySelectOptionList(newVerticalsList);
};
