import { lazy } from 'react';
import { LIST_DEFAULT_PARAMS } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import { FileDoneOutlined } from '@ant-design/icons';

const RequiredDocuments = lazy(() => import('pages/RequiredDocuments'));
const AddRequiredDocument = lazy(() => import('pages/RequiredDocuments/AddRequiredDocument'));

export const REQUIRED_DOCUMENTS_ROUTES: TRouteObject = {
	path: 'required-documents',
	element: <ListParamsRouteWrapper key='required-documents' />,
	handle: {
		translationKey: 'required_documents',
		permissions: [APP_PERMISSIONS.required_document.view],
		icon: <FileDoneOutlined />,
		defaultListParams: LIST_DEFAULT_PARAMS,
		showOnNav: true,
	},
	children: [
		{
			index: true,
			element: <RequiredDocuments />,
		},
		{
			path: 'new',
			element: <AddRequiredDocument />,
			handle: {
				translationKey: 'add_new_required_document',
				permissions: [APP_PERMISSIONS.required_document.add],
				showOnNav: false,
			},
		},
	],
};
