import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { IImageBoxProps } from './types';
import styles from './ImageBox.module.css';
import { Button, Empty, Image, Space, Typography as T } from 'antd';
import { EditOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';

export const ImageBox: FC<IImageBoxProps> = memo(
	({ image, bordered = true, extraInfo, isEditable = true, onAddEditBtnClick, buttonDisabled }) => {
		const { t: tImage } = useTranslation('common', { keyPrefix: 'image' });

		return (
			<Space
				size='middle'
				direction='vertical'
				className={`${styles.img_box_container} ${bordered ? styles.bordered : ''}`}
			>
				{image?.length ? (
					<Image
						src={image}
						height={100}
						loading='lazy'
						rootClassName={styles.img}
						alt={tImage('not_found')}
					/>
				) : (
					<Empty
						className={styles.empty_img}
						image={Empty.PRESENTED_IMAGE_SIMPLE}
						description={tImage('validation.empty_img_msg')}
					/>
				)}

				{isEditable && (
					<>
						<Button
							size='small'
							disabled={buttonDisabled}
							icon={image ? <EditOutlined /> : <PlusOutlined />}
							onClick={onAddEditBtnClick}
						>
							{tImage(image ? 'actions.update' : 'actions.add')}
						</Button>

						{extraInfo && (
							<Space className={styles.extra_info_wrapper}>
								<InfoCircleOutlined />
								<T.Text className={styles.info}>{extraInfo}</T.Text>
							</Space>
						)}
					</>
				)}
			</Space>
		);
	}
);
