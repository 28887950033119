import { GLOBAL_MAP_CONTAINER_STYLE } from 'configs/common';
import { EBranchOperationAreaType } from 'types/api';
import { ISelectOption } from 'types/common';
import TranslatedLabel from 'components/TranslatedLabel';

export const SERVICE_RADIUS_OPTIONS_LIST: ISelectOption[] = [
	0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
].map((radiusOpt) => ({
	value: radiusOpt,
	label: `${radiusOpt} Km`,
}));

export const POLYGON_MAX_EDGE_POINTS = 100;
export const POLYGON_MIN_EDGE_POINTS = 3;

export const OPERATIONS_MAP_CONFIG = {
	...GLOBAL_MAP_CONTAINER_STYLE,
	height: '600px',
};

export const DEFAULT_POLYGON_OPTIONS = {
	fillOpacity: 0.2,
	strokeOpacity: 1,
	strokeWeight: 2,
	zIndex: 1,
};

export const CIRCLE_RADIUS_CONFIGS = {
	...DEFAULT_POLYGON_OPTIONS,
	clickable: false,
	draggable: false,
	editable: false,
	visible: true,
};

export const OPERATION_AREA_TYPES_OPTIONS_LIST: ISelectOption[] = Object.values(EBranchOperationAreaType).map(
	(value) => ({
		value,
		label: (
			<TranslatedLabel
				nameSpace='vendors'
				i18nKey={value}
				extra={{
					keyPrefix: 'vendor_details.tabs.branches.branch_details.tabs.operation_area.operation_area_type',
				}}
			/>
		),
	})
);
