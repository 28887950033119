import { THttpRequestConfig } from 'hooks';

export const CONFIGURATIONS_API = {
	getFirebaseConfig: (): THttpRequestConfig => ({
		url: '/operations/configurations/firebase',
		method: 'GET',
	}),

	getMapConfig: (): THttpRequestConfig => ({
		url: '/operations/configurations/maps',
		method: 'GET',
	}),
};
