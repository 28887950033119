import { IBranchTiming } from 'types/api';
import { IBranchTimingConvertedToNumbers } from './types';
import { DEFAULT_SHIFT_DURATION } from '../config';

export const generateTimeSlotsInMinutes = (shift: number = DEFAULT_SHIFT_DURATION) => {
	const slots: number[] = [];
	const limit: number = 24 * (60 / shift);

	for (let i = 0; i < limit; i++) {
		slots.push(i * shift);
	}

	return slots;
};

export const convertTimeSlotToHours = (timeSlot: number): string => {
	const hours: number = Math.floor(timeSlot / 60);
	const minutes: number = timeSlot - hours * 60;
	const d = new Date();
	d.setHours(hours, minutes, 0);

	return d.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit', hourCycle: 'h23' });
};

const convertHoursToTimeSlot = (value: string): number => {
	let [hours, minutes] = value.split(':');

	const result: number = +hours * 60 + +minutes;
	return result;
};

const getClosestTimeslot = (value: number) => {
	const shiftDuration = DEFAULT_SHIFT_DURATION;

	const rest: number = value % shiftDuration;

	if (rest === 0) return value;

	const isCloserToBottomSlot = rest - shiftDuration / 2;

	if (isCloserToBottomSlot < 0) {
		return Math.floor(value / shiftDuration) * shiftDuration;
	} else {
		return Math.ceil(value / shiftDuration) * shiftDuration;
	}
};

export const transformTimingsDataToWorkShifts = (timingsData: IBranchTiming[]): IBranchTimingConvertedToNumbers[] => {
	return timingsData.map(({ day, schedule }) => ({
		day,
		schedule: schedule.map(({ close_time, open_time }) => {
			return {
				open_time: convertHoursToTimeSlot(open_time),
				close_time: convertHoursToTimeSlot(close_time),
				rounded_open_time: getClosestTimeslot(convertHoursToTimeSlot(open_time)),
				rounded_close_time: getClosestTimeslot(convertHoursToTimeSlot(close_time)),
			};
		}),
	}));
};
