import { THttpRequestConfig, THttpRequestParams } from 'hooks';
import { ICreateUpdateBannerPayload, ISwimlane, ISwimlanePayload } from 'types/api';

export const BANNERS_API = {
	list: (params?: THttpRequestParams): THttpRequestConfig => ({
		url: 'operations/banners',
		method: 'GET',
		params,
	}),
	// ! CRUD
	create: (data: ICreateUpdateBannerPayload): THttpRequestConfig => ({
		url: 'operations/banners',
		method: 'POST',
		data,
	}),

	updateSwimlaneVendors: (id: ISwimlane['id'], data: ISwimlanePayload): THttpRequestConfig => ({
		url: `operations/banners/${id}/vendors`,
		method: 'PATCH',
		data,
	}),

	getDetails: (id: number): THttpRequestConfig => ({
		url: `operations/banners/${id}`,
		method: 'GET',
	}),

	update: (id: number, data: ICreateUpdateBannerPayload): THttpRequestConfig => ({
		url: `operations/banners/${id}`,
		method: 'PATCH',
		data,
	}),

	delete: (id: number): THttpRequestConfig => ({
		url: `operations/banners/${id}`,
		method: 'DELETE',
	}),

	// ! OTHER
	updateBannerImageConfig: (id: number, data: FormData): THttpRequestConfig => ({
		url: `operations/banners/${id}/image`,
		method: 'POST',
		data,
	}),

	activateBannerConfig: (id: number): THttpRequestConfig => ({
		url: `operations/banners/${id}/activate`,
		method: 'PATCH',
	}),

	deactivateBannerConfig: (id: number): THttpRequestConfig => ({
		url: `operations/banners/${id}/deactivate`,
		method: 'PATCH',
	}),
};
