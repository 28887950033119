import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'store';
import { getMapCenterBySelectedCountry } from 'store/selectors';
import { DEFAULT_USER_INTERACTION_DEBOUNCE } from 'utils/debounce';
import { MAP_LIBRARIES } from 'configs/common';
import MapSearchBox from 'components/MapSearchBox';
import { MAP_CONTAINER_STYLE } from 'components/PolygonMap/config';
import { IPolygonMapProps } from 'components/PolygonMap/types';

const PolygonMap: FC<IPolygonMapProps> = ({
	googleMapsApiKey,
	children,
	mapCenter,
	centerRadius,
	onMapClick,
	mapContainerStyle = {},
}) => {
	const searchBoxRef = useRef<google.maps.places.SearchBox>();
	const [searchLocation, setSearchLocation] = useState<google.maps.LatLng>();
	const [googleMapInstance, setGoogleMapInstance] = useState<google.maps.Map>();

	// * map js api
	const { isLoaded: isMapLoaded, loadError } = useLoadScript({
		id: 'tts-branch-operation-area-map',
		googleMapsApiKey,
		libraries: MAP_LIBRARIES,
	});

	// ! selectors
	const { mapCenterByCountry } = useSelector(getMapCenterBySelectedCountry);

	// !handlers
	const onSearchBoxLoad = useCallback((ref: google.maps.places.SearchBox) => (searchBoxRef.current = ref), []);

	const onSearchBoUnmount = useCallback(() => (searchBoxRef.current = undefined), []);

	const onSearchPlacesChanged = useCallback(() => {
		const places = searchBoxRef?.current?.getPlaces();

		if (!places?.length) return;
		// otherwise, set the map center to the new searched location
		setSearchLocation(places[0].geometry?.location ?? undefined);
	}, []);

	const applyFitBoundsRadius = (map?: google.maps.Map) => {
		if (centerRadius && map) {
			const circle: google.maps.Circle = new google.maps.Circle({
				center: mapCenter ?? mapCenterByCountry,
				radius: centerRadius * 1000,
			});
			map.fitBounds(circle.getBounds()!);
		}
	};

	const onMapLoad = (map: google.maps.Map) => setGoogleMapInstance(map);

	// ! useEffects
	useEffect(() => {
		// * Note: 'setTimeout' is set only for animation purposes.
		setTimeout(() => applyFitBoundsRadius(googleMapInstance), DEFAULT_USER_INTERACTION_DEBOUNCE);
	}, [centerRadius, googleMapInstance]); // eslint-disable-line react-hooks/exhaustive-deps

	// ! return
	return (
		<>
			{isMapLoaded && !loadError && (
				<GoogleMap
					zoom={14}
					mapContainerStyle={{ ...MAP_CONTAINER_STYLE, ...mapContainerStyle }}
					center={searchLocation || (mapCenter ?? mapCenterByCountry)}
					onClick={onMapClick}
					onLoad={onMapLoad}
				>
					<MapSearchBox
						onLoad={onSearchBoxLoad}
						onUnmount={onSearchBoUnmount}
						onPlacesChanged={onSearchPlacesChanged}
					/>
					{children}
				</GoogleMap>
			)}
		</>
	);
};

export default memo(PolygonMap);
