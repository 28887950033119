import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store';
import { getSelectedCountryCurrency } from 'store/selectors';
import { useAuth, useCustomer, useForceRefreshData, useNewHttpClient } from 'hooks';
import { transformCustomerTransactionsToTableData } from './helpers';
import { CUSTOMER_API, LIST_DEFAULT_PARAMS } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import {
	ICustomerWalletBalance,
	ICustomerWalletTransaction,
	INewDebitCreditForm,
	IPostNewDebitCreditData,
} from 'types/api';
import { Nullable } from 'types/common';
import { ICustomerWalletTransactionTableData } from './types';
import { BUTTON_COLOR_SETS, CustomButton, EButtonColorTypes } from 'components/CustomButton';
import TableWrapper from 'components/Table';
import NewCreditModal from './NewCreditModal';
import NewDebitModal from './NewDebitModal';
import styles from './WalletHistoryTab.module.css';
import { WALLET_TRANSACTIONS_TABLE_COLUMNS } from './config';
import { Button, Space, Typography } from 'antd';

const WalletHistoryTab: FC = () => {
	const { customerId, isReadOnlyData } = useCustomer();
	const { hasPermission } = useAuth();
	const { forceRefreshData, refreshingData } = useForceRefreshData();
	const { t: tCustomerWallet } = useTranslation('customers', { keyPrefix: 'customer_details.tabs.wallet_history' });

	// ! http clients
	const fetchBalanceHttpClient = useNewHttpClient<ICustomerWalletBalance>();
	const debitHttpClient = useNewHttpClient();
	const creditHttpClient = useNewHttpClient();

	// ! state
	const [openDebitModal, setOpenDebitModal] = useState<boolean>(false);
	const [openCreditModal, setOpenCreditModal] = useState<boolean>(false);
	const [walletBalance, setWalletBalance] = useState<Nullable<ICustomerWalletBalance>>(null);

	const totalBalanceStr =
		walletBalance && Object.keys(walletBalance).length > 0
			? `${walletBalance.balance} ${walletBalance.currency}`
			: 'N/A';

	// ! selectors
	const countryCurrency = useSelector(getSelectedCountryCurrency);

	// ! memos
	const fetchTransactionsConfig = useMemo(
		() => CUSTOMER_API.getCustomersWalletTransactions(customerId, countryCurrency),
		[customerId, countryCurrency]
	);

	// ! handlers
	const closeDebitModal = () => setOpenDebitModal(false);
	const closeCreditModal = () => setOpenCreditModal(false);

	const onDebitModalSubmit = (values: INewDebitCreditForm) => {
		const requestData: IPostNewDebitCreditData = {
			amount: Number.parseFloat(values.amount),
			comment: values.comment,
			currency: countryCurrency,
		};

		debitHttpClient.request({
			requestConfig: CUSTOMER_API.postCustomersWalletCreditDebit(customerId, 'debit', requestData),
			successCallback: () => {
				refreshingData();
				closeDebitModal();
			},
		});
	};

	const onCreditModalSubmit = (values: INewDebitCreditForm) => {
		const requestData: IPostNewDebitCreditData = {
			amount: Number.parseFloat(values.amount),
			comment: values.comment,
			currency: countryCurrency,
		};

		creditHttpClient.request({
			requestConfig: CUSTOMER_API.postCustomersWalletCreditDebit(customerId, 'credit', requestData),
			successCallback: () => {
				refreshingData();
				closeCreditModal();
			},
		});
	};

	const getWalletBalance = () => {
		fetchBalanceHttpClient.request({
			requestConfig: CUSTOMER_API.getCustomersWalletBalance(customerId, countryCurrency),
			successCallback: (response) => {
				setWalletBalance(response);
			},
		});
	};

	// ! effects
	useEffect(() => {
		getWalletBalance();
	}, [customerId, forceRefreshData]); // eslint-disable-line react-hooks/exhaustive-deps

	const isDebitBtnDisabled = isReadOnlyData || !hasPermission(APP_PERMISSIONS.customer.wallet.debit);
	const isCreditBtnDisabled = isReadOnlyData || !hasPermission(APP_PERMISSIONS.customer.wallet.credit);

	// ! render
	if (!customerId || !countryCurrency) {
		return null;
	}

	return (
		<>
			<TableWrapper<ICustomerWalletTransactionTableData, ICustomerWalletTransaction>
				disallowVerticalScroll
				columns={WALLET_TRANSACTIONS_TABLE_COLUMNS}
				requestConfig={fetchTransactionsConfig}
				defaultControlSizes='middle'
				searchPlaceholder={tCustomerWallet('search_placeholder')}
				defaultParams={{ ...LIST_DEFAULT_PARAMS, customer_id: customerId }}
				refetchData={forceRefreshData}
				transformDataToTableData={transformCustomerTransactionsToTableData}
				tableExtraActions={
					<Space size='large'>
						<Typography className={styles.total_balance}>
							<Space
								size='small'
								style={{ alignItems: 'flex-start' }}
							>
								{tCustomerWallet('current_balance')}
								<span className={styles.total_balance_value}>{totalBalanceStr}</span>
							</Space>
						</Typography>
						<Space wrap>
							<Button
								type='primary'
								disabled={isDebitBtnDisabled}
								onClick={() => setOpenDebitModal(true)}
							>
								{tCustomerWallet('debit_btn')}
							</Button>
							<CustomButton
								type='primary'
								disabled={isCreditBtnDisabled}
								onClick={() => setOpenCreditModal(true)}
								colorTokens={BUTTON_COLOR_SETS[EButtonColorTypes.btn_green]}
							>
								{tCustomerWallet('credit_btn')}
							</CustomButton>
						</Space>
					</Space>
				}
			/>
			{/* MODAL */}
			<NewDebitModal
				open={openDebitModal}
				onCancel={closeDebitModal}
				onSubmit={onDebitModalSubmit}
				confirmLoading={debitHttpClient.isLoading}
			/>
			<NewCreditModal
				open={openCreditModal}
				onCancel={closeCreditModal}
				onSubmit={onCreditModalSubmit}
				confirmLoading={creditHttpClient.isLoading}
			/>
		</>
	);
};

export default WalletHistoryTab;
