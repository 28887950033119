import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { disabledHours, disabledMinutes, reformatDate } from './helpers';
import { ITimingsRangeProps } from './types';
import { TIMINGS_DATE_PICKER_FORMAT } from '../config';
import styles from './TimingsRange.module.css';
import { Button, TimePicker, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const TimingsRange: FC<ITimingsRangeProps> = ({
	scheduleItem,
	onRemove,
	onTimeChange,
	isEdit = true,
	showRemoveButton = true,
}) => {
	const { open_time, close_time } = scheduleItem;

	const { t: tCommon } = useTranslation('common');
	const { t: tVendorTimings } = useTranslation('vendors', { keyPrefix: 'vendor_details.timings' });

	const openTime = reformatDate(open_time, 'open_time');
	const closeTime = reformatDate(close_time, 'close_time');

	const [isOpenTimeEditOpen, setOpenTimeEditOpen] = useState(false);
	const [isCloseTimeEditOpen, setCloseTimeEditOpen] = useState(false);

	// ! helpers
	const disabledTime = useCallback(
		() => ({
			disabledMinutes: (hour: number) => disabledMinutes(openTime, hour),
			disabledHours: () => disabledHours(openTime),
		}),
		[openTime]
	);

	// ! render
	return (
		<div className={isEdit ? styles.timing_wrap : ''}>
			{/* PICKERS */}
			<div>
				<TimePicker
					className={styles.time_picker}
					value={openTime}
					format={TIMINGS_DATE_PICKER_FORMAT}
					placeholder={tVendorTimings('open_time.placeholder')}
					allowClear={false}
					onChange={(timeValue) => onTimeChange('open_time', timeValue)}
					onOpenChange={(newState) => setOpenTimeEditOpen(isEdit && newState)}
					inputReadOnly={!isEdit}
					open={isOpenTimeEditOpen}
				/>
				{' - '}
				<TimePicker
					className={styles.time_picker}
					value={closeTime}
					disabledTime={disabledTime}
					format={TIMINGS_DATE_PICKER_FORMAT}
					placeholder={tVendorTimings('close_time.placeholder')}
					allowClear={false}
					onChange={(timeValue) => onTimeChange('close_time', timeValue)}
					inputReadOnly={!isEdit}
					onOpenChange={(newState) => setCloseTimeEditOpen(isEdit && newState)}
					open={isCloseTimeEditOpen}
				/>
			</div>

			{/* BUTTON */}
			{isEdit && showRemoveButton && (
				<div className={styles.action_buttons_wrap}>
					<Tooltip title={tCommon('action_buttons.remove')}>
						<Button
							size='small'
							type='primary'
							shape='default'
							icon={<DeleteOutlined />}
							onClick={onRemove}
						/>
					</Tooltip>
				</div>
			)}
		</div>
	);
};

export default TimingsRange;
