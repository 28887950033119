import { FC, ReactNode, memo } from 'react';
import { EVerticalType } from 'types/api';
import TranslatedLabel from 'components/TranslatedLabel';
import { Space, Tooltip } from 'antd';
import { StarFilled, StarOutlined } from '@ant-design/icons';

interface IVerticalTypeProps {
	type: EVerticalType;
	tooltip?: boolean;
}

const VerticalType: FC<IVerticalTypeProps> = ({ type, tooltip = false }) => {
	const config: Record<EVerticalType, ReactNode> = {
		[EVerticalType.DEFAULT]: <StarOutlined />,
		[EVerticalType.PREMIUM]: <StarFilled style={{ color: 'var(--ant-primary-color)' }} />,
		[EVerticalType.INDEPENDENT]: <StarFilled style={{ color: 'var(--ant-primary-2)' }} />,
	};

	const typeTranslation = (
		<TranslatedLabel
			nameSpace='verticals'
			extra={{ keyPrefix: 'enums.type' }}
			i18nKey={type}
		/>
	);

	return (
		<Space>
			<Tooltip title={tooltip ? typeTranslation : undefined}>{config[type]}</Tooltip>
			{!tooltip && typeTranslation}
		</Space>
	);
};

export default memo(VerticalType);
