import { FC, memo } from 'react';
import { DEFAULT_TAG_STYLES } from 'configs/common';
import { WALLET_TRANSACTION_TYPE_COLORS } from './configs';
import { IWalletTransactionTagProps } from './types';
import TranslatedLabel from 'components/TranslatedLabel';
import { Tag } from 'antd';

const WalletTransactionTag: FC<IWalletTransactionTagProps> = ({ type }) => {
	const color = WALLET_TRANSACTION_TYPE_COLORS[type];

	return (
		<div className='flex-center'>
			<Tag
				color={color}
				style={{ ...DEFAULT_TAG_STYLES, display: 'block', textAlign: 'center', minWidth: '72px' }}
			>
				<TranslatedLabel
					nameSpace='customers'
					i18nKey={type}
					extra={{ keyPrefix: 'customer_details.tabs.wallet_history.table_columns.transaction_type_tags' }}
				/>
			</Tag>
		</div>
	);
};

export default memo(WalletTransactionTag);
