import { Dayjs } from 'dayjs';

// ! types
export type TDocStatusToggleActionKey = 'approve' | 'reject';

// ! enums
export enum EDocumentStatus {
	PENDING = 'pending',
	APPROVED = 'approved',
	REJECTED = 'rejected',
}

// ! interfaces

export interface IRecordTimestamps {
	created_at: string | Dayjs;
	updated_at?: string | Dayjs;
}
