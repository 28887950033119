import { ICountry } from 'types/api';
import { ISelectOption } from 'types/common';
import CountryFlagImage from 'components/CountryFlagImage';
import styles from './CountrySelect.module.css';
import { Flex } from 'antd';

export const transformCountriesListToSelectOptions = (countries: ICountry[]): ISelectOption[] => {
	return countries.map(({ id, name, flag }) => ({
		value: id.toString(),
		label: (
			<Flex
				gap='small'
				align='center'
			>
				<CountryFlagImage flag={flag} />
				<div className={styles.country_name}>{name}</div>
			</Flex>
		),
	}));
};
