// ! enums & types
export enum EBannerListViewMode {
	TABLE = 'table',
	PREVIEW = 'preview',
}

export enum EBannerTypeTabs {
	HIGHLIGHTED_HOME = 'highlighted_home',
	HIGHLIGHTED_ACCOUNT = 'highlighted_account',
	PROMOTIONAL_SWIMLANE = 'promotional_swimlane', //matches MobileAppHomeScreenStoresListEntryType.BANNER
}
