import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_DESCRIPTIONS_PROPS, DEFAULT_EMPTY_VALUE_PLACEHOLDER, DEFAULT_TAG_STYLES } from 'configs/common';
import { EChoiceOption } from 'types/common';
import { IWebPresenceCardProps } from './types';
import { CopyToClipBoard } from 'components/CopyToClipBoard';
import { Card, Descriptions, Space, Tag } from 'antd';

const WebPresenceCard: FC<IWebPresenceCardProps> = ({ subdomain, slug, availableOnWeb = EChoiceOption.NO }) => {
	const { t: tPresence } = useTranslation('vendors', { keyPrefix: 'presence' });

	const isVendor = !!subdomain;

	const copyText = isVendor ? subdomain : slug;

	// ! render
	return (
		<Card
			title={tPresence('titles.web')}
			size='small'
		>
			<Space
				align='start'
				size='large'
			>
				<Descriptions {...DEFAULT_DESCRIPTIONS_PROPS}>
					<Descriptions.Item label={isVendor ? tPresence('subdomain') : tPresence('slug')}>
						{copyText ? <CopyToClipBoard>{copyText}</CopyToClipBoard> : DEFAULT_EMPTY_VALUE_PLACEHOLDER}
					</Descriptions.Item>
					<Descriptions.Item label={tPresence('available_on_web')}>
						<Tag style={DEFAULT_TAG_STYLES}>
							{availableOnWeb?.toUpperCase() ?? DEFAULT_EMPTY_VALUE_PLACEHOLDER}
						</Tag>
					</Descriptions.Item>
				</Descriptions>
			</Space>
		</Card>
	);
};

export default WebPresenceCard;
