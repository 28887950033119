import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useIntervalTimer } from 'hooks/useIntervalTimer';
import { TEmptyFunction } from 'types/common';

export interface IUseCountDown {
	counter: number;
	isEnable: boolean;
	reset: TEmptyFunction;
	toggleEnable: Dispatch<SetStateAction<boolean>>;
}
export const useCountDown = (from: number): IUseCountDown => {
	const { isEnable, intervalUpdate, toggleRefreshEnable } = useIntervalTimer(1000);

	const [counter, setCounter] = useState(from);

	useEffect(() => {
		setCounter((prev) => (prev === 1 ? from : prev - 1));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [intervalUpdate]);

	return {
		counter: counter,
		reset: () => setCounter(from),
		isEnable,
		toggleEnable: toggleRefreshEnable,
	};
};
