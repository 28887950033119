import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isReadOnlyCustomer } from './helpers';
import { throttle } from 'utils/throttle';
import { CUSTOMER_API } from 'configs/api';
import { ICustomer } from 'types/api';
import { Nullable } from 'types/common';
import { useNewHttpClient } from '../useHttpClient';
import { IHttpClientData } from '../useHttpClient/types';

interface ICustomerContextData {
	customerId: number;
	data: Nullable<ICustomer>;

	isReadOnlyData: boolean;

	// handlers
	fetchCustomerInformation: () => void;

	fetchHttpClient: IHttpClientData<ICustomer>;
}

const CustomerContext = createContext<ICustomerContextData>({} as ICustomerContextData);

const useCustomer = () => {
	return useContext(CustomerContext);
};

const CustomerProvider = ({ children }: PropsWithChildren) => {
	const { customerId } = useParams();

	const [data, setData] = useState<Nullable<ICustomer>>(null);

	// ! http clients
	const fetchHttpClient = useNewHttpClient<ICustomer>();

	// ! handlers
	const fetchCustomerInformation = throttle(() => {
		if (!customerId || fetchHttpClient.isLoading) return;

		fetchHttpClient.request({
			requestConfig: CUSTOMER_API.get(+customerId),
			successCallback: (data) => {
				setData(data);
			},
		});
	});

	// ! useEffects
	useEffect(() => {
		fetchCustomerInformation();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customerId]);

	// ! render
	if (!customerId) return null;

	const customerData = {
		customerId: +customerId,
		data,
		isReadOnlyData: isReadOnlyCustomer(data?.status),
		// * handlers
		fetchCustomerInformation,
		fetchHttpClient,
	};

	return <CustomerContext.Provider value={customerData}>{children}</CustomerContext.Provider>;
};

export default useCustomer;
export { CustomerProvider, useCustomer };
