import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store';
import { getIntegrationTypeSettings } from 'store/selectors/integrations';
import { getVendorIntegrationType } from 'store/selectors/vendor';
import TranslatedLabel from 'components/TranslatedLabel';
import { EVendorTabsKeys } from 'pages/Vendors/VendorDetails/Tabs/types';
import { IntegrationManualMenuSync } from './IntegrationManualMenuSync';
import { Alert, AlertProps } from 'antd';

interface IMenuIntegrationAlertProps extends AlertProps {
	pageType: EVendorTabsKeys;
}

export const IntegrationAlert: FC<IMenuIntegrationAlertProps> = ({ pageType, ...props }) => {
	const { t: tIntegrations } = useTranslation('integrations');

	// ! selectors
	const vendorDataIntegrationType = useSelector(getVendorIntegrationType);
	const integrationSettings = useSelector(getIntegrationTypeSettings(vendorDataIntegrationType));

	const pageTranslationName = tIntegrations(`page_types.${pageType}`);

	// ! render
	return (
		<Alert
			type='warning'
			showIcon
			action={integrationSettings?.manual_menu_sync ? <IntegrationManualMenuSync /> : undefined}
			message={
				<TranslatedLabel
					nameSpace='integrations'
					i18nKey='warning'
					extra={{
						page_name: pageTranslationName,
						integration_type: integrationSettings?.name,
					}}
				/>
			}
			{...props}
		/>
	);
};
