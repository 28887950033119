import { FC, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCountDown } from 'hooks';
import { IDataRefreshSwitchProps } from './types';
import styles from './DataRefreshSwitch.module.css';
import { Flex, Switch, Typography as T } from 'antd';

export const DataRefreshSwitch: FC<IDataRefreshSwitchProps> = memo(
	({ loading, isEnable, resetTrigger, refreshTTL, onSwitchChange }) => {
		const { t: tCommon } = useTranslation('common');

		const ttlInSeconds = refreshTTL / 1000;
		const { counter, toggleEnable: toggle, reset } = useCountDown(ttlInSeconds);

		// ! effects
		useEffect(() => {
			toggle(isEnable);
		}, [isEnable, toggle]);

		useEffect(() => {
			reset();
		}, [resetTrigger]); // eslint-disable-line react-hooks/exhaustive-deps

		// ! handlers
		const handleSwitchChange = (checked: boolean) => {
			onSwitchChange(checked);
			if (!checked) reset();
		};

		// ! render
		return (
			<Flex
				gap={8}
				align='center'
				className={styles.switch_container}
			>
				<Switch
					size='small'
					checked={isEnable}
					disabled={loading}
					onChange={handleSwitchChange}
				/>
				<T.Text>
					{isEnable ? (
						<Flex gap={8}>
							{tCommon('data_refresh_switch.on')}
							<span className={styles.counter}>
								{tCommon('data_refresh_switch.counter', { counter })}
							</span>
						</Flex>
					) : (
						<>{tCommon('data_refresh_switch.off', { ttl: ttlInSeconds })}</>
					)}
				</T.Text>
			</Flex>
		);
	}
);
