import { EStatus } from 'types/common';

// ! Enums
export enum ERequiredDocumentTypes {
	DRIVER = 'driver',
}

// ! Interfaces
export interface IRequiredDocument {
	id: number;
	key: string;
	name: string;
	name_ar: string;
	type: ERequiredDocumentTypes;
	status: EStatus;
}

export interface ICreateNewReqDocumentData {
	name: string;
	type: ERequiredDocumentTypes;
	status?: EStatus;
}
