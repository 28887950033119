import { FC, PropsWithChildren } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuth, useGlobalConfigs } from 'hooks';
import { ABSOLUTE_ROUTES } from 'configs/routes';

export const RequireAuthWrapper: FC<PropsWithChildren> = ({ children = <Outlet /> }) => {
	const location = useLocation();
	const authContext = useAuth();
	const { storage } = useGlobalConfigs();

	if (!authContext?.isAuthenticated(storage)) {
		return (
			<Navigate
				to={ABSOLUTE_ROUTES.LOGIN}
				state={{ from: location }}
				replace
			/>
		);
	}

	return <>{children}</>;
};
