import { THttpRequestConfig } from 'hooks';
import { INewSupplierFields, IUpdateSupplierFields } from 'types/api';

export const SUPPLIERS_API = {
	list: (search?: string): THttpRequestConfig => ({
		url: 'operations/suppliers',
		method: 'GET',
		params: {
			search,
		},
	}),
	// ! CRUD
	create: (data: INewSupplierFields): THttpRequestConfig => ({
		url: 'operations/suppliers',
		method: 'POST',
		data,
	}),

	update: (id: number, data: IUpdateSupplierFields): THttpRequestConfig => ({
		url: `operations/suppliers/${id}`,
		method: 'PATCH',
		data,
	}),

	delete: (id: number): THttpRequestConfig => ({
		url: `operations/suppliers/${id}`,
		method: 'DELETE',
	}),
};
