import { FC, PropsWithChildren } from 'react';
import { Card, CardProps } from 'antd';

export const CustomLayoutCard: FC<CardProps & PropsWithChildren> = ({
	children,
	style,
	headStyle,
	bodyStyle,
	...props
}) => {
	const newStyles = { border: 0, borderRadius: 16, height: '100%', ...style };
	const headStyles = { border: 'none', padding: '24px 24px 0 24px', ...headStyle };
	const bodyStyles = { height: '100%', ...bodyStyle };

	return (
		<Card
			style={newStyles}
			headStyle={headStyles}
			bodyStyle={bodyStyles}
			{...props}
		>
			{children}
		</Card>
	);
};
