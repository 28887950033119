import { Nullable } from '../types/common';
import dayjs from 'dayjs';
import dayJsDuration from 'dayjs/plugin/duration';
import dayJsTimezone from 'dayjs/plugin/timezone';
import dayJsUtc from 'dayjs/plugin/utc';

dayjs.extend(dayJsDuration);
dayjs.extend(dayJsTimezone);
dayjs.extend(dayJsUtc);

export const TIME_FORMAT = 'HH:mm';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_INTL_FORMAT = 'YYYY-MM-DD HH:mm';
export const DATE_TIME_INTL_SECONDS_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const formatDate = (
	date: string | Date | dayjs.Dayjs,
	timezone?: Nullable<string>,
	format: string = DATE_TIME_INTL_SECONDS_FORMAT
): string => {
	if (timezone?.length) {
		const tzDate = dayjs(date).tz(timezone).format(format);
		return `${tzDate} (${timezone})`;
	}
	// default
	return dayjs.tz(date).format(format);
};

export const transformToBEAcceptanceFormat = (date?: string | Date | dayjs.Dayjs) => {
	return dayjs(date).utc().toISOString();
};

// ! Time Diff
const getTimeDiffLabel = (duration: dayJsDuration.Duration): string => {
	const seconds = duration.seconds();
	const minutes = duration.minutes();
	const hours = duration.hours();
	const days = duration.days();

	let diff = '';

	if (+days > 0) diff += `${days} d `;

	if (+hours > 0) diff += `${hours} h `;

	if (+minutes > 0) diff += `${minutes} min `;

	if (!days && !hours && !minutes && +seconds >= 0) diff += 'a few seconds ';

	return (diff += 'ago');
};

export const getTimeDiff = (date: string | Date): string => {
	const now = dayjs();
	const utcDate = dayjs(date);

	return getTimeDiffLabel(dayjs.duration(now.diff(utcDate)));
};
