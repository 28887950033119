import { ReactNode, createContext, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteObject, useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'store';
import { getSettingsSlice } from 'store/selectors';
import { fetchCountries, setAccessibleCountriesList } from 'store/slices/countries';
import { useGlobalConfigs, useHandleErrors } from 'hooks';
import useFeatureVariantContext from 'hooks/FeatureVariantControl/useFeatureVariant';
import { fetchUserPermissions, isAuthenticated, processClientLogout, setDefaultCountry } from './helpers';
import { getAuthTokenFromLocalStorage, getRefreshTokenFromLocalStorage } from 'utils/localStorage';
import { getAllowedRoutes } from 'utils/routes';
import { AUTH_API, AUTH_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'configs/api';
import { DEFAULT_OPERATIONS_ROUTES_CONFIG } from 'configs/routes';
import { EHttpStatus, EOperationsUserType, IUserLogin, IUserLoginInfo } from 'types/api';
import { Nullable } from 'types/common';
import { IAuthContextData, IRoutesContextData, IUserAuthInfo, THasPermissionMethod } from './types';
import { AppError } from 'exceptions/AppError';

export const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

export const useRoutesHook = (user: Nullable<IUserAuthInfo>): IRoutesContextData => {
	const { currentFeatureVariantSelection } = useFeatureVariantContext();

	const [allowedRoutes, setAllowedRoutes] = useState<RouteObject[]>([]);
	const { t: tCommon } = useTranslation('common');

	useEffect(() => {
		if (user) {
			const { permissions, isSuperAdmin } = user;
			const newAllowedRoutes = getAllowedRoutes(
				[...DEFAULT_OPERATIONS_ROUTES_CONFIG],
				permissions,
				currentFeatureVariantSelection,
				isSuperAdmin
			);

			if (!newAllowedRoutes.length) {
				throw new AppError(tCommon('exceptions.no_allowed_routes'), EHttpStatus.UNAUTHORIZED);
			}

			setAllowedRoutes(newAllowedRoutes);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);
	return { allowedRoutes };
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { http, storage } = useGlobalConfigs();
	const { handleError } = useHandleErrors();
	const { currentFeatureVariantSelection } = useFeatureVariantContext();
	const { t: tCommon } = useTranslation('common');

	// ! state
	const [user, setUser] = useState<Nullable<IUserAuthInfo>>(null);
	const [isSigningOut, setIsSigningOut] = useState(false);

	const routesContext = useRoutesHook(user);

	// ! selectors
	const { selectedCountryId } = useSelector(getSettingsSlice);

	// ! helpers
	const setUserPermissionsRoutes = (permissions: string[], allowedRoutes: RouteObject[]) => {
		setUser((prev) => {
			if (!prev) return prev;
			return {
				...prev,
				permissions,
				allowedRoutes,
			};
		});
	};

	const updateUserPermissions = async () => {
		if (!user) return false;

		try {
			const permissions = await fetchUserPermissions(http);

			const allowedRoutes = getAllowedRoutes(
				[...DEFAULT_OPERATIONS_ROUTES_CONFIG],
				permissions,
				currentFeatureVariantSelection,
				user.isSuperAdmin
			);
			if (!allowedRoutes.length) throw new Error();

			setUserPermissionsRoutes(permissions, allowedRoutes);
			return true;
		} catch (error) {
			setUserPermissionsRoutes([], []);

			const defaultError = new AppError(tCommon('exceptions.no_allowed_routes'), EHttpStatus.UNAUTHORIZED);
			handleError(defaultError, true);

			return false;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	};

	const setUserDetails = ({ name, email }: Pick<IUserLoginInfo, 'name' | 'email'>) => {
		setUser((prev) => {
			if (!prev) return prev;

			return { ...prev, name, email };
		});
	};

	const hasPermission = useCallback<THasPermissionMethod>(
		(requiredPermissionsArg) => {
			if (!requiredPermissionsArg) {
				return true;
			}

			if (!user) return false;
			if (user.isSuperAdmin) return true;

			const requiredPermissions = !Array.isArray(requiredPermissionsArg)
				? [requiredPermissionsArg]
				: requiredPermissionsArg;

			if (!requiredPermissions.length) {
				return true;
			}

			const userPermissions = user.permissions;
			if (!userPermissions.length) return false;

			return requiredPermissions.every((requiredPermission) => userPermissions.includes(requiredPermission.code));
		},
		[user]
	);

	// ! handlers
	const processUserData = async (loginInfo: IUserLogin): Promise<IUserAuthInfo> => {
		const { user, access_token, refresh_token, ...restLoginInfo } = loginInfo;

		storage.set(AUTH_TOKEN_KEY, access_token);
		storage.set(REFRESH_TOKEN_KEY, refresh_token);

		// ? check if user has accessible countries and set them into redux
		const accessibleCountries = user.accessible_countries;
		if (!accessibleCountries.length) {
			throw new AppError(tCommon('exceptions.no_accessible_countries'), EHttpStatus.UNAUTHORIZED);
		}

		// ? set preselected country or first available
		setDefaultCountry(storage, accessibleCountries[0], dispatch);

		dispatch(setAccessibleCountriesList(accessibleCountries));
		dispatch(fetchCountries({ http, handleError }));

		// ? get permissions for selected country
		const permissions = await fetchUserPermissions(http);

		// ? initialize additional variables
		const isSuperAdmin = user.user_type === EOperationsUserType.SUPER_ADMIN;

		const allowedRoutes = getAllowedRoutes(
			[...DEFAULT_OPERATIONS_ROUTES_CONFIG],
			permissions,
			currentFeatureVariantSelection,
			isSuperAdmin
		);

		// ? return user info
		return {
			...restLoginInfo,
			...user,
			allowedRoutes,
			access_token,
			refresh_token,
			isSuperAdmin,
			permissions,
		};
	};

	const signin = (email: string, password: string) => {
		return http(AUTH_API.login(email, password))
			.then(({ data }: AxiosResponse<IUserLogin>) => processUserData(data))
			.then((userInfo: IUserAuthInfo) => {
				setUser(userInfo);
				return userInfo;
			})
			.catch((error) => {
				handleError(error, true);

				return Promise.reject(error);
			});
	};

	const signout = async () => {
		setIsSigningOut(true);

		http(AUTH_API.logout())
			.catch((error) => {
				/*
					Do not need to handle error, we need to log out user in all cases in finally.
					We need catch here to prevent uncaught error in runtime, if backend throws any http error
				*/
				console.error(error);
			})
			.finally(() => {
				setIsSigningOut(false);
				// clear user auth data
				setUser(null);
				processClientLogout(storage, navigate);
			});
	};

	const profile = (signal: AbortSignal) => {
		const requestConfig = { ...AUTH_API.profile(), signal };

		http(requestConfig)
			.then(({ data }: AxiosResponse<IUserLoginInfo>) =>
				processUserData({
					user: data,
					access_token: getAuthTokenFromLocalStorage(storage),
					refresh_token: getRefreshTokenFromLocalStorage(storage),
				})
			)
			.then((userInfo: IUserAuthInfo) => {
				setUser(userInfo);
			})
			.catch((error) => handleError(error, true));
	};

	// ! effects
	useEffect(() => {
		if (isAuthenticated(storage)) {
			const ctrl = new AbortController();

			profile(ctrl.signal);

			// Unsubscribing
			return () => ctrl.abort();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (selectedCountryId) updateUserPermissions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCountryId]);

	const authData: IAuthContextData = {
		user,
		setUserDetails,
		signin,
		signout,
		isSigningOut,
		hasPermission,
		isAuthenticated: () => isAuthenticated(storage),

		// TO EXTRACT
		routesContext,
	};

	return <AuthContext.Provider value={authData}>{children}</AuthContext.Provider>;
};
