import { TFunction } from 'i18next';
import { ICustomerOrder } from 'types/api';
import { ICustomerOrderListTableData } from './types';

// ! data format
export const transformICustomerOrderToTableData =
	(tCommon: TFunction<'common'>) =>
	(order: ICustomerOrder): ICustomerOrderListTableData => {
		const { id, info, store, payment, actions } = order;

		return {
			key: id,
			id,
			order_code: info.code,
			branch: store,
			total_cost: payment.amount_payable,
			currency_code: payment.currency_code,
			payment_type: tCommon(`order.payment.types.${payment.method}`),
			status: info.status,
			actions: actions,
		};
	};
