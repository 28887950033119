import { useEffect } from 'react';
import { useDispatch, useSelector } from 'store';
import { getSettingsSlice } from 'store/selectors';
import { fetchGoogleMapsApiKey } from 'store/slices/settings';
import useGlobalConfigs from './useGlobalConfig';
import useHandleErrors from './useHandleErrors';

export const useGoogleMapsApiKey = () => {
	const dispatch = useDispatch();
	const { http } = useGlobalConfigs();
	const { handleError } = useHandleErrors();

	// ! selectors
	const { loading: mapApiKeyLoading, mapApiKey } = useSelector(getSettingsSlice);

	// ! useEffect
	useEffect(() => {
		dispatch(fetchGoogleMapsApiKey({ http, handleError }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		mapApiKey,
		mapApiKeyLoading,
	};
};
