import { RouteObject } from 'react-router-dom';
import { isFeatureAvailableByRouteMatchABVersion } from 'hooks/FeatureVariantControl/helpers';
import { IFeatureVariantCurrentSelection } from 'hooks/FeatureVariantControl/types';
import { IPermissionConfigItem } from 'configs/permissions';

export const isAllowed = (userPermissions: string[], routePermissions: IPermissionConfigItem[]) => {
	if (!routePermissions.length) return true;

	return routePermissions.every((routePermission) => userPermissions.includes(routePermission.code));
};

export const getABAllowedRoutes = (
	routes: RouteObject[] = [],
	currentSelectedABs: IFeatureVariantCurrentSelection
): RouteObject[] => {
	let result: RouteObject[] = [];

	result = routes.reduce((acc, route) => {
		if (isFeatureAvailableByRouteMatchABVersion(currentSelectedABs, [route])) {
			route.children = getABAllowedRoutes(route.children, currentSelectedABs);

			acc.push(route);
		}

		return acc;
	}, result);

	return result;
};

export const getAllowedRoutes = (
	routes: RouteObject[],
	userPermissions: string[],
	currentSelectedABs: IFeatureVariantCurrentSelection,
	isSuperAdmin?: boolean
) => {
	routes = getABAllowedRoutes(routes, currentSelectedABs);

	if (isSuperAdmin) return routes;

	return routes.reduce<RouteObject[]>((filteredRoutes, route) => {
		if (isSuperAdmin) return routes;

		const routeAllowed = isAllowed(userPermissions, route.handle?.permissions ?? []);
		if (!routeAllowed) return filteredRoutes;

		let children: RouteObject[] = [];

		if (route.children?.length) {
			children = getAllowedRoutes(route.children, userPermissions, currentSelectedABs, isSuperAdmin);
			if (!children.length) return filteredRoutes;
		}

		filteredRoutes.push({
			...route,
			children,
		} as RouteObject);

		return filteredRoutes;
	}, []);
};
