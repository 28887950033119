import { DEFAULT_EMPTY_VALUE_PLACEHOLDER } from 'configs/common';
import { IArea } from 'types/api';
import { IAreasTableData } from 'pages/Areas/AreasList';

export const transformIAreaToTableData = (area: IArea): IAreasTableData => {
	return {
		key: area.id,
		...area,
		name_ar: area.name_ar || DEFAULT_EMPTY_VALUE_PLACEHOLDER,
	};
};
