import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppBrand } from 'hooks';
import { Result } from 'antd';
import cariImg from 'assets/logos/getcari-small-logo-new.svg';
import dukanImg from 'assets/logos/getdukan-small-logo-new.svg';

export const EntryLobby: FC = () => {
	const { t: tLobby } = useTranslation('lobby');
	const { brand } = useAppBrand();

	return (
		<>
			<Result
				icon={
					<img
						src={brand === 'getcari' ? cariImg : dukanImg}
						style={{ height: 300 }}
						alt='icon'
					/>
				}
				title={tLobby('title', { brand: brand.toUpperCase() })}
				subTitle={tLobby('subtitle')}
			/>
		</>
	);
};
