import { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext, useRef, useState } from 'react';
import { IBanner } from 'types/api';
import { Nullable, TEmptyFunction } from 'types/common';
import AddEditBannerModal from './components/AddEditBannerModal';
import { IBannersTableData } from './components/BannerList/types';

interface IAddEditBannerContextData {
	isModalOpen: boolean;
	setIsModalOpen: Dispatch<SetStateAction<boolean>>;
	// banner info (of editing)
	setBannerData: Dispatch<SetStateAction<Nullable<IBannersTableData | IBanner>>>;
	// set refreshing callback
	setRefreshingDataAfterCloseModalCallback: (refreshFn: Nullable<TEmptyFunction>) => void;
}

// ! CONTEXT ----
const AddEditBannerContext = createContext<IAddEditBannerContextData>({} as IAddEditBannerContextData);

export const useAddEditBannerContext = () => useContext(AddEditBannerContext);

// ! PROVIDER ----
export const AddEditBannerProvider = ({ children }: PropsWithChildren) => {
	// ! states
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [bannerData, setBannerData] = useState<Nullable<IBannersTableData | IBanner>>(null);

	// ! refs
	const refreshingDataAfterCloseModalRef = useRef<Nullable<TEmptyFunction>>(null);

	// ! handlers
	const setRefreshingDataAfterCloseModalCallback = (refreshFn: Nullable<TEmptyFunction>) => {
		refreshingDataAfterCloseModalRef.current = refreshFn;
	};

	const onCloseModal = (withRefreshData?: boolean) => {
		setIsModalOpen(false);
		setBannerData(null);

		if (withRefreshData && refreshingDataAfterCloseModalRef.current) {
			refreshingDataAfterCloseModalRef.current();
		}
	};

	// ! provider render
	const data: IAddEditBannerContextData = {
		isModalOpen,
		setIsModalOpen,
		// banner info (of editing)
		setBannerData,
		// set refreshing callback
		setRefreshingDataAfterCloseModalCallback,
	};

	return (
		<AddEditBannerContext.Provider value={data}>
			<>
				{children}

				<AddEditBannerModal
					open={isModalOpen}
					banner={bannerData}
					onClose={onCloseModal}
				/>
			</>
		</AddEditBannerContext.Provider>
	);
};
