import { FC } from 'react';
import TranslatedLabel from 'components/TranslatedLabel';
import styles from './CariUnlimitedTag.module.css';
import { Flex } from 'antd';
import { FireOutlined } from '@ant-design/icons';

export const CariUnlimitedTag: FC = () => (
	<div className={styles.cari_tag}>
		<Flex gap={6}>
			<FireOutlined />
			<TranslatedLabel
				nameSpace='cari-unlimited'
				i18nKey={'unlimited'}
			/>
		</Flex>
	</div>
);
