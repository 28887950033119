import { FC, PropsWithChildren } from 'react';
import { DEFAULT_TAG_STYLES } from 'configs/common';
import { USER_TYPES_TAG_COLORS, USER_TYPES_TAG_ICONS } from './configs';
import { IUserRoleProps } from './types';
import TranslatedLabel from 'components/TranslatedLabel';
import { Tag } from 'antd';

const WithTagWrapper: FC<IUserRoleProps & PropsWithChildren> = ({ role, asTag, children }) => {
	const icon = role && USER_TYPES_TAG_ICONS[role];
	const tagColor = role && USER_TYPES_TAG_COLORS[role];

	// ! render
	return asTag ? (
		<Tag
			icon={icon}
			color={tagColor}
			style={{ ...DEFAULT_TAG_STYLES, textTransform: 'capitalize' }}
		>
			{children}
		</Tag>
	) : (
		<>{children}</>
	);
};

export const UserRole: FC<IUserRoleProps> = ({ id, name, role, asTag }) => {
	// ! render
	return (
		<WithTagWrapper
			asTag={asTag}
			role={role}
		>
			{role && (
				<TranslatedLabel
					i18nKey={role}
					extra={{ keyPrefix: 'user_types' }}
				/>
			)}

			{id && name ? `#${id} - ${name}` : null}
		</WithTagWrapper>
	);
};
