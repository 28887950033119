import { FC, PropsWithChildren } from 'react';
import { DEFAULT_TAG_STYLES } from 'configs/common';
import { EStatus } from 'types/common';
import TranslatedLabel from 'components/TranslatedLabel';
import { Tag } from 'antd';

export const ActiveStatusTag: FC<PropsWithChildren<{ status: EStatus }>> = ({
	status,
	children = (
		<TranslatedLabel
			i18nKey={status}
			extra={{ keyPrefix: 'on_off_switch' }}
		/>
	),
}) => {
	const colorByStatus = {
		[EStatus.ACTIVE]: 'green',
		[EStatus.INACTIVE]: 'red',
	};

	return (
		<Tag
			color={colorByStatus[status]}
			style={DEFAULT_TAG_STYLES}
		>
			{children}
		</Tag>
	);
};
