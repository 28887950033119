import { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'store';
import { getSelectedCountryCurrency } from 'store/selectors';
import { IProduct } from 'types/api';
import { Nullable } from 'types/common';

interface IProductContextData {
	productId: number;
	categoryId?: string;
	subCategoryId?: string;
	currency: string;
	productData: Nullable<IProduct>;
	setProductData: Dispatch<SetStateAction<Nullable<IProduct>>>;
}

const useProduct = () => {
	return useContext(ProductContext);
};

const ProductContext = createContext<IProductContextData>({} as IProductContextData);

const ProductProvider = ({ productId: productIdArg, children }: PropsWithChildren<Partial<IProductContextData>>) => {
	const { productId = productIdArg, categoryId, subCategoryId } = useParams();

	const [productData, setProductData] = useState<Nullable<IProduct>>(null);

	// ! selectors
	const currency = useSelector(getSelectedCountryCurrency);

	if (!productId) return null;

	const vendorData = { productId: +productId, categoryId, subCategoryId, currency, productData, setProductData };

	return <ProductContext.Provider value={vendorData}>{children}</ProductContext.Provider>;
};

export { useProduct, ProductProvider };
export default useProduct;
