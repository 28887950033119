import { lazy } from 'react';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import { DashboardOutlined } from '@ant-design/icons';

const PagePermissions = lazy(() => import('pages/PagePermissions'));

export const PAGE_PERMISSIONS_ROUTES: TRouteObject = {
	path: 'page-permissions',
	element: <PagePermissions />,
	handle: {
		translationKey: 'page_permissions',
		permissions: [APP_PERMISSIONS.role.view],
		icon: <DashboardOutlined />,
		showOnNav: true,
	},
};
