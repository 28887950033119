import { FC, PropsWithChildren, Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import { EVerticalType } from 'types/api';
import { EVerticalDefaultTabs } from './types';
import Spinner from 'components/Spinner';
import { ActiveStatusTag } from 'components/Tags/ActiveStatusTag';
import TranslatedLabel from 'components/TranslatedLabel';
import ErrorPage from 'pages/ErrorPage';
import useVertical from 'pages/Verticals/hooks/useVertical';
import VerticalTypeRender from '../components/VerticalType';
import { Space, Tabs } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { Tab } from 'rc-tabs/lib/interface';

export const VerticalDetailsPageLayout: FC<PropsWithChildren> = ({ children = <Outlet /> }) => {
	const { t: tVerticals } = useTranslation('verticals');
	const navigate = useNavigate();
	const { id, data, loading, error } = useVertical();
	const match = useMatch('/:countryIsoTwoCode/verticals/:verticalId/:verticalTab/*');

	// ! handlers
	const onBack = () => {
		navigate('..');
	};
	const onTabChange = (activeKey: EVerticalDefaultTabs) => {
		navigate(activeKey);
	};

	// ! memos
	const tabItems: Tab[] = useMemo(() => {
		if (!data) return [];

		const tabsByVerticalType: Record<EVerticalType, EVerticalDefaultTabs[]> = {
			[EVerticalType.DEFAULT]: [EVerticalDefaultTabs.DETAILS, EVerticalDefaultTabs.SUB_VERTICALS],
			[EVerticalType.PREMIUM]: [EVerticalDefaultTabs.DETAILS],
			[EVerticalType.INDEPENDENT]: [EVerticalDefaultTabs.DETAILS, EVerticalDefaultTabs.INDEPENDENT_VENDORS],
		};

		return tabsByVerticalType[data.type].map((tabValue) => ({
			key: tabValue,
			label: (
				<TranslatedLabel
					nameSpace='verticals'
					i18nKey={tabValue}
					extra={{ keyPrefix: 'enums.tabs' }}
				/>
			),
		}));
	}, [data]);

	// ! useEffects

	// ! handlers

	// ! render
	if (error) {
		return (
			<ErrorPage
				status={error.status}
				title={error.status}
				subTitle={error.data?.message}
			/>
		);
	}

	if (!data || loading) {
		return <Spinner defaultAntdSpinner />;
	}

	return (
		<>
			{loading && <Spinner defaultAntdSpinner />}

			<PageHeader
				title={tVerticals('page_titles.details')}
				subTitle={`#${id}`}
				onBack={onBack}
				tags={
					<Space key='vertical_tags'>
						<VerticalTypeRender
							type={data.type}
							tooltip
						/>
						<ActiveStatusTag status={data.status} />
					</Space>
				}
			/>

			<Tabs
				items={tabItems}
				destroyInactiveTabPane
				activeKey={match?.params.verticalTab}
				onTabClick={(activeKey) => onTabChange(activeKey as EVerticalDefaultTabs)}
			/>
			<Suspense fallback={<Spinner defaultAntdSpinner />}>{children}</Suspense>
		</>
	);
};
