import { DEFAULT_FORM_ITEMS_LAYOUT } from 'configs/common';
import { TAppFormRenderHelper } from './types';
import { Input, Radio, Select } from 'antd';

export const getAdditionalFormProps = (defaultFormItemsLayout?: boolean) => {
	if (defaultFormItemsLayout) return DEFAULT_FORM_ITEMS_LAYOUT;
	return {};
};

const renderSelect: TAppFormRenderHelper = ({ disabled, inputSelectionOptions, selectProps }, loading) => {
	return (
		<Select
			disabled={disabled}
			loading={loading}
			options={inputSelectionOptions}
			{...selectProps}
		/>
	);
};

const renderInput: TAppFormRenderHelper = ({ disabled, inputElement, textAreaProps = {}, inputProps = {} }) => {
	const Element = inputElement || Input;

	if (Element === Input.TextArea) {
		return (
			// @ts-ignore
			<Element
				disabled={disabled}
				{...textAreaProps}
			/>
		);
	}

	return (
		// @ts-ignore
		<Element
			disabled={disabled}
			{...inputProps}
		/>
	);
};

const renderRadio: TAppFormRenderHelper = ({ disabled, radioOptions, optionType, buttonStyle }) => {
	return (
		<Radio.Group
			disabled={disabled}
			optionType={optionType}
			buttonStyle={buttonStyle}
		>
			{radioOptions?.map(({ value, label }, index) => (
				<Radio
					key={index}
					value={value}
				>
					{label}
				</Radio>
			))}
		</Radio.Group>
	);
};

export const renderHelper: TAppFormRenderHelper = (configItem, loading) => {
	const { radioOptions, inputSelectionOptions } = configItem;

	if (inputSelectionOptions) return renderSelect(configItem, loading);

	if (radioOptions) return renderRadio(configItem);

	return renderInput(configItem);
};
