import { AxiosResponse } from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { errorCase, pendingCase } from 'store/helpers';
import { UTC_TIME_ZONE } from 'hooks/useLocaleTimeUtils';
import { THttpFunction } from 'utils/axiosInstance';
import { THandleError } from 'utils/handleError';
import { IStorageUtils } from 'utils/localStorage';
import { CONFIGURATIONS_API } from 'configs/api';
import {
	APP_LANGUAGE_STORAGE_KEY,
	AREAS_ID_STORAGE_KEY,
	COUNTRY_ID_STORAGE_KEY,
	TIMEZONE_STORAGE_KEY,
} from 'configs/common';
import { IFirebaseGoogleMapsConfig, IGeneralSettingsItem } from 'types/api';
import { ESupportedLanguages, Nullable } from 'types/common';
import { IActionArg, IDefaultStateFields } from '../types';

// ! initial state
export interface ISettingsSlice extends IDefaultStateFields {
	generalSettings: Nullable<Record<string, IGeneralSettingsItem>>;
	// global
	language: ESupportedLanguages;
	selectedCountryId: Nullable<number>;
	selectedAreasId: React.Key[];
	selectedTimezone: string;

	mapApiKey: Nullable<string>;
	error: Nullable<any>;
}

export const fetchGoogleMapsApiKey = createAsyncThunk<
	// Response
	string,
	// Args
	{
		http: THttpFunction;
		handleError: THandleError;
	},
	// thunk configs
	{ state: RootState }
>(
	'settingsSlice/fetchGoogleMapsApiKey',
	async ({ http, handleError }, { rejectWithValue }) => {
		try {
			const { data }: AxiosResponse<IFirebaseGoogleMapsConfig> = await http(CONFIGURATIONS_API.getMapConfig());

			return data.apiKey;
		} catch (error) {
			handleError(error, true);
			return rejectWithValue(error);
		}
	},
	{
		condition: (args, { getState }) => {
			const { settings }: RootState = getState();

			if (settings.mapApiKey) {
				// Already fetched or in progress, don't need to re-fetch
				return false;
			}
		},
	}
);

const settingsSliceInitialState: ISettingsSlice = {
	generalSettings: null,
	// global
	language: (localStorage.getItem('i18nextLng') as ESupportedLanguages) ?? ESupportedLanguages.ENGLISH,
	selectedCountryId: null,
	selectedAreasId: [],
	selectedTimezone: UTC_TIME_ZONE,

	mapApiKey: null,

	// own state
	error: null,
	loading: false,
};

const settingsSlice = createSlice({
	name: 'settingsSlice',
	initialState: settingsSliceInitialState,
	reducers: {
		setLanguage: (state, action: IActionArg<ESupportedLanguages>) => {
			state.language = action.payload;
			localStorage.setItem(APP_LANGUAGE_STORAGE_KEY, state.language);
		},
		setSelectedTimezone: (
			state,
			{ payload }: IActionArg<{ newSelectedTimezone: string; storage: IStorageUtils }>
		) => {
			const { newSelectedTimezone, storage } = payload;

			state.selectedTimezone = newSelectedTimezone;
			storage.set(TIMEZONE_STORAGE_KEY, newSelectedTimezone);
		},
		setSelectedCountry: (
			state,
			{ payload }: IActionArg<{ newSelectedCountryId: number; storage: IStorageUtils }>
		) => {
			const { newSelectedCountryId, storage } = payload;
			storage.set(COUNTRY_ID_STORAGE_KEY, newSelectedCountryId.toString());
			state.selectedCountryId = newSelectedCountryId;
		},

		setSelectedAreas: (
			state,
			{ payload }: IActionArg<{ selectedAreas: Nullable<number[]>; storage: IStorageUtils }>
		) => {
			const { selectedAreas, storage } = payload;

			if (selectedAreas?.length) {
				storage.set(AREAS_ID_STORAGE_KEY, selectedAreas);
				state.selectedAreasId = selectedAreas;
			} else {
				storage.remove(AREAS_ID_STORAGE_KEY);
			}
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchGoogleMapsApiKey.pending, pendingCase)
			.addCase(fetchGoogleMapsApiKey.rejected, (state, { payload }) => {
				state.error = payload;
				errorCase(state);
			})
			.addCase(fetchGoogleMapsApiKey.fulfilled, (state, { payload }) => {
				state.mapApiKey = payload;
				state.loading = false;
			});
	},
});

export const { setLanguage, setSelectedCountry, setSelectedAreas, setSelectedTimezone } = settingsSlice.actions;

export default settingsSlice.reducer;
