import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth, useForceRefreshData, useNewHttpClient } from 'hooks';
import { CARI_PRIZE_CAMPAIGN_API } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TEmptyFunction } from 'types/common';
import {
	ECariPrizeCampaignFields,
	ECariPrizeCampaignStatus,
	IAssociateVendors,
	ICariPrizeCampaign,
	ICariPrizeUpdatePayload,
} from './types';
import dayjs from 'dayjs';

interface ICariPrizeCampaignContextData {
	campaignId?: number;
	data?: ICariPrizeCampaign;
	loading: boolean;
	errors: any;
	refreshingData: TEmptyFunction;

	isEditDisable: boolean;

	// ! handles
	handleUpdate: (payload: ICariPrizeUpdatePayload) => Promise<void>;
	handleRemoveVendor: (id: React.Key) => Promise<void>;
	handleResetPointConversion: (id: React.Key) => Promise<void>;
	handleAssociateVendors: (payload: IAssociateVendors) => Promise<void>;
}

const CariPrizeCampaignContext = createContext<ICariPrizeCampaignContextData>({} as ICariPrizeCampaignContextData);

const useCariPrizeCampaign = () => {
	return useContext(CariPrizeCampaignContext);
};

export default useCariPrizeCampaign;

export const CariPrizeCampaignProvider = ({ children }: PropsWithChildren) => {
	const { campaignId } = useParams();
	const { hasPermission } = useAuth();

	const [errors, setErrors] = useState();
	const { forceRefreshData, refreshingData } = useForceRefreshData();
	const [campaignData, setCampaignData] = useState<ICariPrizeCampaign>();

	// ! http clients
	const fetchHttpClient = useNewHttpClient<ICariPrizeCampaign>();
	const updateHttpClient = useNewHttpClient();
	const removeVendorHttpClient = useNewHttpClient();
	const resetPointsHttpClient = useNewHttpClient();
	const associateVendorsHttpClient = useNewHttpClient();

	const isEditDisable = useMemo(() => {
		const canUpdate = hasPermission([
			APP_PERMISSIONS.cari_prize_campaign.update,
			APP_PERMISSIONS.cari_prize_campaign.add,
		]);

		return (
			!canUpdate ||
			(campaignData?.[ECariPrizeCampaignFields.STATUS] === ECariPrizeCampaignStatus.COMPLETED ?? true)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [campaignData]);

	useEffect(() => {
		if (campaignId) {
			fetchHttpClient.request({
				requestConfig: CARI_PRIZE_CAMPAIGN_API.get(campaignId),
				successCallback: (response) => {
					const campaignData: ICariPrizeCampaign = {
						...response,
						[ECariPrizeCampaignFields.START_DATE]: dayjs(response[ECariPrizeCampaignFields.START_DATE]),
						[ECariPrizeCampaignFields.END_DATE]: dayjs(response[ECariPrizeCampaignFields.END_DATE]),
						[ECariPrizeCampaignFields.TIME_RANGE]: [
							dayjs(response[ECariPrizeCampaignFields.START_DATE]),
							dayjs(response[ECariPrizeCampaignFields.END_DATE]),
						],
					};

					setCampaignData(campaignData);
				},
				displayErrorMsg: false,
				errorCallback: (error) => {
					setErrors(error);
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [campaignId, forceRefreshData]);

	const vendorData: ICariPrizeCampaignContextData = {
		campaignId: campaignId ? +campaignId : undefined,
		data: campaignData,
		loading: fetchHttpClient.isLoading,
		errors,
		isEditDisable,
		refreshingData,

		// ! handlers
		handleUpdate: (payload: ICariPrizeUpdatePayload) => {
			if (!campaignId) return Promise.reject();

			return updateHttpClient.request({
				requestConfig: CARI_PRIZE_CAMPAIGN_API.patch(campaignId, payload),
			});
		},
		handleRemoveVendor: (vendorId: React.Key) => {
			if (!campaignId) return Promise.reject();

			return removeVendorHttpClient.request({
				requestConfig: CARI_PRIZE_CAMPAIGN_API.removeVendor(campaignId, { vendors_id: [vendorId] }),
			});
		},
		handleResetPointConversion: (vendorId: React.Key) => {
			if (!campaignId) return Promise.reject();

			return resetPointsHttpClient.request({
				requestConfig: CARI_PRIZE_CAMPAIGN_API.associateVendors(campaignId, {
					vendors_id: [vendorId],
				}),
			});
		},
		handleAssociateVendors: (payload: IAssociateVendors) => {
			if (!campaignId) return Promise.reject();

			return associateVendorsHttpClient.request({
				requestConfig: CARI_PRIZE_CAMPAIGN_API.associateVendors(campaignId, payload),
			});
		},
	};

	return <CariPrizeCampaignContext.Provider value={vendorData}>{children}</CariPrizeCampaignContext.Provider>;
};
