import icon from 'assets/images/citc.png';

const CITCIcon = () => {
	return (
		<img
			className='anticon anticon-unordered-list'
			src={icon}
			alt=''
			width={20}
			height={20}
		/>
	);
};

export default CITCIcon;
