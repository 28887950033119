import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_MODAL_PROPS } from 'configs/common';
import { IApplyToAllModalProps } from './types';
import TimingsDay from '../TimingsDay';
import { Modal } from 'antd';

const ApplyToAllModal: FC<IApplyToAllModalProps> = ({
	open,
	selectedAllShift,
	onClose,
	onOk,
	onTimeChange,
	onAddScheduleItem,
	onRemoveScheduleItem,
}) => {
	const { t: tCommon } = useTranslation();
	const { t: tWorkShifts } = useTranslation('vendors', { keyPrefix: 'vendor_details.tabs.working_shifts' });

	const handleConfirm = () => {
		onOk();
		onClose();
	};

	return (
		<Modal
			{...DEFAULT_MODAL_PROPS}
			open={open}
			title={tWorkShifts('apply_to_all_modal.title')}
			okText={tCommon('action_buttons.continue')}
			onOk={handleConfirm}
			onCancel={onClose}
		>
			<TimingsDay
				timingsItem={selectedAllShift}
				onTimeChange={(day, index, timeType, timeValue) => onTimeChange(day, index, timeType, timeValue)}
				onAddScheduleItem={onAddScheduleItem}
				onRemoveScheduleItem={onRemoveScheduleItem}
			/>
		</Modal>
	);
};

export default ApplyToAllModal;
