import { THttpRequestConfig } from 'hooks';
import { IPostPushNotificationData } from 'types/api';

export const PUSH_NOTIFICATIONS_API = {
	list: (): THttpRequestConfig => ({
		url: 'operations/push-notifications',
		method: 'GET',
	}),

	getSegmentsList: (): THttpRequestConfig => ({
		url: 'operations/push-notification-segments',
		method: 'GET',
	}),

	create: (data: IPostPushNotificationData): THttpRequestConfig => ({
		url: 'operations/push-notifications/send',
		method: 'POST',
		data,
	}),
};
