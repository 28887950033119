import { useEffect, useMemo, useState } from 'react';
import { useNewHttpClient } from 'hooks';
import { transformCITCEntryResponseToSelectOption } from './helper';
import { CITC_API } from 'configs/api';
import { ICITCEntryResponse, ICITCListResponse } from 'types/api';
import { ISelectOption } from 'types/common';
import { App, FormInstance } from 'antd';

export const useCitcCities = (regionIdArg: string, form: FormInstance) => {
	const { message } = App.useApp();
	const [regionId, fetchCitiesFromRegion] = useState(regionIdArg);

	const [data, setData] = useState<ICITCEntryResponse[]>([]);

	// ! http client
	const fetchHttpClient = useNewHttpClient<ICITCListResponse>();

	const fetchData = (regionId: string) => {
		fetchHttpClient.request({
			requestConfig: CITC_API.getCitiesList(regionId),
			successCallback: (response) => {
				if (response.errorCodes.length) {
					form.resetFields(['city_id']);
					setData([]);
					message.error(response.errorCodesPlain);
				} else setData(response.data);
			},
		});
	};

	useEffect(() => {
		if (regionIdArg) fetchData(regionId);
	}, [regionId]); // eslint-disable-line

	const selectOptionList = useMemo<ISelectOption[]>(
		() => data?.map(transformCITCEntryResponseToSelectOption),
		[data]
	);

	return { data, fetchCitiesFromRegion, loading: fetchHttpClient.isLoading, selectOptionList };
};
