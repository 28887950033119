import { ECariUnlimitedSubscriptionStatus } from 'types/api';

export const STATUS_TAG_COLORS: Record<ECariUnlimitedSubscriptionStatus, string> = {
	[ECariUnlimitedSubscriptionStatus.ACTIVE]: 'green',
	[ECariUnlimitedSubscriptionStatus.EXPIRED]: 'red',
	[ECariUnlimitedSubscriptionStatus.CANCELLED]: 'red',
	[ECariUnlimitedSubscriptionStatus.FROZEN]: 'gold',
	[ECariUnlimitedSubscriptionStatus.AWAITING_PAYMENT]: 'purple',
	[ECariUnlimitedSubscriptionStatus.PAYMENT_ERROR]: 'red',
};
