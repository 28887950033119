import { lazy } from 'react';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import { GENERAL_SETTINGS_URL_PARAMS } from './config';
import { SettingOutlined } from '@ant-design/icons';

const GeneralSettings = lazy(() => import('pages/GeneralSettings'));

export const GENERAL_SETTINGS_ROUTES: TRouteObject = {
	path: 'general-settings',
	element: (
		<ListParamsRouteWrapper key='general-settings'>
			<GeneralSettings />
		</ListParamsRouteWrapper>
	),
	handle: {
		translationKey: 'general_settings',
		permissions: [APP_PERMISSIONS.general_setting.view],
		defaultListParams: GENERAL_SETTINGS_URL_PARAMS,
		icon: <SettingOutlined />,
		showOnNav: true,
	},
};
