import { IBaseTableData } from 'components/Table';
import dayjs from 'dayjs';

export enum ECariPrizeCampaignStatus {
	COMPLETED = 'completed',
	IN_PROGRESS = 'in_progress',
	PENDING = 'pending',
}

export const STATUS_ACTIONS: Record<ECariPrizeCampaignStatus, string> = {
	[ECariPrizeCampaignStatus.COMPLETED]: 'activate',
	[ECariPrizeCampaignStatus.IN_PROGRESS]: 'deactivate',
	[ECariPrizeCampaignStatus.PENDING]: 'activate',
};

export enum ECariPrizeCampaignFields {
	ID = 'id',
	NAME = 'name',
	TITLE = 'title',
	TITLE_AR = 'title_ar',
	DESCRIPTION = 'description',
	DESCRIPTION_AR = 'description_ar',

	TIME_RANGE = 'time_range',
	START_DATE = 'start_date',
	END_DATE = 'end_date',

	BANNER_URL = 'banner_url',
	BANNER_COLOR = 'banner_colour',
	DEFAULT_POINT_RATIO = 'default_point_ratio',

	STATUS = 'status',

	VENDORS = 'vendors',

	LAUNCH_ON_CREATE = 'launch_on_create',

	// ! banners (CariPrizeCampaignImageKeysEnum)
	STORE_DETAIL_BANNER = 'store_detail_banner',
	STORE_DETAIL_BANNER_AR = 'store_detail_banner_ar',
	CHECKOUT_BANNER = 'checkout_banner',
	CHECKOUT_BANNER_AR = 'checkout_banner_ar',
	PLACED_ORDER_BANNER = 'placed_order_banner',
	PLACED_ORDER_BANNER_AR = 'placed_order_banner_ar',
	DETAILS_PAGE_HEADER = 'details_page_header',
	DETAILS_PAGE_HEADER_AR = 'details_page_header_ar',
	DETAILS_PAGE_BODY = 'details_page_body',
	DETAILS_PAGE_BODY_AR = 'details_page_body_ar',
}

export interface ICariPrizeImages {
	[ECariPrizeCampaignFields.STORE_DETAIL_BANNER]?: string;
	[ECariPrizeCampaignFields.STORE_DETAIL_BANNER_AR]?: string;

	[ECariPrizeCampaignFields.CHECKOUT_BANNER]?: string;
	[ECariPrizeCampaignFields.CHECKOUT_BANNER_AR]?: string;

	[ECariPrizeCampaignFields.PLACED_ORDER_BANNER]?: string;
	[ECariPrizeCampaignFields.PLACED_ORDER_BANNER_AR]?: string;

	[ECariPrizeCampaignFields.DETAILS_PAGE_HEADER]?: string;
	[ECariPrizeCampaignFields.DETAILS_PAGE_HEADER_AR]?: string;
	[ECariPrizeCampaignFields.DETAILS_PAGE_BODY]?: string;
	[ECariPrizeCampaignFields.DETAILS_PAGE_BODY_AR]?: string;
}

export interface ICariPrizeCampaign {
	[ECariPrizeCampaignFields.ID]: React.Key;

	[ECariPrizeCampaignFields.NAME]: string;
	[ECariPrizeCampaignFields.TITLE]: string;
	[ECariPrizeCampaignFields.TITLE_AR]: string;
	[ECariPrizeCampaignFields.DESCRIPTION]: string;
	[ECariPrizeCampaignFields.DESCRIPTION_AR]: string;

	[ECariPrizeCampaignFields.TIME_RANGE]: [dayjs.Dayjs, dayjs.Dayjs];
	[ECariPrizeCampaignFields.START_DATE]: dayjs.Dayjs;
	[ECariPrizeCampaignFields.END_DATE]: dayjs.Dayjs;

	[ECariPrizeCampaignFields.BANNER_URL]: string;
	[ECariPrizeCampaignFields.BANNER_COLOR]: string;

	[ECariPrizeCampaignFields.STATUS]: ECariPrizeCampaignStatus;
	[ECariPrizeCampaignFields.DEFAULT_POINT_RATIO]: number;

	[ECariPrizeCampaignFields.VENDORS]: ICariPrizeCampaignVendorAssociated[];

	// ! banners
	images: ICariPrizeImages;

	[ECariPrizeCampaignFields.PLACED_ORDER_BANNER_AR]?: string;
}

export interface ICariPrizeCampaignTableData extends IBaseTableData, ICariPrizeCampaign {}

export enum ECariPrizeCampaignVendorFields {
	ID = 'id',

	NAME = 'name',
	NAME_AR = 'name_ar',

	POINT_RATIO = 'ratio',
}

export interface ICariPrizeCampaignVendorAssociated extends IBaseTableData {
	[ECariPrizeCampaignVendorFields.ID]: React.Key;
	[ECariPrizeCampaignVendorFields.NAME]: string;
	[ECariPrizeCampaignVendorFields.NAME_AR]: string;
	[ECariPrizeCampaignVendorFields.POINT_RATIO]: number;
}

export interface IAssociateVendors {
	vendors_id: React.Key[];
	points_ratio?: number;
}

export interface IRemoveAssociatedVendors {
	vendors_id: React.Key[];
}

export interface ICariPrizeCampaignCreatePayload {
	[ECariPrizeCampaignFields.NAME]: string;
	[ECariPrizeCampaignFields.TITLE]: string;
	[ECariPrizeCampaignFields.TITLE_AR]: string;
	[ECariPrizeCampaignFields.DESCRIPTION]: string;
	[ECariPrizeCampaignFields.DESCRIPTION_AR]: string;

	[ECariPrizeCampaignFields.TIME_RANGE]: [dayjs.Dayjs, dayjs.Dayjs];
	[ECariPrizeCampaignFields.START_DATE]: dayjs.Dayjs;
	[ECariPrizeCampaignFields.END_DATE]: dayjs.Dayjs;

	[ECariPrizeCampaignFields.BANNER_URL]: string;

	[ECariPrizeCampaignFields.DEFAULT_POINT_RATIO]: number;

	[ECariPrizeCampaignFields.LAUNCH_ON_CREATE]: false;
}

export interface ICariPrizeUpdatePayload extends ICariPrizeCampaignCreatePayload {
	[ECariPrizeCampaignFields.BANNER_COLOR]: string;
}
