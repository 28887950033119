import { lazy } from 'react';
import { TRouteObject } from 'configs/routes';
import { ApartmentOutlined, InfoOutlined, SettingOutlined } from '@ant-design/icons';

export enum ECariPrizeCampaignTabKeys {
	DETAILS = 'details',
	VENDORS = 'vendors',
	SETTINGS = 'settings',
}

// ! lazy imports
const CariPrizeCampaignViewVendors = lazy(() => import('./tabs/CariPrizeCampaignsViewVendors'));
const CariPrizeCampaignViewSettings = lazy(() => import('./tabs/CariPrizeCampaignViewSettings'));
const CariPrizeCampaignViewDetails = lazy(() => import('./tabs/CariPrizeCampaignViewDetails'));

export const CARI_PRIZE_CAMPAIGN_TAB_ROUTES: Record<ECariPrizeCampaignTabKeys, TRouteObject> = {
	[ECariPrizeCampaignTabKeys.DETAILS]: {
		path: ECariPrizeCampaignTabKeys.DETAILS,
		element: <CariPrizeCampaignViewDetails />,
		handle: {
			icon: <InfoOutlined />,
			permissions: [],
		},
	},
	[ECariPrizeCampaignTabKeys.VENDORS]: {
		path: ECariPrizeCampaignTabKeys.VENDORS,
		element: <CariPrizeCampaignViewVendors />,
		handle: {
			icon: <ApartmentOutlined />,
			permissions: [],
		},
	},

	[ECariPrizeCampaignTabKeys.SETTINGS]: {
		path: ECariPrizeCampaignTabKeys.SETTINGS,
		element: <CariPrizeCampaignViewSettings />,
		handle: {
			icon: <SettingOutlined />,
			permissions: [],
		},
	},
};

export const CARI_PRIZE_CAMPAIGN_ROUTES: TRouteObject[] = Object.values(CARI_PRIZE_CAMPAIGN_TAB_ROUTES);
