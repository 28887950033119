import { THttpFunction } from 'utils/axiosInstance';
import { SUPPORT_PAGES_API } from 'configs/api';
import { TFetchPageTypeResponse } from 'types/api';
import { IServiceMethodFunc } from './types';

interface IPagesService {
	fetchPageTypes: IServiceMethodFunc<THttpFunction, TFetchPageTypeResponse>;
}

const pagesService: IPagesService = {
	fetchPageTypes: (http: THttpFunction) => http(SUPPORT_PAGES_API.pageTypesList()),
};

export default pagesService;
