import { FC, PropsWithChildren, lazy } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'store';
import { getSelectedCountry } from 'store/selectors';
import { LIST_DEFAULT_PARAMS } from 'configs/api';
import { SUPER_ADMIN_PERMISSION } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import { ESupportedCountriesIsoTwoCodes } from 'types/common';
import { ECariUnlimitedTabsKeys } from './types';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import { FireOutlined } from '@ant-design/icons';

const ErrorPage = lazy(() => import('pages/ErrorPage'));
const CariUnlimitedManagementPageLayout = lazy(() => import('pages/CariUnlimited'));
// tabs
const DetailsTabContent = lazy(() => import('pages/CariUnlimited/Tabs/Details'));
const PlanListTabContent = lazy(() => import('pages/CariUnlimited/Tabs/Plans/PlanListTabContent'));
const PlanDetailsPage = lazy(() => import('pages/CariUnlimited/Tabs/Plans/PlanDetails'));
const AddPlanPage = lazy(() => import('pages/CariUnlimited/Tabs/Plans/AddPlanPage'));
const VendorsTabContent = lazy(() => import('./Tabs/Vendors/VendorsTabContent'));
const SupportPagesTabContent = lazy(() => import('./Tabs/SupportPages'));

const TemporaryCountryValidationWrapper: FC<PropsWithChildren> = ({ children = <Outlet /> }) => {
	const { t: tCariUnlimited } = useTranslation('cari-unlimited');

	const selectedCountry = useSelector(getSelectedCountry);
	const selectedCountryIsoCode = selectedCountry?.iso_two_code.toLowerCase();

	const countriesWhiteList = [ESupportedCountriesIsoTwoCodes.KUWAIT, ESupportedCountriesIsoTwoCodes.UAE];
	const canShowCariUnlimitedInfo = !!(
		selectedCountryIsoCode && countriesWhiteList.includes(selectedCountryIsoCode as ESupportedCountriesIsoTwoCodes)
	);

	if (!canShowCariUnlimitedInfo) {
		return (
			<ErrorPage
				status='403'
				title={tCariUnlimited('error.page_not_available_title')}
			></ErrorPage>
		);
	}

	return <>{children}</>;
};

export const CARI_UNLIMITED_ROUTES: TRouteObject = {
	path: 'cari-unlimited',
	element: (
		<TemporaryCountryValidationWrapper>
			<CariUnlimitedManagementPageLayout />
		</TemporaryCountryValidationWrapper>
	),
	handle: {
		icon: <FireOutlined />,
		translationKey: 'cari_unlimited',
		permissions: [SUPER_ADMIN_PERMISSION], // APP_PERMISSIONS.cari_unlimited.manage
		showOnNav: true,
	},
	children: [
		{
			index: true,
			element: (
				<Navigate
					to={ECariUnlimitedTabsKeys.DETAILS}
					replace
				/>
			),
		},
		{
			path: ECariUnlimitedTabsKeys.DETAILS,
			element: <DetailsTabContent />,
			handle: {
				permissions: [],
				showOnNav: false,
			},
		},
		{
			path: ECariUnlimitedTabsKeys.PLANS,
			element: <ListParamsRouteWrapper key='cari-unlimited-plans' />,
			handle: {
				permissions: [],
				showOnNav: false,
			},
			children: [
				// list page (index)
				{
					index: true,
					element: <PlanListTabContent />,
					handle: {
						defaultListParams: LIST_DEFAULT_PARAMS,
					},
				},
				// plan page
				{
					path: ':planId',
					children: [
						// details
						{
							index: true,
							element: <PlanDetailsPage />,
						},
					],
				},
				// new plan page
				{
					path: 'new',
					element: <AddPlanPage />,
				},
			],
		},
		{
			path: ECariUnlimitedTabsKeys.VENDORS,
			element: <VendorsTabContent />,
			handle: {
				permissions: [],
				showOnNav: false,
			},
		},
		{
			path: ECariUnlimitedTabsKeys.SUPPORT_PAGES,
			element: <SupportPagesTabContent />,
			handle: {
				permissions: [],
				showOnNav: false,
			},
		},
	],
};
