import { lazy } from 'react';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';

const OffersLabelsList = lazy(() => import('./OffersLabelsList'));

export const OFFERS_LABELS_ROUTES: TRouteObject = {
	path: 'offers-labels',
	element: <ListParamsRouteWrapper key='offers-labels' />,
	handle: {
		translationKey: 'offers_labels',
		permissions: [APP_PERMISSIONS.offer_label.view],
		showOnNav: true,
	},
	children: [
		{
			index: true,
			element: <OffersLabelsList />,
		},
	],
};
