import { Nullable } from 'types/common';
import { FormInstance } from 'antd';

export const useGetFormValues = () => {
	const getFormValues = async <ReturnType>(form: FormInstance<ReturnType>): Promise<Nullable<ReturnType>> =>
		form.validateFields().catch((error) => {
			console.warn('Form Validate Failed:', error);
			return null;
		});
	return { getFormValues };
};
