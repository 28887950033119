import { FC, memo } from 'react';
import { useSelector } from 'store';
import { getIsRTL } from 'store/selectors';
import { Divider } from 'antd';
import { DividerProps } from 'antd/lib/divider';

const CustomDivider: FC<DividerProps> = ({ orientation, ...restProps }) => {
	// ! selectors
	const isRTL = useSelector(getIsRTL);

	// ! computed props
	const orientationProp = orientation || (isRTL ? 'right' : 'left');

	// ! render
	return (
		<Divider
			{...restProps}
			orientation={orientationProp}
		/>
	);
};

export default memo(CustomDivider);
