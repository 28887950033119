import { ReactNode } from 'react';

export enum EAreasFilterMode {
	ALL = 'all',
	SPECIFIC = 'specific',
}

export interface IAreasSelectionTableData {
	key: React.Key;
	id: number;
	name: ReactNode;
	name_ar: ReactNode;
}

export interface ISelectAresName extends Omit<IAreasSelectionTableData, 'id'> {}
