import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ITimingsDayWithConfirmationProps } from './types';
import TimingsRange from '../TimingsRange';
import styles from './TimingsDayWithConfirmation.module.css';
import { Button, Col } from 'antd';

const TimingsDayWithConfirmation: FC<ITimingsDayWithConfirmationProps> = ({
	timingsItem,
	isEdit = true,
	onTimeChange,
	onAddScheduleItem,
	onRemoveScheduleItem,
	showDayTitle = false,
}) => {
	const { day, schedule } = timingsItem;

	const { t: tCommon } = useTranslation();
	const { t: tVendorTimings } = useTranslation('vendors', { keyPrefix: 'vendor_details.timings' });

	// ! render
	return (
		<Col>
			<div className={styles.day_wrap}>
				{/* TITLE */}
				{showDayTitle && (
					<div className={styles.title_wrap}>
						<span className={styles.title}>{tCommon(`weekdays.${day}`)}</span>
					</div>
				)}
				{/* SCHEDULE ITEMS */}
				<div className={styles.controls_wrap}>
					{schedule.map((scheduleItem, index) => (
						<TimingsRange
							key={`${day}-${index}`}
							scheduleItem={scheduleItem}
							onRemove={() => onRemoveScheduleItem(index)}
							onTimeChange={(timeType, timeValue) => onTimeChange(index, timeType, timeValue)}
							isEdit={isEdit}
						/>
					))}

					{schedule?.length === 0 && !isEdit && <div>{tVendorTimings('closed')}</div>}

					{/* ADD BUTTON */}
					{isEdit && (
						<Button
							size='small'
							className={styles.add_button}
							disabled={schedule.length >= 3}
							title={tVendorTimings(schedule.length >= 3 ? 'actions.add.warning' : 'actions.add.hours')}
							onClick={() => onAddScheduleItem()}
						>
							{`+ ${tVendorTimings('actions.add.hours')}`}
						</Button>
					)}
				</div>
			</div>
		</Col>
	);
};

export default TimingsDayWithConfirmation;
