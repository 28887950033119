import { THttpRequestConfig, THttpRequestParams } from 'hooks';
import { ICustomerAddressPayloadData, IPostNewDebitCreditData, TTransactionModalType } from 'types/api';
import { ICustomersTableData } from 'pages/Customers/types';

export const CUSTOMER_API = {
	list: (search?: string, byIdparams?: THttpRequestParams): THttpRequestConfig => ({
		url: 'operations/customers',
		method: 'GET',
		params: { search, ...byIdparams },
	}),

	// ! CRUD
	delete: (id: number): THttpRequestConfig => ({
		url: `operations/customers/${id}`,
		method: 'DELETE',
	}),

	update: (id: number, data: Partial<ICustomersTableData>): THttpRequestConfig => ({
		url: `operations/customers/${id}`,
		method: 'PATCH',
		data,
	}),

	get: (id: number): THttpRequestConfig => ({
		url: `operations/customers/${id}`,
		method: 'GET',
	}),

	// ! Customer Restore
	getRestoreCustomer: (id: number): THttpRequestConfig => ({
		url: `operations/customers/${id}/restore`,
		method: 'POST',
	}),

	// ! Customers Addresses
	getCustomersAddresses: (id: number, params?: THttpRequestParams): THttpRequestConfig => ({
		url: `operations/customers/${id}/addresses`,
		method: 'GET',
		params: { ...params },
	}),

	updateCustomersAddresses: (
		id: number,
		addressId: number,
		data: ICustomerAddressPayloadData
	): THttpRequestConfig => ({
		url: `operations/customers/${id}/addresses/${addressId}`,
		method: 'PUT',
		data,
	}),

	deleteCustomersAddresses: (id: number, addressId: number): THttpRequestConfig => ({
		url: `operations/customers/${id}/addresses/${addressId}`,
		method: 'DELETE',
	}),

	// ! Customer Wallet
	getCustomersWalletTransactions: (id: number, currency: string): THttpRequestConfig => ({
		url: `operations/customers/${id}/wallet/${currency}/transactions`,
		method: 'GET',
	}),

	getCustomersWalletBalance: (id: number, currency: string): THttpRequestConfig => ({
		url: `operations/customers/${id}/wallet/${currency}`,
		method: 'GET',
	}),

	postCustomersWalletCreditDebit: (
		id: number,
		operationType: TTransactionModalType,
		data: IPostNewDebitCreditData
	): THttpRequestConfig => ({
		url: `operations/customers/${id}/wallet/${operationType}`,
		method: 'POST',
		data,
	}),

	// ! Customer Orders
	getCustomersOrderList: (id: number, params?: THttpRequestParams): THttpRequestConfig => ({
		url: `operations/customers/${id}/orders`,
		method: 'GET',
		params,
	}),

	getSubscriptionLog: (customer_id: number): THttpRequestConfig => ({
		url: `operations/cari-unlimited/subscription-log`,
		method: 'GET',
		params: { customer_id },
	}),
};
