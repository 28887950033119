import { THttpRequestConfig } from 'hooks';
import { ICreateNewReqDocumentData } from 'types/api';

export const REQUIRED_DOCUMENTS_API = {
	list: (): THttpRequestConfig => ({
		url: 'operations/required-documents',
		method: 'GET',
	}),

	create: (data: ICreateNewReqDocumentData): THttpRequestConfig => ({
		url: 'operations/required-documents',
		method: 'POST',
		data,
	}),

	update: (id: number, data: Partial<ICreateNewReqDocumentData>): THttpRequestConfig => ({
		url: `operations/required-documents/${id}`,
		method: 'PATCH',
		data,
	}),

	delete: (id: number): THttpRequestConfig => ({
		url: `operations/required-documents/${id}`,
		method: 'DELETE',
	}),
};
