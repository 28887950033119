import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth, useNewHttpClient, useVendor } from 'hooks';
import { VENDOR_PRESENCE_API } from 'configs/api';
import { DEFAULT_DESCRIPTIONS_PROPS } from 'configs/common';
import { APP_PERMISSIONS } from 'configs/permissions';
import { IPatchPresenceRequestData, IWebPresence } from 'types/api';
import { EChoiceOption } from 'types/common';
import { EditableField } from 'pages/Vendors/VendorDetails/Tabs/Presence/EditableField';
import { Alert, App, Card, Descriptions, Switch } from 'antd';

const VendorPresenceCard: FC = () => {
	const { t: tPresence } = useTranslation('vendors', { keyPrefix: 'presence' });
	const { t: tCommon } = useTranslation('common');

	const { message } = App.useApp();
	const { hasPermission } = useAuth();

	const { vendorId } = useVendor();

	// ! http client
	const fetchVendorHttpClient = useNewHttpClient<{ id: number } & IWebPresence>();
	const patchPresenceHttpClient = useNewHttpClient();

	// ! memos
	const canUpdate = useMemo(
		() => !!fetchVendorHttpClient.response && hasPermission(APP_PERMISSIONS.vendor.presence.update),
		[fetchVendorHttpClient.response, hasPermission]
	);

	// ! handlers
	const fetchWebPresence = () => {
		fetchVendorHttpClient.request({ requestConfig: VENDOR_PRESENCE_API.getVendorPresence(vendorId) });
	};

	const onPatchVendorPresence = (updateData: IPatchPresenceRequestData) => {
		return patchPresenceHttpClient.request({
			requestConfig: VENDOR_PRESENCE_API.updateVendorPresence(vendorId, updateData),
			successCallback: () => {
				fetchWebPresence();

				message.success(tPresence('presence_updated'), 3);
			},
		});
	};

	// ! effects
	useEffect(() => {
		fetchWebPresence();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vendorId]);

	// ! render
	const {
		subdomain,
		available_on_web = EChoiceOption.NO,
		available_on_mobile = EChoiceOption.NO,
	} = fetchVendorHttpClient.response ?? {};

	const isLoading = fetchVendorHttpClient.isLoading || patchPresenceHttpClient.isLoading;

	return (
		<Card>
			<Descriptions
				title={tPresence('titles.vendor')}
				{...DEFAULT_DESCRIPTIONS_PROPS}
			>
				<Descriptions.Item label={tPresence('subdomain')}>
					<EditableField
						value={subdomain}
						onSuccessSubmit={(value) => onPatchVendorPresence({ subdomain: value })}
						isLoading={isLoading}
						canOpenEdit={canUpdate && !isLoading}
					/>
				</Descriptions.Item>
				<Descriptions.Item label={tPresence('available_on_web')}>
					<Switch
						size='small'
						checkedChildren={tCommon('on_off_switch.yes')}
						unCheckedChildren={tCommon('on_off_switch.no')}
						checked={available_on_web === EChoiceOption.YES}
						onChange={(checked) =>
							onPatchVendorPresence({
								available_on_web: checked ? EChoiceOption.YES : EChoiceOption.NO,
							})
						}
						loading={isLoading}
						disabled={!canUpdate}
					/>
				</Descriptions.Item>
				<Descriptions.Item label={tPresence('available_on_mobile')}>
					<Switch
						size='small'
						checkedChildren={tCommon('on_off_switch.yes')}
						unCheckedChildren={tCommon('on_off_switch.no')}
						checked={available_on_mobile === EChoiceOption.YES}
						onChange={(checked) =>
							onPatchVendorPresence({
								available_on_mobile: checked ? EChoiceOption.YES : EChoiceOption.NO,
							})
						}
						loading={isLoading}
						disabled={!canUpdate}
					/>
				</Descriptions.Item>
			</Descriptions>

			<Alert
				type='info'
				showIcon
				message={tPresence('vendor_presence_info')}
			/>
		</Card>
	);
};

export default VendorPresenceCard;
