import { ICITCEntryResponse } from 'types/api';
import { ISelectOption } from 'types/common';

export const transformCITCEntryResponseToSelectOption = ({
	id,
	nameAr,
	nameEn,
}: ICITCEntryResponse): ISelectOption => ({
	value: id,
	label: `${nameEn} | ${nameAr}`,
});
