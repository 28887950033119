import { Circle, Marker, Polygon } from '@react-google-maps/api';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	useAuth,
	useBranch,
	useConfirmModal,
	useForceRefreshData,
	useGoogleMapsApiKey,
	useNewHttpClient,
	useVendor,
} from 'hooks';
import { convertGeoJsonDataToReadableLatLngArray } from './helper';
import { VENDOR_BRANCH_OPERATIONS_AREA_API } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import {
	CIRCLE_RADIUS_CONFIGS,
	DEFAULT_POLYGON_OPTIONS,
	OPERATIONS_MAP_CONFIG,
	OPERATION_AREA_TYPES_OPTIONS_LIST,
	POLYGON_MAX_EDGE_POINTS,
	POLYGON_MIN_EDGE_POINTS,
	SERVICE_RADIUS_OPTIONS_LIST,
} from './configs';
import {
	EBranchOperationAreaType,
	IBranchOperationAreaCoordinates,
	IUpdateBranchOperationAreaPolygonPayload,
	IUpdateBranchOperationAreaRadiusPayload,
	TOperationsAreaResponse,
} from 'types/api';
import { Nullable } from 'types/common';
import CustomDivider from 'components/CustomDivider';
import PolygonMap from 'components/PolygonMap';
import Spinner from 'components/Spinner';
import styles from './OperationsAreaTabContent.module.css';
import UploadKMLFile from './UploadKMLFile';
import { App, Button, Select, Space } from 'antd';
import { DeleteOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import locationPinLogo from 'assets/images/location-pin.svg';

const OperationsAreaTabContent: FC = () => {
	const { message } = App.useApp();
	const { hasPermission } = useAuth();
	const { openConfirmModal } = useConfirmModal();
	const { mapApiKey } = useGoogleMapsApiKey();
	const { vendorId } = useVendor();
	const { branchId, data: branchData } = useBranch();

	const { forceRefreshData, refreshingData } = useForceRefreshData();

	const { t: tCommon } = useTranslation('common');
	const { t: tVendorBranchOperationArea } = useTranslation('vendors', {
		keyPrefix: 'vendor_details.tabs.branches.branch_details.tabs.operation_area',
	});

	// ! httpClients
	const getHttpClient = useNewHttpClient<TOperationsAreaResponse>();
	const updateHttpClient = useNewHttpClient();

	// ! state
	const [serviceRadius, setServiceRadius] = useState<number>(0);
	const [branchLocation, setBranchLocation] = useState<google.maps.LatLngLiteral>();
	const [selectedOperationType, setSelectedOperationType] = useState<EBranchOperationAreaType>();

	const polygonRef = useRef<Nullable<google.maps.Polygon>>(null);
	const [polygonPath, setPolygonPath] = useState<IBranchOperationAreaCoordinates[]>([]);
	const [isDefiningPolygonArea, setIsDefiningPolygonArea] = useState(false);

	const localLoading = getHttpClient.isLoading || updateHttpClient.isLoading;

	// ! memos
	const canSaveChangesOnOperationArea = useMemo(
		() => hasPermission(APP_PERMISSIONS.vendor.store.operation_area.save),
		[hasPermission]
	);

	const submitDisabled = useMemo(() => {
		if (!canSaveChangesOnOperationArea || localLoading) return true;

		if (selectedOperationType === EBranchOperationAreaType.POLYGON) {
			return polygonPath.length > POLYGON_MAX_EDGE_POINTS || polygonPath.length < POLYGON_MIN_EDGE_POINTS;
		}

		if (selectedOperationType === EBranchOperationAreaType.RADIUS) {
			return !serviceRadius;
		}

		return false;
	}, [localLoading, polygonPath, canSaveChangesOnOperationArea, selectedOperationType, serviceRadius]);

	// ! helpers
	const fetchPolygonAreaData = () => {
		getHttpClient.request({
			requestConfig: VENDOR_BRANCH_OPERATIONS_AREA_API.get(vendorId, branchId),
			successCallback: (data) => {
				if (data.type === EBranchOperationAreaType.RADIUS) {
					setServiceRadius(data.service_radius);
				}

				if (data.type === EBranchOperationAreaType.POLYGON) {
					const validPaths = data ? convertGeoJsonDataToReadableLatLngArray(data.coordinates[0]) : [];

					setPolygonPath(validPaths);
				}

				setSelectedOperationType(data.type);
			},
			displayErrorMsg: false,
		});
	};

	useEffect(() => {
		if (!branchData) return;

		const { location } = branchData;
		setSelectedOperationType(location.operation_area_type ?? null);
		setServiceRadius(location.service_radius);
		setBranchLocation({ lat: +location.lat, lng: +location.lng });
	}, [branchData]);

	// ! effects
	useEffect(() => {
		fetchPolygonAreaData();
	}, [forceRefreshData]); // eslint-disable-line react-hooks/exhaustive-deps

	// ! handlers
	const refreshingPage = () => {
		setIsDefiningPolygonArea(false);
		refreshingData();
	};

	const saveRadius = () => {
		const data: IUpdateBranchOperationAreaRadiusPayload = {
			type: EBranchOperationAreaType.RADIUS,
			service_radius: serviceRadius,
		};

		updateHttpClient.request({
			requestConfig: VENDOR_BRANCH_OPERATIONS_AREA_API.update(vendorId, branchId, data),
			successCallback: () => {
				message.success(tVendorBranchOperationArea('messages.update.success'), 3);
				refreshingPage();
			},
		});
	};

	const savePolygon = () => {
		const data: IUpdateBranchOperationAreaPolygonPayload = {
			type: EBranchOperationAreaType.POLYGON,
			operation_area: polygonPath,
		};

		updateHttpClient.request({
			requestConfig: VENDOR_BRANCH_OPERATIONS_AREA_API.update(vendorId, branchId, data),
			successCallback: () => {
				message.success(tVendorBranchOperationArea('messages.update.success'), 3);
				refreshingPage();
			},
		});
	};

	const onSave = () => {
		if (selectedOperationType === EBranchOperationAreaType.RADIUS) {
			saveRadius();
		}

		if (selectedOperationType === EBranchOperationAreaType.POLYGON) {
			savePolygon();
		}
	};

	// * Map CRUD
	const onMapClick = (event: google.maps.MapMouseEvent) => {
		if (!isDefiningPolygonArea || polygonPath.length >= POLYGON_MAX_EDGE_POINTS) return;

		const newPolygonPosition = event.latLng?.toJSON();
		if (!newPolygonPosition) return;
		setPolygonPath((prev) => [...prev, newPolygonPosition]);
	};

	const onCreateOrEditPolygonArea = () => setIsDefiningPolygonArea(true);

	const onClearAllPoints = () => {
		openConfirmModal({
			title: tVendorBranchOperationArea('messages.clear_all_polygon_points_confirmation'),
			onOk: () => setPolygonPath([]),
		});
	};

	const onPolygonEdit = useCallback(
		(event: google.maps.PolyMouseEvent) => {
			if (!polygonRef.current || !isDefiningPolygonArea) return;

			const path = polygonRef.current.getPath();
			if (path.getLength() > POLYGON_MAX_EDGE_POINTS) {
				if (typeof event?.edge === 'number' && event.edge >= 0) path.removeAt(event.edge);
			}

			const polygonPathArray = path.getArray();
			if (!polygonPathArray?.length) return;

			const newPath = polygonPathArray.map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));
			setPolygonPath(newPath);
		},
		[isDefiningPolygonArea]
	);

	const onPolygonLoad = useCallback((polyRef: google.maps.Polygon) => (polygonRef.current = polyRef), []);

	const onPolygonUnmount = useCallback(() => (polygonRef.current = null), []);

	const polygonCreateBtnTitle = useMemo(
		() => tVendorBranchOperationArea(isDefiningPolygonArea ? 'actions.creating' : 'actions.create'),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isDefiningPolygonArea]
	);

	const polygonEditBtnTitle = useMemo(
		() => tVendorBranchOperationArea(isDefiningPolygonArea ? 'actions.editing' : 'actions.edit'),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isDefiningPolygonArea]
	);

	// ! render
	return (
		<>
			{localLoading && <Spinner defaultAntdSpinner />}

			{/* OPERATION TYPE SELECT */}
			<div className='flex'>
				<Select
					disabled={localLoading}
					value={selectedOperationType}
					className={styles.operation_type_select}
					placeholder={tVendorBranchOperationArea('operation_area_type.placeholder')}
					onChange={(type) => setSelectedOperationType(type)}
					options={OPERATION_AREA_TYPES_OPTIONS_LIST}
				/>

				<Button
					type='primary'
					onClick={onSave}
					disabled={submitDisabled}
					className={styles.save_btn}
				>
					{tCommon('action_buttons.save')}
				</Button>
			</div>

			<CustomDivider />

			{/* OPERATION TYPE HEADER */}
			<div className={styles.operation_type_header}>
				{/* SERVICE POLYGON INPUT */}
				{selectedOperationType === EBranchOperationAreaType.POLYGON && (
					<>
						{isDefiningPolygonArea && (
							<Space className={styles.polygon_info_msg}>
								<InfoCircleOutlined />
								<span>
									{tVendorBranchOperationArea('polygon.hint', {
										min: POLYGON_MIN_EDGE_POINTS,
										max: POLYGON_MAX_EDGE_POINTS,
									})}
								</span>
							</Space>
						)}
						<Space>
							<UploadKMLFile setPolygonPath={setPolygonPath} />

							<Button
								size='small'
								icon={<EditOutlined />}
								disabled={isDefiningPolygonArea || !mapApiKey?.length}
								onClick={onCreateOrEditPolygonArea}
							>
								{polygonPath?.length ? polygonEditBtnTitle : polygonCreateBtnTitle}
							</Button>
							<Button
								size='small'
								icon={<DeleteOutlined />}
								disabled={!isDefiningPolygonArea || !polygonPath.length}
								onClick={onClearAllPoints}
							>
								{tVendorBranchOperationArea('actions.clear.all')}
							</Button>
						</Space>
					</>
				)}

				{/* SERVICE RADIUS INPUT */}
				{selectedOperationType === EBranchOperationAreaType.RADIUS && (
					<Space>
						<span>{tVendorBranchOperationArea('service_radius.label')}</span>
						<Select
							value={serviceRadius}
							disabled={localLoading}
							placeholder={tVendorBranchOperationArea('service_radius.placeholder')}
							className={styles.service_radius_select}
							onChange={(radius) => setServiceRadius(radius)}
							options={SERVICE_RADIUS_OPTIONS_LIST}
						/>
					</Space>
				)}
			</div>

			{/* MAP */}
			{!localLoading && mapApiKey && (
				<>
					<PolygonMap
						mapContainerStyle={OPERATIONS_MAP_CONFIG}
						googleMapsApiKey={mapApiKey}
						mapCenter={branchLocation}
						onMapClick={onMapClick}
						centerRadius={
							selectedOperationType === EBranchOperationAreaType.RADIUS ? serviceRadius : undefined
						}
					>
						{branchLocation && (
							<Marker
								animation={4}
								title={tVendorBranchOperationArea('branch_location')}
								icon={locationPinLogo}
								position={branchLocation}
							/>
						)}

						{/* POLYGON AREA */}
						{selectedOperationType === EBranchOperationAreaType.POLYGON && (
							<Polygon
								path={polygonPath}
								options={{
									...DEFAULT_POLYGON_OPTIONS,
									fillColor: polygonPath.length > POLYGON_MAX_EDGE_POINTS ? 'red' : 'green',
									strokeColor: polygonPath.length > POLYGON_MAX_EDGE_POINTS ? 'darkred' : 'darkgreen',
								}}
								editable={isDefiningPolygonArea}
								draggable={isDefiningPolygonArea}
								onLoad={onPolygonLoad}
								onMouseUp={onPolygonEdit}
								onDragEnd={onPolygonEdit}
								onUnmount={onPolygonUnmount}
							/>
						)}

						{/* RADIUS CIRCLE */}
						{selectedOperationType === EBranchOperationAreaType.RADIUS && (
							<Circle
								center={branchLocation}
								options={{
									...CIRCLE_RADIUS_CONFIGS,
									radius: (serviceRadius ?? 0) * 1000,
								}}
							/>
						)}
					</PolygonMap>
				</>
			)}
		</>
	);
};

export default OperationsAreaTabContent;
