import { useMemo } from 'react';
import { useMatches } from 'react-router-dom';
import { convertIRouteToMenuItemType } from './helper';
import { IRoute, IRouteObjectHandle } from 'configs/routes';

export const useNavMenuController = (items: IRoute[]) => {
	const matches = useMatches();

	const navItems = items.map(convertIRouteToMenuItemType);

	const selectedNavItems = useMemo(
		() =>
			matches
				.map((route) => (route.handle as IRouteObjectHandle['handle'])?.translationKey ?? '')
				.filter((elem) => elem),
		[matches]
	);

	return { selectedNavItems, navItems };
};
