import { lazy } from 'react';
import { LIST_DEFAULT_PARAMS } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import { CreditCardOutlined } from '@ant-design/icons';

const BinCardSegmentList = lazy(() => import('pages/BinCardSegments/BinCardSegmentList'));

export const BIN_CARD_SEGMENTS_ROUTES: TRouteObject = {
	path: 'bin-card-segments',
	element: (
		<ListParamsRouteWrapper key='bin-card-segments'>
			<BinCardSegmentList />
		</ListParamsRouteWrapper>
	),
	handle: {
		translationKey: 'bin_card_segments',
		permissions: [APP_PERMISSIONS.bin_segment.manage],
		icon: <CreditCardOutlined />,
		defaultListParams: LIST_DEFAULT_PARAMS,
		showOnNav: true,
	},
};
