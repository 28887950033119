import { ESupportedLanguages } from '../common';

export enum ESupportPagesPurpose {
	GENERAL = 'general',
	CARI_UNLIMITED = 'cari_unlimited',
}

export type TPageType = { id: number; slug: string };

export type TPageTypeOptionItem = { value: TPageType['id']; label: TPageType['slug'] };

export type TFetchPageTypeResponse = {
	count: number;
	data: TPageType[];
};

export enum ESupportPagesStatus {
	DRAFT = 'draft',
	PUBLISHED = 'published',
}

export interface ISupportPages {
	id: number;
	title: string;
	link: string;
	content: string;
	country_id: number; // drop
	language: ESupportedLanguages;
	status: ESupportPagesStatus;
	type: TPageType;
}

// ! create new page
export interface ICreateNewSupportPageData {
	title: string;
	type_id: number;
	content: string;
	purpose?: ESupportPagesPurpose;
	status: ESupportPagesStatus;
	language: ESupportedLanguages;
}

// ! update page
export interface IUpdateNewSupportPageConfig {
	pageId: string | number;
	data: ICreateNewSupportPageData;
}
