import { THttpRequestConfig } from 'hooks';
import { IPostBulkUpdateDeliveryFeePayload, IPostBulkUpdateETAPayload } from 'types/api';
import { IVendorRankingAddVendorBranches, TVendorRankingUpdatePatch } from 'pages/Vendors/VendorRankings/types';

export const LOGISTICS_VENDOR_RANKING_API = {
	get: (): THttpRequestConfig => ({
		url: 'operations/logistics/ranking',
		method: 'GET',
	}),
	reset: (): THttpRequestConfig => ({
		url: 'operations/logistics/ranking',
		method: 'DELETE',
	}),
	add: (data: IVendorRankingAddVendorBranches): THttpRequestConfig => ({
		url: 'operations/logistics/ranking/add',
		method: 'POST',
		data,
	}),
	bulkUpdate: (data: TVendorRankingUpdatePatch): THttpRequestConfig => ({
		url: 'operations/logistics/bulk-update-ranking',
		method: 'PATCH',
		data,
	}),

	postBulkUpdateETA: (data: IPostBulkUpdateETAPayload): THttpRequestConfig => ({
		url: 'operations/logistics/bulk-update-eta',
		method: 'POST',
		data,
	}),

	postBulkUpdateDeliveryFee: (data: IPostBulkUpdateDeliveryFeePayload): THttpRequestConfig => ({
		url: 'operations/logistics/bulk-update-delivery-fee',
		method: 'POST',
		data,
	}),
};
