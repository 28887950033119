import { FC, memo } from 'react';
import { useAuth } from 'hooks';
import { ILinkBtnProps } from './types';
import LinkWrapper from './Wrappers/LinkWrapper';
import VanillaWrapper from './Wrappers/VanillaWrapper';
import { Tooltip } from 'antd';

const LinkButton: FC<ILinkBtnProps> = memo((props) => {
	const { label, icon, tooltip, permissions = [] } = props;
	const { hasPermission } = useAuth();

	const canLink = hasPermission(permissions);

	const Wrapper = canLink ? LinkWrapper : VanillaWrapper;

	// ! render
	return (
		<Tooltip title={tooltip}>
			<Wrapper {...props}>
				{icon} {label}
			</Wrapper>
		</Tooltip>
	);
});

export default LinkButton;
