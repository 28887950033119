import { FC, useEffect, useState } from 'react';
import { DEFAULT_EMPTY_VALUE_PLACEHOLDER } from 'configs/common';
import { IEditableFieldForm, IEditableFieldProps } from './types';
import { CopyToClipBoard } from 'components/CopyToClipBoard';
import styles from './EditableField.module.css';
import { Button, Flex, Form, Input, Space } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { CheckCircleOutlined, CloseCircleOutlined, EditOutlined } from '@ant-design/icons';

export const EditableField: FC<IEditableFieldProps> = ({
	value,
	onSuccessSubmit,
	onEdit = () => {},
	isLoading,
	canOpenEdit,
}) => {
	const [isEditing, setIsEditing] = useState(false);

	const size = 'small';

	// ! handlers
	const onToggleEdit = () => {
		setIsEditing((prev) => !prev);
	};

	const onSubmit = ({ value }: IEditableFieldForm) => {
		onSuccessSubmit(value).finally(() => {
			setIsEditing(false);
		});
	};

	// ! useEffects
	useEffect(() => {
		onEdit(isEditing);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEditing]);

	// ! render
	return (
		<>
			{!isEditing && (
				<Flex
					justify='space-between'
					align='baseline'
					gap={16}
				>
					<Input
						size={size}
						value={value || DEFAULT_EMPTY_VALUE_PLACEHOLDER}
						bordered={false}
						prefix={
							value ? (
								<CopyToClipBoard
									contentToCopy={value}
									reverted={false}
								/>
							) : (
								<span /> // ? Reason: https://ant.design/components/input#why-input-lose-focus-when-change-prefixsuffixshowcount
							)
						}
						tabIndex={-1}
						className={styles.editable_field_input}
						readOnly={!isEditing}
					/>

					<Button
						size={size}
						icon={<EditOutlined />}
						disabled={!canOpenEdit}
						loading={isLoading}
						onClick={onToggleEdit}
						className='flex-shrink-zero'
					/>
				</Flex>
			)}

			{isEditing && (
				<Form<IEditableFieldForm> onFinish={onSubmit}>
					<Flex
						className='w-100'
						justify='space-between'
						gap={8}
					>
						<FormItem
							noStyle
							name='value'
							initialValue={value}
						>
							<Input
								size={size}
								disabled={isLoading}
							/>
						</FormItem>
						<Space>
							<Button
								size={size}
								icon={<CloseCircleOutlined />}
								disabled={isLoading}
								onClick={onToggleEdit}
							/>
							<Button
								size={size}
								htmlType='submit'
								icon={<CheckCircleOutlined />}
								loading={isLoading}
								disabled={isLoading}
							/>
						</Space>
					</Flex>
				</Form>
			)}
		</>
	);
};
