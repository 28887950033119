import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { errorCase, pendingCase } from 'store/helpers';
import { THttpFunction } from 'utils/axiosInstance';
import { TPageType, TPageTypeOptionItem } from 'types/api';
import { Nullable } from 'types/common';
import { IDefaultStateFields } from '../types';
import pagesService from 'services/pages';

// ! initial state
interface IPagesSlice extends IDefaultStateFields {
	pageTypesList: Nullable<TPageType[]>;
	pageTypesOptions: Nullable<TPageTypeOptionItem[]>;
}

const pagesSliceInitialState: IPagesSlice = {
	pageTypesList: null,
	pageTypesOptions: null,
	loading: false,
};

// ! thunks
export const fetchPageTypes = createAsyncThunk(
	'pagesSlice/fetchPageTypes',
	async ({ http }: { http: THttpFunction }, { rejectWithValue }) => {
		try {
			const response = await pagesService.fetchPageTypes(http);
			return response.data;
		} catch (error) {
			console.error(error);
			return rejectWithValue(error);
		}
	}
);

const pagesSlice = createSlice({
	name: 'pagesSlice',
	initialState: pagesSliceInitialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchPageTypes.pending, pendingCase)
			.addCase(fetchPageTypes.rejected, errorCase)
			.addCase(fetchPageTypes.fulfilled, (state, { payload }) => {
				const pageTypesList = payload?.data;
				if (!pageTypesList?.length) {
					state.loading = false;
					return;
				}
				state.pageTypesList = pageTypesList;
				state.pageTypesOptions = pageTypesList.map<TPageTypeOptionItem>(({ id: value, slug: label }) => ({
					value,
					label,
				}));
				state.loading = false;
			});
	},
});

export default pagesSlice.reducer;
