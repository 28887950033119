import { AxiosResponse } from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { errorCase, pendingCase } from 'store/helpers';
import { THttpFunction } from 'utils/axiosInstance';
import { THandleError } from 'utils/handleError';
import { VENDOR_API } from 'configs/api';
import { EVendorStatus, IVendor } from 'types/api';
import { Nullable } from 'types/common';
import { EChoiceOption } from 'types/common';
import { IActionArg, IDefaultStateFields } from '../types';

// ! initial state
export interface IVendorSlice extends IDefaultStateFields {
	data: Nullable<IVendor>;
	error: Nullable<any>;
}

const vendorSliceInitialState: IVendorSlice = {
	data: null,
	// own state
	error: null,
	loading: false,
};

export const fetchVendorInformation = createAsyncThunk<
	// Response
	IVendor,
	// Args
	{
		id: number;
		http: THttpFunction;
		handleError: THandleError;
	},
	// thunk configs
	{ state: RootState }
>(
	'vendorSlice/fetchVendor',
	async ({ id, http, handleError }, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<IVendor> = await http(VENDOR_API.get(id));

			return response.data;
		} catch (error) {
			handleError(error, true);
			return rejectWithValue(error);
		}
	},
	{
		condition: (args, { getState }) => {
			const root = getState();

			if (root.vendor.loading) {
				// Already fetched or in progress, don't need to re-fetch
				return false;
			}
		},
	}
);

// ! slice
const vendorSlice = createSlice({
	name: 'vendorSlice',
	initialState: vendorSliceInitialState,
	reducers: {
		setData: (state, action: IActionArg<Nullable<IVendor>>) => {
			state.data = action.payload;
		},
		setStatus: (state, action: IActionArg<Nullable<EVendorStatus>>) => {
			if (!state.data || !action.payload) return;

			state.data.info.status = action.payload;
		},
		setIsCariUnlimited: (state, action: IActionArg<boolean>) => {
			if (!state.data) return;

			const is_associated_with_cari_unlimited = action.payload ? EChoiceOption.YES : EChoiceOption.NO;
			state.data.is_associated_with_cari_unlimited = is_associated_with_cari_unlimited;
		},
		cleanup: (state) => {
			state.data = null;
			state.error = null;
			state.loading = false;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchVendorInformation.pending, pendingCase)
			.addCase(fetchVendorInformation.rejected, (state, { payload }) => {
				state.error = payload;
				errorCase(state);
			})
			.addCase(fetchVendorInformation.fulfilled, (state, { payload }) => {
				state.data = payload;
				state.loading = false;
			});
	},
});

export const { setStatus, cleanup, setData, setIsCariUnlimited } = vendorSlice.actions;

export default vendorSlice.reducer;
