import { useEffect, useState } from 'react';
import useAppBrand from './useAppBrandName';

const useLogos = () => {
	const { brand } = useAppBrand();
	const [miniLogo, setMiniLogo] = useState<string>();
	const [fullLogo, setFullLogo] = useState<string>();

	const fetchLogos = async () => {
		try {
			const fullLogoResponse = await import(`assets/logos/${brand}-logo.svg`);
			const miniLogoResponse = await import(`assets/logos/${brand}-small-logo.svg`);
			// ! set logos
			setFullLogo(fullLogoResponse.default);
			setMiniLogo(miniLogoResponse.default);
		} catch (err) {
			console.warn(err);
		}
	};

	useEffect(() => {
		fetchLogos();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return { miniLogo, fullLogo };
};

export default useLogos;
export { useLogos };
