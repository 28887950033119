import { IBranchOperationAreaCoordinates } from 'types/api';

export const convertGeoJsonDataToReadableLatLngArray = (geoJsonData: number[][]): IBranchOperationAreaCoordinates[] => {
	return geoJsonData.reduce<IBranchOperationAreaCoordinates[]>((acc, geoPosition) => {
		acc.push({
			lat: geoPosition[1],
			lng: geoPosition[0],
		});
		return acc;
	}, []);
};
