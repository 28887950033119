import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { DriverProvider } from 'hooks/useDriver';
import { LIST_DEFAULT_PARAMS } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import { ECustomerTabsKeys } from 'pages/Customers/CustomerDetails/Tabs/types';
import { DRIVER_TAB_ROUTES } from './routes.drivers-tabs';

// ! Lazy module imports
const DriverList = lazy(() => import('pages/Drivers/DriverList'));
const DriverPageLayout = lazy(() => import('pages/Drivers/DriverDetails'));

export const DRIVERS_ROUTES: TRouteObject = {
	path: 'drivers',
	element: <ListParamsRouteWrapper key='drivers' />,
	handle: {
		translationKey: 'drivers',
		permissions: [APP_PERMISSIONS.driver.view],
		defaultListParams: LIST_DEFAULT_PARAMS,
		showOnNav: true,
	},
	children: [
		{
			index: true,
			element: <DriverList />,
			handle: {
				permissions: [APP_PERMISSIONS.driver.view],
				defaultListParams: LIST_DEFAULT_PARAMS,
				showOnNav: false,
			},
		},
		{
			path: ':driverId',
			element: (
				<DriverProvider>
					<DriverPageLayout />
				</DriverProvider>
			),
			handle: {
				translationKey: 'driver_details',
				permissions: [APP_PERMISSIONS.driver.view],
				showOnNav: false,
			},
			children: [
				{
					index: true,
					element: (
						<Navigate
							to={ECustomerTabsKeys.DETAILS}
							replace
						/>
					),
				},
				...DRIVER_TAB_ROUTES,
			],
		},
	],
};
