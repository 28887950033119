/**
 * MOCK BE SERVER
 *  */
import { IFeatureVariantCurrentSelection, IFeatureVariantMapConfiguration } from '../types';
import SERVER_CONFIGURATIONS_AS_JSON from './featureVariantConfig.json';

// EXAMPLE SERVER CONFIGURATION
const SERVER_CONFIGURATIONS: IFeatureVariantMapConfiguration = SERVER_CONFIGURATIONS_AS_JSON;

const namePartitionsThatHaveNoName = (
	configuration: IFeatureVariantMapConfiguration
): IFeatureVariantMapConfiguration =>
	Object.entries(configuration).reduce<IFeatureVariantMapConfiguration>((acc, [featureName, partitionList]) => {
		acc[featureName] = partitionList.map((partition, index) => ({
			...partition,
			name: partition.name ?? index.toString(),
		}));
		return acc;
	}, {});

const fetchABVersioningServerConfiguration = (): IFeatureVariantMapConfiguration =>
	namePartitionsThatHaveNoName(SERVER_CONFIGURATIONS);

// TEMPORARY convert configuration into FeatureVariant SELECTION
// PROCESS SERVER CONFIGURATIONS TO USER_ABVersion_SELECTION. first defaultActive wins
export const serverProcessConfigurationFirstDefaultActive = (
	configuration: IFeatureVariantMapConfiguration
): IFeatureVariantCurrentSelection => {
	configuration = namePartitionsThatHaveNoName(configuration);

	return Object.entries(configuration).reduce<IFeatureVariantCurrentSelection>(
		(acc, [featureName, partitionList]) => {
			let activeElement = partitionList.find((partition) => partition.defaultActive);

			if (!activeElement) {
				activeElement = partitionList[0];
			}

			acc[featureName] = activeElement.name;

			return acc;
		},
		{}
	);
};
// ===========

const getClientVersionSelectionFromServer = async () => {
	return serverProcessConfigurationFirstDefaultActive(SERVER_CONFIGURATIONS);
};

export { fetchABVersioningServerConfiguration, getClientVersionSelectionFromServer };
