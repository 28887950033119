import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetFormValues, useNewHttpClient } from 'hooks';
import { isNumber } from 'utils/validationUtils/isNumber';
import { VENDOR_MENU_API } from 'configs/api';
import { DEFAULT_MODAL_PROPS } from 'configs/common';
import { EBranchesAssociationPayloadAssociationType, IMenu, IVendorAssociableBranch } from 'types/api';
import { IListResponse, ISelectOption, TEmptyFunction } from 'types/common';
import { Form, Modal, Select } from 'antd';

interface IAssociatedMenuModalProps {
	open: boolean;
	vendorId: number;
	branchId: number;
	associatedMenuId?: number;
	onCloseModal: TEmptyFunction;
	onAfterConfirm: (newAssociatedMenuId: number) => void;
}

export const AssociatedMenuModal: FC<IAssociatedMenuModalProps> = ({
	vendorId,
	branchId,
	associatedMenuId,
	open,
	onCloseModal,
	onAfterConfirm,
}) => {
	const { getFormValues } = useGetFormValues();

	const { t: tBranchMenu } = useTranslation('vendors', {
		keyPrefix: 'vendor_details.tabs.branches.branch_details.tabs.menus',
	});

	const [form] = Form.useForm<{ menu_id: number }>();

	const [menuList, setMenuList] = useState<IMenu[]>([]);

	// ! http clients
	const fetchMenuListHttpClient = useNewHttpClient<IListResponse<IMenu>>();
	const fetchAssociatedBranchIdsHttpClient = useNewHttpClient<IListResponse<IVendorAssociableBranch>>();
	const associateBranchesHttpClient = useNewHttpClient();

	// ! memos
	const menuOptionList = useMemo<ISelectOption[]>(
		() =>
			menuList?.map(({ id, name, type }) => ({
				value: id,
				label: `#${id} ${name} (${type.toUpperCase()})`,
			})),
		[menuList]
	);

	// ! handlers

	const fetchMenus = useCallback(
		(vendorId: number) => {
			return fetchMenuListHttpClient.request({
				requestConfig: VENDOR_MENU_API.fetchList(vendorId),
				successCallback: (data) => {
					setMenuList(data?.data ?? []);
				},
			});
		},
		[] // eslint-disable-line
	);

	// ! effects
	useEffect(() => {
		if (!open) return;

		fetchMenus(vendorId);
	}, [fetchMenus, open, vendorId]);

	useEffect(() => {
		if (associatedMenuId && isNumber(associatedMenuId)) {
			form.setFieldsValue({ menu_id: associatedMenuId });
		}
	}, [open, associatedMenuId, form]);

	// ! handlers
	const onOkModal = async () => {
		const values = await getFormValues(form);

		if (!values) return;

		const newSelectedMenuId = values.menu_id;

		associateBranchesHttpClient.request({
			requestConfig: VENDOR_MENU_API.postStores(vendorId, newSelectedMenuId, {
				add_ids: [branchId],
				associationType: EBranchesAssociationPayloadAssociationType.CHOSEN,
			}),
			successCallback: () => {
				onAfterConfirm(newSelectedMenuId);
			},
		});
	};

	const onAfterCloseModal = () => form.resetFields();

	return (
		<Form
			form={form}
			layout='vertical'
			name='associate_menu_form'
		>
			<Modal
				{...DEFAULT_MODAL_PROPS}
				open={open}
				title={tBranchMenu('actions.associate_menu')}
				confirmLoading={fetchAssociatedBranchIdsHttpClient.isLoading || associateBranchesHttpClient.isLoading}
				onOk={onOkModal}
				onCancel={onCloseModal}
				afterClose={onAfterCloseModal}
			>
				<Form.Item
					name='menu_id'
					rules={[{ required: true }]}
				>
					<Select
						loading={fetchMenuListHttpClient.isLoading}
						options={menuOptionList}
					/>
				</Form.Item>
			</Modal>
		</Form>
	);
};
