import { ICompensationRequestListItem } from 'types/api/compensationRequest';
import { ICompensationRequestTabTableData } from './types';

export const transformICompensationRequestListItemToTableData = (
	compensationItem: ICompensationRequestListItem
): ICompensationRequestTabTableData => {
	return {
		...compensationItem,
		key: compensationItem.id,
	};
};
