import { THttpRequestConfig, THttpRequestParams } from 'hooks';

export const STORES_API = {
	genericList: (search?: string, params?: THttpRequestParams): THttpRequestConfig => ({
		url: 'operations/stores/generic',
		method: 'GET',
		params: {
			search,
			...params,
		},
	}),
};
