import { CSSProperties, FC, Key, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
import { getSettingsSlice } from 'store/selectors';
import { setSelectedAreas as setSelectedAreas_REDUX } from 'store/slices/settings';
import { useAreasDrawer, useGlobalConfigs, useNewHttpClient } from 'hooks';
import { transformIAreaToTableData } from './helper';
import { AREAS_API, LIST_DEFAULT_PARAMS } from 'configs/api';
import { IArea } from 'types/api';
import { IListResponse, Nullable } from 'types/common';
import { EAreasFilterMode, IAreasSelectionTableData, ISelectAresName } from './types';
import CustomDrawer from 'components/CustomDrawer';
import Spinner from 'components/Spinner';
import styles from './SelectAreasDrawer.module.css';
import { CorrectLanguageName, SELECT_AREAS_DRAWER_TABLE_COLUMNS } from './config';
import { Button, Flex, Input, Pagination, Radio, RadioChangeEvent, Typography as T, Table, Tooltip } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';

export const SelectAreasDrawer: FC = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { storage } = useGlobalConfigs();
	const { openSelectAreasDrawer, closeDrawer } = useAreasDrawer();
	const { t: tAreas } = useTranslation('areas', { keyPrefix: 'drawer' });

	// ! selectors
	const { selectedAreasId: REDUX_areas } = useSelector(getSettingsSlice);

	// ! states
	const [selectedAreasIds, setSelectedAreasIds] = useState<Key[]>([]);
	const [selectedAreasNames, setSelectedAreasNames] = useState<Record<Key, ISelectAresName>>({});
	const [selectedCountryAreas, setSelectedCountryAreas] = useState<IArea[]>([]);
	const [filterMode, setFilterMode] = useState<EAreasFilterMode>();
	const [totalAreas, setTotalAreas] = useState<number>();
	const [currentPage, setCurrentPage] = useState(LIST_DEFAULT_PARAMS.page);
	const [perPage, setPerPage] = useState(LIST_DEFAULT_PARAMS.per_page);
	const [search, setSearch] = useState('');

	// ! http
	const fetchAreasHttpClient = useNewHttpClient<IListResponse<IArea>>();

	// ! memos
	const dataSource: IAreasSelectionTableData[] = useMemo(
		() => selectedCountryAreas.map(transformIAreaToTableData),
		[selectedCountryAreas]
	);

	const isFilterModeAll = useMemo(() => filterMode === EAreasFilterMode.ALL, [filterMode]);

	const isApplyButtonDisabled = useMemo(
		() => !isFilterModeAll && selectedAreasIds?.length === 0,
		[isFilterModeAll, selectedAreasIds?.length]
	);

	const allowClose = useMemo(() => !isApplyButtonDisabled, [isApplyButtonDisabled]);

	// ! handlers
	const handleFetchAreas = () => {
		fetchAreasHttpClient.request({
			requestConfig: AREAS_API.genericList(search, { page: currentPage, per_page: perPage }),
			successCallback({ data, count }) {
				setSelectedCountryAreas(data);
				setTotalAreas(count);
			},
		});
	};

	// * table management
	const haveAreasChanged = (oldAreas: Nullable<Key[]>, newAreas: Nullable<Key[]>): boolean => {
		if (oldAreas === null || newAreas === null || oldAreas.length !== newAreas.length) return true;

		const stringifiedOldAreas = oldAreas.map((old) => old.toString());
		const stringifiedNewAreas = newAreas.map((newArea) => newArea.toString());

		return stringifiedOldAreas.some((oldArea: string) => !stringifiedNewAreas.includes(oldArea));
	};

	const handleFinishAreasSelect = (selectedAreas: Key[]) => {
		dispatch(
			setSelectedAreas_REDUX({
				selectedAreas: selectedAreas.map((key) => +key),
				storage,
			})
		);
	};

	const handleApply = () => {
		if (!allowClose) return;

		if (haveAreasChanged(REDUX_areas, selectedAreasIds)) {
			handleFinishAreasSelect(selectedAreasIds);
			navigate(0);
		}

		handleCloseDrawer(true);
	};

	const handleCloseDrawer = (applied?: boolean) => {
		if (!allowClose) return;

		if (!applied) {
			setSelectedAreasIds(REDUX_areas ?? []);
		}

		setCurrentPage(LIST_DEFAULT_PARAMS.page);

		closeDrawer();
	};

	const handleRowSelection = (record: IAreasSelectionTableData) => {
		setSelectedAreasNames((prev) => {
			if (prev[record.id]) {
				delete prev[record.id];
			} else {
				prev[record.id] = { key: +record.key, name_ar: record.name_ar, name: record.name };
			}

			return { ...prev };
		});

		setSelectedAreasIds((prev) => {
			let newSelectedRows = [];

			if (prev) {
				newSelectedRows.push(...prev);
			}

			const hashMapActiveKey = newSelectedRows.reduce<Record<React.Key, true>>((acc, elem) => {
				acc[elem] = true;

				return acc;
			}, {});

			if (hashMapActiveKey[record.key]) {
				delete hashMapActiveKey[record.key];
			} else {
				hashMapActiveKey[record.key] = true;
			}

			const newList = Object.keys(hashMapActiveKey).map((id) => +id);
			return newList;
		});
	};

	const handleDeselectAll = () => {
		setSelectedAreasIds([]);
		setSelectedAreasNames({});
	};

	const handleFilterModeChange = (e: RadioChangeEvent) => {
		handleDeselectAll();
		setFilterMode(e.target.value);
	};

	const onSearch = (value: string) => {
		if (currentPage !== LIST_DEFAULT_PARAMS.page) setCurrentPage(LIST_DEFAULT_PARAMS.page);
		setSearch(value);
	};

	const handlePaginationChange = (page: number, pageSize: number) => {
		setCurrentPage(page.toString());
		setPerPage(pageSize.toString());
	};

	// ! effects
	useEffect(() => {
		if (openSelectAreasDrawer) {
			handleFetchAreas();
		}
	}, [openSelectAreasDrawer, currentPage, perPage, search]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		setFilterMode(REDUX_areas.length > 0 ? EAreasFilterMode.SPECIFIC : EAreasFilterMode.ALL);

		if (selectedAreasIds.length > 0 && openSelectAreasDrawer) {
			fetchAreasHttpClient.request({
				requestConfig: AREAS_API.genericList(undefined, { id: selectedAreasIds.map(String) }),

				successCallback: (response) => {
					setSelectedAreasNames(() =>
						response.data.reduce<Record<Key, ISelectAresName>>((acc, { id, name, name_ar }) => {
							acc[id] = { name_ar, name, key: +id };

							return acc;
						}, {})
					);
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openSelectAreasDrawer]);

	useEffect(() => {
		if (REDUX_areas) {
			setSelectedAreasIds(REDUX_areas.map((id) => +id));
		}
	}, [REDUX_areas]);

	// ! render
	const pagination = {
		total: totalAreas,
		current: +currentPage,
		pageSize: +perPage,
		disabled: isFilterModeAll,
	};

	const rowSelectionConfig: TableRowSelection<IAreasSelectionTableData> = {
		onSelect: handleRowSelection,
		checkStrictly: false,
		selectedRowKeys: selectedAreasIds ?? [],
		fixed: true,
		getCheckboxProps: () => ({
			disabled: isFilterModeAll,
		}),
	};

	const onRowClickConfig = (record: IAreasSelectionTableData) => ({
		onClick: () => {
			if (isFilterModeAll) {
				return;
			}
			handleRowSelection(record);
		},
	});

	const bodyStyle: CSSProperties = { padding: 0 };
	const footerStyle: CSSProperties = { paddingTop: '24px', paddingBottom: '40px' };

	const areasTooltip = useMemo(() => {
		const areaList = Object.values(selectedAreasNames);

		return (
			<>
				{areaList.length > 0
					? areaList.map((area, index) => (
							<T.Text
								key={index}
								className={styles.areas_tooltip}
							>
								<CorrectLanguageName
									name={area.name}
									name_ar={area.name_ar}
								/>
								{index !== areaList.length - 1 ? ', ' : ''}
							</T.Text>
					  ))
					: tAreas('no_areas_selected')}
			</>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedAreasNames]);

	return (
		<CustomDrawer
			title={tAreas('title')}
			placement='right'
			size='large'
			open={openSelectAreasDrawer}
			onClose={() => handleCloseDrawer()}
			styles={{
				body: bodyStyle,
				footer: footerStyle,
			}}
			classNames={{
				footer: styles.footer,
			}}
			footer={
				<Flex
					vertical
					gap={12}
					align='center'
				>
					<Pagination
						{...pagination}
						showSizeChanger
						onChange={handlePaginationChange}
					/>

					{isApplyButtonDisabled && <T.Text type='danger'>{tAreas('warning_no_selection')}</T.Text>}

					<Button
						type='primary'
						onClick={handleApply}
						disabled={isApplyButtonDisabled}
					>
						{tAreas('apply')}
					</Button>
				</Flex>
			}
		>
			<Flex
				vertical
				className='h-100'
			>
				<Flex
					className={`w-100 ${styles.fixed_header}`}
					vertical
					gap={20}
				>
					<Radio.Group
						value={filterMode}
						onChange={handleFilterModeChange}
						defaultValue={EAreasFilterMode.ALL}
					>
						<Radio.Button value={EAreasFilterMode.ALL}>
							{tAreas(`radio.${EAreasFilterMode.ALL}`)}
						</Radio.Button>
						<Radio.Button value={EAreasFilterMode.SPECIFIC}>
							<Tooltip title={areasTooltip}>
								{tAreas(`radio.${EAreasFilterMode.SPECIFIC}`, { count: selectedAreasIds?.length ?? 0 })}
							</Tooltip>
						</Radio.Button>
					</Radio.Group>

					<Flex
						justify='end'
						gap='small'
					>
						<Input.Search
							allowClear
							placeholder={tAreas('search_placeholder')}
							disabled={isFilterModeAll || fetchAreasHttpClient.isLoading}
							onSearch={onSearch}
						/>

						<Button
							disabled={isFilterModeAll}
							onClick={handleDeselectAll}
						>
							{tAreas('select.none')}
						</Button>
					</Flex>
				</Flex>

				<Flex
					vertical
					gap='middle'
					className={`w-100 ${styles.table_container}`}
				>
					{fetchAreasHttpClient.isLoading && <Spinner defaultAntdSpinner />}

					{!fetchAreasHttpClient.isLoading && fetchAreasHttpClient.response && (
						<Table<IAreasSelectionTableData>
							className={styles.table}
							showHeader={false}
							bordered={false}
							columns={SELECT_AREAS_DRAWER_TABLE_COLUMNS}
							dataSource={dataSource}
							pagination={{ ...pagination, style: { display: 'none' } }}
							rowClassName={styles.table_row}
							onRow={onRowClickConfig}
							rowSelection={rowSelectionConfig}
						/>
					)}
				</Flex>
			</Flex>
		</CustomDrawer>
	);
};
