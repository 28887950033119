import { StandaloneSearchBox } from '@react-google-maps/api';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { TEmptyFunction } from 'types/common';
import styles from './MapSearchBox.module.css';
import { Input } from 'antd';

interface IMapSearchBoxProps {
	onLoad: (searchBox: google.maps.places.SearchBox) => void;
	onUnmount: (searchBox: google.maps.places.SearchBox) => void;
	onPlacesChanged?: TEmptyFunction;
}

const MapSearchBox: FC<IMapSearchBoxProps> = memo(({ onLoad, onUnmount, onPlacesChanged }) => {
	const { t: tCommon } = useTranslation('common');

	// ! render
	return (
		<StandaloneSearchBox
			onLoad={onLoad}
			onUnmount={onUnmount}
			onPlacesChanged={onPlacesChanged}
		>
			<Input
				placeholder={tCommon('map_search_box.placeholder')}
				className={styles.map_search_input}
				onPressEnter={(e) => e.preventDefault()}
			/>
		</StandaloneSearchBox>
	);
});

export default MapSearchBox;
