import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { errorCase, pendingCase } from '../helpers';
import { THttpFunction } from 'utils/axiosInstance';
import { PERMISSIONS_LIST } from 'configs/api';
import { IPermissionItem } from 'types/api';
import { Nullable } from 'types/common';
import { IDefaultStateFields } from '../types';

// ! initial state
interface IPermissionsSlice extends IDefaultStateFields {
	permissionsList: Nullable<IPermissionItem[]>;
}

const permissionsSliceInitialState: IPermissionsSlice = {
	permissionsList: null,

	// own state
	loading: false,
};

// ! thunks
export const fetchPermissions = createAsyncThunk(
	'permissionsSlice/fetchPermissions',
	async ({ http }: { http: THttpFunction }, { rejectWithValue }) => {
		try {
			const response = await http(PERMISSIONS_LIST);
			return response.data;
		} catch (error) {
			console.error(error);
			return rejectWithValue(error);
		}
	}
);

const permissionsSlice = createSlice({
	name: 'permissionsSlice',
	initialState: permissionsSliceInitialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchPermissions.pending, pendingCase)
			.addCase(fetchPermissions.rejected, errorCase)
			.addCase(fetchPermissions.fulfilled, (state, action) => {
				if (!action?.payload?.data) return;
				state.permissionsList = action.payload.data;
				state.loading = false;
			});
	},
});

export default permissionsSlice.reducer;
