import { lazy } from 'react';
import { LIST_DEFAULT_PARAMS } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import { ApartmentOutlined } from '@ant-design/icons';

const Suppliers = lazy(() => import('pages/Suppliers'));
const AddSupplier = lazy(() => import('pages/Suppliers/AddSupplier'));

export const SUPPLIERS_ROUTES: TRouteObject = {
	path: 'suppliers',
	element: <ListParamsRouteWrapper key='suppliers' />,
	handle: {
		translationKey: 'suppliers',
		icon: <ApartmentOutlined />,
		defaultListParams: LIST_DEFAULT_PARAMS,
		permissions: [APP_PERMISSIONS.supplier.view],
		showOnNav: true,
	},
	children: [
		{
			index: true,
			element: <Suppliers />,
		},
		{
			path: 'new',
			element: <AddSupplier />,
			handle: {
				translationKey: 'add_new_supplier',
				permissions: [APP_PERMISSIONS.supplier.add],
				showOnNav: false,
			},
		},
	],
};
