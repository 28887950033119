import { Location, NavigateFunction, RouteObject, matchRoutes } from 'react-router-dom';
import { AppDispatch } from 'store';
import { setSelectedCountry } from 'store/slices/settings';
import { THttpFunction } from 'utils/axiosInstance';
import { IStorageUtils, getAuthTokenFromLocalStorage } from 'utils/localStorage';
import { getUserPermissionConfig } from 'configs/api';
import { COUNTRY_ID_STORAGE_KEY } from 'configs/common';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { Nullable } from 'types/common';
import { IPermissionsResponse } from './types';

export const fetchUserPermissions = async (http: THttpFunction) => {
	const res: IPermissionsResponse = await http(getUserPermissionConfig());
	return res?.data?.permissions || [];
};

export const setDefaultCountry = (storage: IStorageUtils, defaultCountryId: number, dispatch: AppDispatch) => {
	let countryId = storage.get(COUNTRY_ID_STORAGE_KEY) ?? defaultCountryId;

	dispatch(setSelectedCountry({ newSelectedCountryId: +countryId, storage }));
};

export const isAuthenticated = (storage: IStorageUtils): boolean => {
	if (!storage) {
		return false;
	}

	const token = getAuthTokenFromLocalStorage(storage);

	return !!(token && token.length > 0);
};

// ! log in redirect flow
const findFirstNonDynamicRoute = (routes: RouteObject[] = []): Nullable<string> => {
	for (const route of routes) {
		const { path, children } = route;

		// non-dynamic path founded
		if (path && !path.includes(':')) return path;

		// makes no sense to check for children if path is dynamic
		if (path && path.includes(':')) continue;

		// check for children if we have no path
		if (children?.length) {
			const childPath = findFirstNonDynamicRoute(children);
			if (childPath) {
				return childPath;
			}
		}
	}

	return null;
};

const checkIsFromAllowed = (routes: RouteObject[], from: Location) => {
	if (!from?.pathname?.length) return false;

	const matchedRoutes = matchRoutes(routes, from);
	if (!matchedRoutes?.length) return false;

	const onlyAsteriskRoute = matchedRoutes.every((matched) => matched?.route?.path === '*');
	return !onlyAsteriskRoute;
};

export const findRedirectRoute = (routes: RouteObject[], from?: Location): string | Location => {
	if (from) {
		const isFromAllowed = checkIsFromAllowed(routes, from);
		if (isFromAllowed) return from;
	}

	return findFirstNonDynamicRoute(routes) || '';
};

export const processClientLogout = (storage: IStorageUtils, navigate: NavigateFunction) => {
	storage.clear();

	// Go to Login page
	navigate(ABSOLUTE_ROUTES.LOGIN, {});
};
