import { FC } from 'react';
import TranslatedLabel from 'components/TranslatedLabel';
import ErrorPage from 'pages/ErrorPage';

export const ErrorPage404: FC = () => {
	return (
		<ErrorPage
			status='404'
			title={
				<TranslatedLabel
					nameSpace='error-page'
					i18nKey='page_not_found.title'
				/>
			}
			subTitle={
				<TranslatedLabel
					nameSpace='error-page'
					i18nKey='page_not_found.subtitle'
				/>
			}
		/>
	);
};
