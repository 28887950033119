import { FC, PropsWithChildren, Suspense, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
import { getSettingsSlice } from 'store/selectors';
import { fetchPermissions } from 'store/slices/permissions';
import { setLanguage, setSelectedAreas } from 'store/slices/settings';
import { useAuth, useGlobalConfigs } from 'hooks';
import { getNavItems } from './helper';
import { getSelectedAreasFromLocalStorage } from 'utils/localStorage';
import { APP_LANGUAGE_STORAGE_KEY, SUPPORTED_LANGUAGES } from 'configs/common';
import { LAYOUT_CONTENT_ID } from './configs';
import { ESupportedLanguages } from 'types/common';
import CustomLayoutCard from 'components/CustomLayoutCard';
import Header from 'components/Header';
import Nav from 'components/Nav';
import Spinner from 'components/Spinner';
import styles from './Layout.module.css';
import { ConfigProvider } from 'antd';
import { Locale } from 'antd/es/locale';
import arEG from 'antd/es/locale/ar_EG';
import enUS from 'antd/es/locale/en_US';

const ANTD_LANGUAGE_LOCALE: Record<ESupportedLanguages, Locale> = {
	en: enUS,
	ar: arEG,
};

export const Layout: FC<PropsWithChildren> = ({ children = <Outlet /> }) => {
	const { http, storage } = useGlobalConfigs();
	const { routesContext, isSigningOut } = useAuth();
	const dispatch = useDispatch();
	const { t: tCommon } = useTranslation('common');

	const formValidateMessages = {
		required: tCommon('form_validations.required_field'),
	};

	// ! selectors
	const { language: currentAppLanguage } = useSelector(getSettingsSlice);
	const languageConfig = SUPPORTED_LANGUAGES[currentAppLanguage];

	// ! useMemos
	const direction = useMemo(() => languageConfig?.defaultDirection ?? 'ltr', [languageConfig?.defaultDirection]);
	const navItems = useMemo(() => getNavItems(routesContext.allowedRoutes), [routesContext]);

	// ! useEffects
	useEffect(() => {
		/*
		 * Fetch all existing permissions and place them to redux permissions slice
		 */
		dispatch(fetchPermissions({ http }));

		/*
		 * ! HAMMER TO REVIEW: ▬▬█ @tlsltaken @nibsa ▬▬█
		 * change BE to accept country_ISO_TWO_CODE instead of country id
		 */
		dispatch(
			setLanguage(
				(localStorage.getItem(APP_LANGUAGE_STORAGE_KEY) as ESupportedLanguages) ?? ESupportedLanguages.ENGLISH
			)
		);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// load REDUX
	useEffect(() => {
		const areas = getSelectedAreasFromLocalStorage(storage);

		dispatch(
			setSelectedAreas({
				selectedAreas: areas,
				storage,
			})
		);
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// ! render
	return (
		<ConfigProvider
			direction={direction}
			locale={ANTD_LANGUAGE_LOCALE[currentAppLanguage] ?? enUS}
			form={{ validateMessages: formValidateMessages }}
		>
			{isSigningOut && <Spinner defaultAntdSpinner />}

			<div className={`${styles.wrapper}  ${direction}`}>
				<div className={styles.header}>
					<Header />
				</div>
				<div className={styles.sidebar}>
					<Nav items={navItems} />
				</div>
				<div
					className={styles.content}
					id={LAYOUT_CONTENT_ID}
				>
					<CustomLayoutCard style={{ height: '100%', overflow: 'auto' }}>
						<Suspense fallback={<Spinner />}>{children}</Suspense>
					</CustomLayoutCard>
				</div>
			</div>
		</ConfigProvider>
	);
};
