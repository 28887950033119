import { THttpRequestConfig } from 'hooks';
import { IUpdatePaymentData } from 'types/api';

export const PAYMENTS_API = {
	list: (): THttpRequestConfig => ({
		url: 'operations/payments',
		method: 'GET',
	}),

	listRefunds: (): THttpRequestConfig => ({
		url: 'operations/payments/refund-methods',
		method: 'GET',
	}),

	updatePayment: (id: number, data: IUpdatePaymentData): THttpRequestConfig => ({
		url: `operations/payments/${id}`,
		method: 'PATCH',
		data,
	}),
};
