import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { getSettingsSlice } from 'store/selectors';
import { ESupportedLanguages } from 'types/common';
import { IAreasSelectionTableData } from './types';
import { ColumnsType } from 'antd/lib/table';

export const SELECT_AREAS_DRAWER_TABLE_COLUMNS: ColumnsType<IAreasSelectionTableData> = [
	{
		key: 'name',
		title: 'Name',
		render: (_, record) => (
			<CorrectLanguageName
				name={record.name}
				name_ar={record.name_ar}
			/>
		),
	},
];

export const CorrectLanguageName: FC<{ name: ReactNode; name_ar: ReactNode }> = ({ name, name_ar }) => {
	// ! selectors
	const { language } = useSelector(getSettingsSlice);

	return <>{language === ESupportedLanguages.ARABIC ? name_ar : name}</>;
};
