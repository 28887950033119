import { Dispatch, PropsWithChildren, SetStateAction, createContext, useContext, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { ORDERS_API } from 'configs/api';
import { IOrder } from 'types/api';
import { Nullable } from 'types/common';
import { useNewHttpClient } from './useHttpClient';
import { IHttpClientData } from './useHttpClient/types';

interface IOrderContextData {
	orderId: number;

	orderData: Nullable<IOrder>;
	setOrder: Dispatch<SetStateAction<Nullable<IOrder>>>;
	//
	fetchHttpClient: IHttpClientData<IOrder>;
	fetchOrder: (signal?: AbortSignal) => Promise<void>;
}

const Context = createContext<IOrderContextData>({} as IOrderContextData);

const useOrder = () => {
	return useContext(Context);
};

const OrderProvider = ({ children = <Outlet /> }: PropsWithChildren<Partial<IOrderContextData>>) => {
	const { orderId } = useParams();

	// ! states
	const [orderData, setOrderData] = useState<Nullable<IOrder>>(null);

	// ! http clients
	const fetchHttpClient = useNewHttpClient<IOrder>();

	// ! render
	if (!orderId || Number.isNaN(orderId)) return null;

	// ! handlers
	const fetchOrder = (signal?: AbortSignal) => {
		return fetchHttpClient.request(
			{
				requestConfig: ORDERS_API.get(+orderId),
				successCallback: (response) => {
					setOrderData(response);
				},
			},
			signal
		);
	};

	const data: IOrderContextData = {
		orderId: +orderId,
		orderData,
		fetchHttpClient,

		//
		fetchOrder,
		setOrder: setOrderData,
	};

	return <Context.Provider value={data}>{children}</Context.Provider>;
};

export { useOrder, OrderProvider };
