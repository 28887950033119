import { useState } from 'react';
import { TEmptyFunction } from 'types/common';

export interface IUseForceRefreshController {
	forceRefreshData: number;
	refreshingData: TEmptyFunction;
}

const useForceRefreshData = (): IUseForceRefreshController => {
	const [forceRefreshData, setRefreshData] = useState(0);

	const refreshingData: TEmptyFunction = () => setRefreshData((previousValue) => previousValue + 1);

	return { forceRefreshData, refreshingData };
};

export default useForceRefreshData;
export { useForceRefreshData };
