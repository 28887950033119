export const DEFAULT_USER_INTERACTION_DEBOUNCE = 300;

export const debounce = (func: Function, timeout = 2000) => {
	let timer: NodeJS.Timeout;

	return (...args: unknown[]) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			func.apply(this, args);
		}, timeout);
	};
};
