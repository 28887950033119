import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_TAG_STYLES } from 'configs/common';
import { STATUS_TAG_COLORS } from './configs';
import { ICustomerCariUnlimitedSubscriptionStatusTagProps } from './types';
import { Tag, Tooltip } from 'antd';

export const CustomerCariUnlimitedSubscriptionStatusTag: FC<ICustomerCariUnlimitedSubscriptionStatusTagProps> = ({
	status,
	statusDescription = '',
}) => {
	const { t: tCustomerCariUnlimitedDetails } = useTranslation('customers', {
		keyPrefix: 'customer_details.tabs.details.cari_unlimited_card',
	});

	if (!status) {
		return (
			<Tag
				color='red'
				style={DEFAULT_TAG_STYLES}
			>
				{tCustomerCariUnlimitedDetails('status.not_subscribed')}
			</Tag>
		);
	}

	// ! render
	return (
		<Tooltip title={statusDescription}>
			<Tag
				color={STATUS_TAG_COLORS[status]}
				style={DEFAULT_TAG_STYLES}
			>
				{tCustomerCariUnlimitedDetails(`status.${status}`)}
			</Tag>
		</Tooltip>
	);
};
