import { FC, memo } from 'react';
import { getGoogleMapsExternalLink } from 'configs/common';
import { IAddressLinkBtnProps } from './types';
import LinkButton from 'components/LinkButton';
import styles from './AddressLinkButton.module.css';

export const AddressLinkButton: FC<IAddressLinkBtnProps> = memo(({ icon, address, addressLatLng }) => {
	const link = `${addressLatLng.lat},${addressLatLng.lng}`;

	// ! render
	return (
		<LinkButton
			type='link'
			icon={icon}
			target='_blank'
			className={styles.address_link_button}
			to={getGoogleMapsExternalLink(`${addressLatLng.lat},${addressLatLng.lng}`)}
			label={address ?? link}
		/>
	);
});
