import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Property } from 'csstype';
import { DEFAULT_TAG_STYLES } from 'configs/common';
import { EOrderStatus } from 'types/api';
import { EOrderStatusTranslationKeys, IOrderStatusTagProps } from './types';
import { Tag, Tooltip } from 'antd';

export const OrderStatusTag: FC<IOrderStatusTagProps> = memo(({ status, description }) => {
	const { t: tCommon } = useTranslation('common');

	const config: Record<EOrderStatus, Property.Color> = {
		[EOrderStatus.ROOT]: 'default',
		[EOrderStatus.SCHEDULED]: 'geekblue',
		[EOrderStatus.NEW]: 'gold',
		[EOrderStatus.PREPARING]: 'orange',
		[EOrderStatus.READY]: 'cyan',
		[EOrderStatus.DISPATCHED]: 'blue',
		//
		[EOrderStatus.COMPLETED]: 'green',
		[EOrderStatus.REJECTED]: 'magenta',
		[EOrderStatus.CANCELLED]: 'volcano',
		[EOrderStatus.FAILED]: 'red',
		[EOrderStatus.AWAITING_PAYMENT]: 'purple',
		[EOrderStatus.INTERVENTION]: 'purple',
		[EOrderStatus.ABANDONED]: 'default',
	};

	// ! render
	const tagEl = (
		<Tag
			color={config[status]}
			style={DEFAULT_TAG_STYLES}
		>
			{tCommon(`order.status.${EOrderStatusTranslationKeys[status]}`)}
		</Tag>
	);

	return description?.length ? <Tooltip title={description}>{tagEl}</Tooltip> : tagEl;
});
