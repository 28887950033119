import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAuth, useBranch } from 'hooks';
import { APP_PERMISSIONS } from 'configs/permissions';
import Spinner from 'components/Spinner';
import MobilePresenceCard from 'pages/Vendors/components/MobilePresenceCard';
import WebPresenceCard from 'pages/Vendors/components/WebPresenceCard';
import { Button, Flex, Space } from 'antd';
import { EditOutlined } from '@ant-design/icons';

const BranchPresenceTabContent: FC = () => {
	const { t: tCommon } = useTranslation('common');
	const navigate = useNavigate();
	const { hasPermission } = useAuth();

	const { data, isLoading } = useBranch();

	// ! memos
	const canUpdate = useMemo(() => hasPermission(APP_PERMISSIONS.vendor.store.presence.update), [hasPermission]);

	// ! render
	return (
		<>
			{isLoading && <Spinner defaultAntdSpinner />}

			<Space direction='vertical'>
				<Flex
					className='w-100'
					justify='flex-end'
				>
					<Button
						icon={<EditOutlined />}
						disabled={!canUpdate}
						onClick={() => navigate('./edit')}
					>
						{tCommon('action_buttons.edit')}
					</Button>
				</Flex>

				<WebPresenceCard
					slug={data?.info?.slug}
					availableOnWeb={data?.info?.available_on_web}
				/>

				<MobilePresenceCard availableOnMobile={data?.info?.available_on_mobile} />
			</Space>
		</>
	);
};

export default BranchPresenceTabContent;
