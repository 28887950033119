import { ICountry } from 'types/api';
import { ISelectOption } from 'types/common';
import { IDefaultStateFields } from './types';

export const pendingCase = (state: IDefaultStateFields) => {
	state.loading = true;
};

export const errorCase = (state: IDefaultStateFields) => {
	state.loading = false;
};

// ! countries codes
export const reduceSelectedCountryDataToCountryCodeOptionsList = (country: ICountry): ISelectOption[] => {
	const { phone_code, iso_two_code } = country;
	return [
		{
			value: phone_code,
			label: `${iso_two_code} (${phone_code})`,
		},
	];
};

// ! timezones
export const reduceCountriesDataToTimezoneOptionsList = (countries: ICountry[]): Record<number, ISelectOption[]> => {
	return countries?.reduce<Record<number, ISelectOption[]>>((acc, { id, timezones }) => {
		acc[id] = timezones?.map(({ id, name }) => ({ value: id, label: name }));
		return acc;
	}, {});
};
