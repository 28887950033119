import { THttpRequestConfig } from 'hooks';
import { IUpdateProfileData } from 'types/api';

export const AUTH_TOKEN_KEY = 'auth_token';
export const REFRESH_TOKEN_KEY = 'refresh_token';
export const REFRESH_TOKEN_URL = 'operations/auth/token';

export const AUTH_API = {
	logout: (): THttpRequestConfig => ({
		url: 'operations/auth/logout',
		method: 'POST',
		data: {},
	}),
	profile: (): THttpRequestConfig => ({
		url: 'operations/profile',
		method: 'GET',
	}),
	updateProfileConfig: (data: IUpdateProfileData): THttpRequestConfig => ({
		url: 'operations/profile',
		method: 'PATCH',
		data,
	}),
	login: (email: string, password: string): THttpRequestConfig => ({
		url: 'operations/auth/login',
		method: 'POST',
		data: {
			email,
			password,
		},
	}),
	refreshToken: (refresh_token: string): THttpRequestConfig => ({
		url: REFRESH_TOKEN_URL,
		method: 'POST',
		data: {
			refresh_token,
		},
	}),
};
