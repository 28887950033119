import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNewHttpClient } from 'hooks';
import { PAYMENTS_API } from 'configs/api';
import { DEFAULT_MODAL_PROPS } from 'configs/common';
import { IRefundMethodItem } from 'types/api';
import { IFormItem, IListResponse, ISelectOption } from 'types/common';
import { IOrderRefundModalProps } from './types';
import BasicForm from 'components/BasicForm';
import Spinner from 'components/Spinner';
import { Input, Modal } from 'antd';

const OrderRefundModal: FC<IOrderRefundModalProps> = ({
	isOpen,
	form,
	onOk,
	onCancel,
	confirmLoading,
	title,
	maxRefund = 0,
}) => {
	const { t: tCustomersRefundModal } = useTranslation('customers', {
		keyPrefix: 'customer_details.tabs.orders.refund_modal',
	});
	const [refundOptions, setRefundOptions] = useState<ISelectOption[]>([]);

	// ! http client
	const fetchMethodsHttpClient = useNewHttpClient<IListResponse<IRefundMethodItem>>();

	const fetchRefundMethods = useCallback(() => {
		fetchMethodsHttpClient.request({
			requestConfig: PAYMENTS_API.listRefunds(),
			successCallback: (response) => {
				const options = response.data.map<ISelectOption>(({ id, title }) => ({
					value: id,
					label: title,
				}));

				setRefundOptions(options);
			},
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// ! effects
	useEffect(() => {
		fetchRefundMethods();
	}, [fetchRefundMethods]);

	useEffect(() => {
		if (!refundOptions?.length || !form || !isOpen) return;
		form.setFieldsValue({ payment_method_id: refundOptions[0].value });
	}, [form, refundOptions, isOpen]);

	// ! config
	const formConfig = useMemo<IFormItem[]>(
		() => [
			{
				key: 'amount',
				label: tCustomersRefundModal('amount'),
				initialValue: 0,
				validationsRules: [
					{ required: true },
					{
						validator: (_, value) => {
							if (value <= maxRefund) return Promise.resolve();
							return Promise.reject(new Error(tCustomersRefundModal('amount_error', { maxRefund })));
						},
					},
				],
				inputProps: {
					type: 'number',
					min: 0,
					max: maxRefund,
				},
			},
			{
				key: 'payment_method_id',
				label: tCustomersRefundModal('payment_method'),
				inputSelectionOptions: refundOptions,
				validationsRules: [{ required: true }],
			},
			{
				key: 'comment',
				label: tCustomersRefundModal('comment'),
				initialValue: '',
				validationsRules: [{ required: true }, { max: 255 }],
				inputElement: Input.TextArea,
				textAreaProps: { rows: 5, maxLength: 255 },
			},
			{
				key: 'cost_to_vendor',
				label: tCustomersRefundModal('cost_to_vendor'),
				initialValue: 0,
				inputProps: { type: 'number', min: 0 },
				validationsRules: [{ required: true }],
			},
			{
				key: 'cost_to_platform',
				label: tCustomersRefundModal('cost_to_platform'),
				initialValue: 0,
				inputProps: { type: 'number', min: 0 },
				validationsRules: [{ required: true }],
			},
		],
		[refundOptions, maxRefund, tCustomersRefundModal]
	);

	// ! render
	return (
		<Modal
			{...DEFAULT_MODAL_PROPS}
			title={title}
			open={isOpen}
			okText={tCustomersRefundModal('ok_text')}
			confirmLoading={confirmLoading}
			onOk={onOk}
			onCancel={onCancel}
		>
			{fetchMethodsHttpClient.isLoading && <Spinner defaultAntdSpinner />}

			<BasicForm
				form={form}
				name='refund_order_form'
				config={formConfig}
			/>
		</Modal>
	);
};

export default OrderRefundModal;
