import { lazy } from 'react';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import { WalletOutlined } from '@ant-design/icons';

const PaymentTypes = lazy(() => import('pages/PaymentTypes'));

export const PAYMENT_TYPES_ROUTES: TRouteObject = {
	path: 'payment-types',
	element: <PaymentTypes />,
	handle: {
		translationKey: 'payment_types',
		permissions: [APP_PERMISSIONS.payment_type.view],
		icon: <WalletOutlined />,
		showOnNav: true,
	},
};
