import { DEFAULT_DESCRIPTIONS_PROPS } from 'configs/common';
import { ECustomerStatus } from 'types/api';
import { IFormItem } from 'types/common';
import TranslatedLabel from 'components/TranslatedLabel';
import { DescriptionsProps } from 'antd';

export const DESCRIPTIONS_PROPS: DescriptionsProps = {
	...DEFAULT_DESCRIPTIONS_PROPS,
	labelStyle: { ...DEFAULT_DESCRIPTIONS_PROPS.labelStyle, alignSelf: 'start' },
};

export const CUSTOMER_EDIT_FORM_CONFIG: IFormItem[] = [
	{
		key: 'first_name',
		label: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey='shared.entity.first_name'
			/>
		),
		validationsRules: [{ required: true }],
	},
	{
		key: 'last_name',
		label: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey='shared.entity.last_name'
			/>
		),
		validationsRules: [{ required: true }],
	},
	{
		key: 'email',
		label: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey='shared.entity.email'
			/>
		),
		validationsRules: [{ required: true }, { type: 'email' }],
		disabled: true,
	},
	{
		key: 'phone_number',
		label: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey='shared.entity.phone_number'
			/>
		),
		validationsRules: [{ required: true }],
		disabled: true,
	},
	{
		key: 'status',
		label: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey='shared.entity.status'
			/>
		),
		validationsRules: [{ required: true }],
		inputSelectionOptions: [
			{
				value: ECustomerStatus.ACTIVE,
				label: (
					<TranslatedLabel
						nameSpace='customers'
						i18nKey={`shared.status_options.${ECustomerStatus.ACTIVE}`}
					/>
				),
			},
			{
				value: ECustomerStatus.BLOCKED,
				label: (
					<TranslatedLabel
						nameSpace='customers'
						i18nKey={`shared.status_options.${ECustomerStatus.BLOCKED}`}
					/>
				),
			},
		],
	},
];
