import { FC, useMemo } from 'react';
import {
	IFeatureVariantConfiguration,
	IFeatureVariantCurrentSelection,
	IFeatureVariantFeatureName,
	IFeatureVariantPartition,
} from 'hooks/FeatureVariantControl';
import useFeatureVariantContext, { IFeatureVariantConfigData } from 'hooks/FeatureVariantControl/useFeatureVariant';
import { WithKey } from 'types/common';
import { CopyToClipBoard } from 'components/CopyToClipBoard';
import layoutStyles from '../../NewLayout.module.css';
import styles from './VersionMasterSwitchPage.module.css';
import { Button, Input, Space, Table, Tooltip, Typography } from 'antd';
import { Content, Header } from 'antd/lib/layout/layout';

const VersionPartitionToggleList: FC<{
	featureName: IFeatureVariantFeatureName;
	partitionList: IFeatureVariantPartition[];
	currentSelectedPartition: IFeatureVariantPartition['name'];
}> = ({ featureName, partitionList, currentSelectedPartition }) => {
	const versionController = useFeatureVariantContext();

	const handlePartitionSelection = (newPartition: IFeatureVariantPartition['name']) => {
		versionController.setNewPartitionToFeature(featureName, newPartition);
	};

	return (
		<Space>
			{partitionList.map((partition, index) => {
				const isPartitionSelected = currentSelectedPartition === partition.name;

				return (
					<div key={index}>
						<Tooltip title={`Server default Active : ${partition.defaultActive ? 'true' : ''}`}>
							<Button
								type={isPartitionSelected ? 'primary' : undefined}
								onClick={() => handlePartitionSelection(partition.name)}
							>
								{partition.name}
							</Button>
						</Tooltip>
					</div>
				);
			})}
		</Space>
	);
};

const ShareAbConfigHelper: FC<{ currentSelectedVersions: IFeatureVariantCurrentSelection }> = ({
	currentSelectedVersions,
}) => {
	const value = new URLSearchParams(currentSelectedVersions as Record<string, string>).toString();

	return (
		<Space
			className='w-100'
			direction='vertical'
		>
			<Typography.Title>Share Feature Variant Selection</Typography.Title>
			<Input.TextArea
				value={value}
				disabled
			/>
			<CopyToClipBoard
				reverted={false}
				contentToCopy={value}
			>
				Copy Current Feature Variant Selection
			</CopyToClipBoard>
		</Space>
	);
};

const ABConfigSelection: FC<IFeatureVariantConfigData> = ({
	currentFeatureVariantSelection,
	versionConfiguration,
	clearLocalABs,
}) => {
	const dataSource = useMemo<WithKey<IFeatureVariantConfiguration>[]>(
		() =>
			Object.entries(versionConfiguration).map(([featName, partitionList]) => ({
				key: featName,
				name: featName,
				partitionList,
			})),
		[versionConfiguration]
	);

	return (
		<Space
			direction='vertical'
			className='w-100'
		>
			<Space className='w-100 justify-space-between'>
				<Typography.Title level={2}>Feature Variant Selection</Typography.Title>
				<Button
					className='text-uppercase'
					onClick={clearLocalABs}
				>
					RESET ALL VERSIONS
				</Button>
			</Space>

			<Table<IFeatureVariantConfiguration>
				dataSource={dataSource}
				pagination={false}
				columns={[
					{
						title: 'Feature Name',
						dataIndex: 'name',
					},
					{
						title: 'Partition',
						render: (_, { name: featName, partitionList }) => (
							<VersionPartitionToggleList
								featureName={featName}
								partitionList={partitionList}
								currentSelectedPartition={currentFeatureVariantSelection[featName]}
							/>
						),
					},
				]}
			/>
		</Space>
	);
};

const VersionMasterSwitchPage: FC = () => {
	const versionController = useFeatureVariantContext();

	return (
		<>
			<Header className={layoutStyles.header}>
				<Typography.Title className={`${styles.typography_no_margin}`}>
					Local Version Switch Board
				</Typography.Title>
			</Header>
			<Content className={layoutStyles.content}>
				<Space
					direction='vertical'
					size={48}
					className='w-100'
				>
					<ABConfigSelection {...versionController} />
					<ShareAbConfigHelper currentSelectedVersions={versionController.currentFeatureVariantSelection} />
				</Space>
			</Content>
		</>
	);
};

export default VersionMasterSwitchPage;
