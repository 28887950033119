import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetFormValues } from 'hooks';
import { DEFAULT_MODAL_PROPS } from 'configs/common';
import { INewDebitCreditForm } from 'types/api';
import { INewDebitModalProps } from './types';
import BasicForm from 'components/BasicForm';
import { DEBIT_MODAL_FORM_CONFIG } from './config';
import { Form, Modal } from 'antd';

const NewDebitModal: FC<INewDebitModalProps> = ({ open, confirmLoading, onCancel, onSubmit }) => {
	const { getFormValues } = useGetFormValues();
	const [debitForm] = Form.useForm<INewDebitCreditForm>();

	const { t: tCustomerWallet } = useTranslation('customers', { keyPrefix: 'customer_details.tabs.wallet_history' });

	// ! handlers
	const onSubmitHandler = async () => {
		const values = await getFormValues(debitForm);
		if (!values) return;
		onSubmit(values);
	};

	const onAfterCloseModal = () => debitForm.resetFields();

	// ! render
	return (
		<Modal
			{...DEFAULT_MODAL_PROPS}
			open={open}
			title={tCustomerWallet('new_debit_modal.title')}
			confirmLoading={confirmLoading}
			onOk={onSubmitHandler}
			onCancel={onCancel}
			afterClose={onAfterCloseModal}
		>
			<BasicForm
				form={debitForm}
				name='add_debit_form'
				config={DEBIT_MODAL_FORM_CONFIG}
			/>
		</Modal>
	);
};

export default NewDebitModal;
