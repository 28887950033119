interface AppErrorData {
	message?: string;
}

export class AppError extends Error {
	readonly isAppError = true;
	readonly data: AppErrorData = {};
	readonly status?: number;

	constructor(message?: string, status?: number) {
		super(message);
		if (message) this.data.message = message;
		if (status) this.status = status;
	}

	static isError(error: unknown): error is AppError {
		return error instanceof AppError;
	}
}
