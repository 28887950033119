import { ICompensationRequestListItem } from 'types/api';
import { ICompensationTableData } from '../types';

// ! data serializers
export const transformICompensationRequestToTableData = (
	request: ICompensationRequestListItem
): ICompensationTableData => {
	return {
		...request,
		key: request.id,
	};
};
