export enum EDeliveryTimeAction {
	INCREASE = 'increase',
	DECREASE = 'decrease',
}

export interface IDeliveryTimeData {
	min_amount: number;
	max_amount: number;
}

export interface IPostBulkUpdateETAPayload {
	min_amount: number;
	min_factor: EDeliveryTimeAction;
	max_amount: number;
	max_factor: EDeliveryTimeAction;
}

export interface IPostBulkUpdateDeliveryFeePayload {
	delivery_fee: number;
}
