import { FC } from 'react';
import { createSearchParams, generatePath, useNavigate } from 'react-router-dom';
import { useSelector } from 'store';
import { getSelectedCountryIsoTwoCode } from 'store/selectors';
import { transformToBEAcceptanceFormat } from 'utils/date';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { IRedirectToOrdersListButtonProps } from './types';
import dayjs from 'dayjs';
import { Button } from 'antd';

const RedirectToOrdersListButton: FC<IRedirectToOrdersListButtonProps> = ({
	branchId,
	vendorId,
	children,
	...props
}) => {
	const navigate = useNavigate();

	// ! selectors
	const countryIsoTwoCode = useSelector(getSelectedCountryIsoTwoCode);

	// ! handle
	const handleClick = () => {
		let idParams = {};

		if (vendorId) {
			idParams = { ...idParams, vendor_id: vendorId.toString() };
		}

		if (branchId) {
			idParams = { ...idParams, store_ids: branchId.toString() };
		}

		const searchParams = createSearchParams({
			...idParams,
			start_date: transformToBEAcceptanceFormat(dayjs().startOf('day').subtract(7, 'days')),
			end_date: transformToBEAcceptanceFormat(dayjs().endOf('day')),
		}).toString();

		navigate({
			pathname: generatePath(ABSOLUTE_ROUTES.ORDER_LIST, { countryIsoTwoCode }),
			search: searchParams,
		});
	};

	// ! render
	return (
		<Button
			onClick={handleClick}
			style={{ marginTop: 8 }}
			{...props}
		>
			{children}
		</Button>
	);
};

export default RedirectToOrdersListButton;
