import { ReactNode } from 'react';
import { ECariUnlimitedTabsKeys } from './types';
import { ApartmentOutlined, BuildOutlined, FileOutlined, InfoOutlined } from '@ant-design/icons';

export const CARI_UNLIMITED_TAB_ICONS: Record<ECariUnlimitedTabsKeys, ReactNode> = {
	[ECariUnlimitedTabsKeys.DETAILS]: <InfoOutlined />,
	[ECariUnlimitedTabsKeys.PLANS]: <BuildOutlined />,
	[ECariUnlimitedTabsKeys.VENDORS]: <ApartmentOutlined />,
	[ECariUnlimitedTabsKeys.SUPPORT_PAGES]: <FileOutlined />,
};
