import { ESupportedLanguages } from 'types/common';
import { io } from 'socket.io-client';

// ! Driver Live Tracking
// * types
interface ICreateDriverTrackingSocket {
	baseUrl: string;
	token: string;
	countryCode: string;
	language: ESupportedLanguages;
}

// * methods
// create base socket url
export const createDriverTrackingSocket = ({ baseUrl, token, countryCode, language }: ICreateDriverTrackingSocket) => {
	/* *******
		NOTE:
		The "baseUrl" value comes from the app global config file ("ws_url" prop).
		If needs to test the socket on different environments, change the value there.
	******* */
	return io(`${baseUrl}/operations_live_tracker`, {
		autoConnect: false,
		reconnectionDelay: 10000,
		timeout: 10000,
		transports: ['websocket'],
		auth: {
			token,
			country: countryCode.toLowerCase(),
			language,
		},
	});
};
