import { lazy } from 'react';
import { LIST_DEFAULT_PARAMS } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import { DeploymentUnitOutlined } from '@ant-design/icons';

// companies
const Companies = lazy(() => import('pages/Companies'));

export const COMPANIES_ROUTES: TRouteObject = {
	path: 'companies',
	element: (
		<ListParamsRouteWrapper key='companies'>
			<Companies />
		</ListParamsRouteWrapper>
	),
	handle: {
		translationKey: 'companies',
		permissions: [APP_PERMISSIONS.company.view],
		icon: <DeploymentUnitOutlined />,
		defaultListParams: LIST_DEFAULT_PARAMS,
		showOnNav: true,
	},
};
