import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { LIST_DEFAULT_PARAMS } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import {
	DEFAULT_LOGISTICS_OPTIMIZER_DETAILS_TAB_KEY,
	LOGISTICS_OPTIMIZER_TAB_ROUTES,
} from './routes.logisticsOptimizer-tabs';
import { FieldTimeOutlined, RiseOutlined } from '@ant-design/icons';
import RankingsIcon from 'assets/svgs/ranking.svg';

// ! Lazy module imports
const VendorRankings = lazy(() => import('pages/Vendors/VendorRankings'));
const LogisticsOptimizer = lazy(() => import('../LogisticsOptimizerPageLayout'));

export const LOGISTICS_OPTIMIZER_ROUTES: TRouteObject = {
	path: 'logistics',
	handle: {
		translationKey: 'logistics_optimizer',
		permissions: [],
		icon: <RiseOutlined />,
		showOnNav: true,
		isNavigateable: false,
	},
	children: [
		{
			path: 'deliveries',
			element: <LogisticsOptimizer />,
			handle: {
				translationKey: 'delivery_time_fee',
				permissions: [APP_PERMISSIONS.logistics.view],
				icon: <FieldTimeOutlined />,
				showOnNav: true,
			},
			children: [
				{
					index: true,
					element: (
						<Navigate
							to={DEFAULT_LOGISTICS_OPTIMIZER_DETAILS_TAB_KEY}
							replace
						/>
					),
				},
				...LOGISTICS_OPTIMIZER_TAB_ROUTES,
			],
		},
		{
			path: 'rankings',
			element: <VendorRankings />,
			handle: {
				translationKey: 'vendors_rankings',
				permissions: [APP_PERMISSIONS.logistics.get_stores_ranking],
				icon: (
					<img
						alt='logo'
						loading='lazy'
						src={RankingsIcon}
						width={14}
						height={14}
					/>
				),
				defaultListParams: LIST_DEFAULT_PARAMS,
				showOnNav: true,
			},
		},
	],
};
