import { AUTH_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'configs/api';
import { AREAS_ID_STORAGE_KEY } from 'configs/common';
import { IStorageUtils } from './storage';
import * as yup from 'yup';

export const getSelectedAreasFromLocalStorage = (storage: IStorageUtils): number[] => {
	const yupNumberArray = yup.array().of(yup.number().required());

	try {
		let areas = storage.get(AREAS_ID_STORAGE_KEY);

		return yupNumberArray.validateSync(areas) ?? [];
	} catch (error) {
		return [];
	}
};

export const getRefreshTokenFromLocalStorage = (storage: IStorageUtils): string => {
	const yupSchema = yup.string().required();

	try {
		let token = storage.get(REFRESH_TOKEN_KEY);

		return yupSchema.validateSync(token) ?? '';
	} catch (error) {
		return '';
	}
};

export const getAuthTokenFromLocalStorage = (storage: IStorageUtils): string => {
	const yupSchema = yup.string().required();

	try {
		let token = storage.get(AUTH_TOKEN_KEY);

		return yupSchema.validateSync(token) ?? '';
	} catch (error) {
		return '';
	}
};
