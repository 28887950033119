import { lazy } from 'react';
import { LIST_DEFAULT_PARAMS } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import { FileTextOutlined } from '@ant-design/icons';

const InvoiceList = lazy(() => import('pages/Invoices'));

export const INVOICES_ROUTES: TRouteObject = {
	path: 'invoices',
	element: <InvoiceList />,
	handle: {
		translationKey: 'invoices',
		permissions: [APP_PERMISSIONS.vendor.invoices.view],
		defaultListParams: LIST_DEFAULT_PARAMS,
		icon: <FileTextOutlined />,
		showOnNav: true,
	},
};
