import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'store';
import { getSelectedCountryIsoTwoCode } from 'store/selectors';
import { useLocaleTimeUtils, useNewHttpClient } from 'hooks';
import { PROMOTIONS_API } from 'configs/api';
import { EPromotionType, IPromotionDetailsResponse } from 'types/api';
import { IPromotionCreatePayloadTemp } from 'types/api';
import { Nullable } from 'types/common';

export interface IPromotionDetailsData extends IPromotionDetailsResponse {
	country_iso_two_code: Nullable<string>;
}

export interface IPromotionContextData {
	id: number;
	data: IPromotionDetailsData;
	isLoading: boolean;
	patch: (
		payload: IPromotionCreatePayloadTemp,
		successCb: (data: IPromotionDetailsResponse) => void
	) => Promise<void>;
	fetchData: () => Promise<null | undefined>;
}

const PromotionContext = createContext<IPromotionContextData>({} as IPromotionContextData);

const usePromotion = () => {
	return useContext(PromotionContext);
};

const PromotionProvider = ({ children }: PropsWithChildren<{ promotionType: EPromotionType }>) => {
	const fetchHttpClient = useNewHttpClient<IPromotionDetailsResponse>();
	const patchHttpClient = useNewHttpClient<IPromotionDetailsResponse>();
	const localeTimeUtils = useLocaleTimeUtils();

	// ! state
	const [data, setData] = useState<IPromotionDetailsData>();

	const { promotionId: paramPromotionCodeId } = useParams();

	const id = useMemo(() => paramPromotionCodeId, [paramPromotionCodeId]);

	// !  selectors
	const countryIsoTwoCode = useSelector(getSelectedCountryIsoTwoCode);

	// ! handlers
	const setPromotionData = (promotion: IPromotionDetailsResponse) => {
		const tempStartDate =
			promotion.duration.start_date && localeTimeUtils.getCurrentTimeZoneDate(promotion.duration.start_date);
		const tempEndDate =
			promotion.duration.end_date && localeTimeUtils.getCurrentTimeZoneDate(promotion.duration.end_date);
		promotion.duration.start_date = tempStartDate?.isValid() ? tempStartDate : null;
		promotion.duration.end_date = tempEndDate?.isValid() ? tempEndDate : null;

		setData({
			...promotion,
			country_iso_two_code: countryIsoTwoCode,
		});
	};

	const fetchData = async () => {
		if (!id || isNaN(+id)) {
			return null;
		}

		fetchHttpClient.request({
			requestConfig: PROMOTIONS_API.get(+id),
			successCallback: (response) => {
				setPromotionData(response);
			},
		});
	};

	const patch = async (
		payload: IPromotionCreatePayloadTemp,
		successCb: (data: IPromotionDetailsResponse) => void
	) => {
		if (!data?.id) {
			return;
		}

		patchHttpClient.request({
			requestConfig: PROMOTIONS_API.patch(data.id, payload),
			successCallback: (response) => {
				fetchData();

				successCb(response);
			},
		});
	};

	useEffect(() => {
		fetchData();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	if (!data || !id || isNaN(+id)) return null;

	const promotionData: IPromotionContextData = {
		id: +id,
		data,
		patch,
		isLoading: fetchHttpClient.isLoading || patchHttpClient.isLoading,
		fetchData,
	};

	return <PromotionContext.Provider value={promotionData}>{children}</PromotionContext.Provider>;
};

export default usePromotion;
export { usePromotion, PromotionProvider };
