import { lazy } from 'react';
import { LIST_DEFAULT_PARAMS } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import { StarOutlined } from '@ant-design/icons';

const DriversRating = lazy(() => import('pages/Ratings/DriversRating'));
const BranchesRating = lazy(() => import('pages/Ratings/BranchesRating'));

export const RATINGS_ROUTES: TRouteObject = {
	path: 'ratings',
	handle: {
		translationKey: 'ratings',
		icon: <StarOutlined />,
		permissions: [],
		showOnNav: true,
		isNavigateable: false,
	},
	children: [
		// ! Drivers
		{
			path: 'drivers',
			element: (
				<ListParamsRouteWrapper key='ratings-drivers'>
					<DriversRating />
				</ListParamsRouteWrapper>
			),
			handle: {
				translationKey: 'ratings_drivers',
				defaultListParams: LIST_DEFAULT_PARAMS,
				permissions: [APP_PERMISSIONS.driver_rating.view],
				showOnNav: true,
			},
		},
		// ! Branches
		{
			path: 'branches',
			element: (
				<ListParamsRouteWrapper key='ratings-branches'>
					<BranchesRating />
				</ListParamsRouteWrapper>
			),
			handle: {
				translationKey: 'ratings_branches',
				defaultListParams: LIST_DEFAULT_PARAMS,
				permissions: [APP_PERMISSIONS.store_rating.view],
				showOnNav: true,
			},
		},
	],
};
