import { FC } from 'react';
import { IWorkshiftController } from '../types';
import TimingsDay from '../TimingsDay';

interface IWorkshiftDropdownEditorProps {
	workshiftController: IWorkshiftController;
	isEdit: boolean;
}

const WorkshiftDropdownEditor: FC<IWorkshiftDropdownEditorProps> = ({ workshiftController, isEdit }) => {
	return (
		<>
			{workshiftController.timingsData.map((item) => (
				<TimingsDay
					key={item.day}
					timingsItem={item}
					onTimeChange={workshiftController.timeChange}
					onAddScheduleItem={workshiftController.addScheduleItem}
					onRemoveScheduleItem={workshiftController.removeScheduledItem}
					isEdit={isEdit}
					showDayTitle={true}
				/>
			))}
		</>
	);
};

export default WorkshiftDropdownEditor;
