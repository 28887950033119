import { THttpRequestConfig } from 'hooks';
import { ICreateUpdateOfferLabelPayload, IOfferLabel, IUpdateOfferLabelStatusPayload } from 'types/api';

export const OFFERS_LABELS_API = {
	list: (name?: IOfferLabel['name']): THttpRequestConfig => ({
		url: 'operations/promotions/offer-labels',
		method: 'GET',
		params: {
			name,
		},
	}),
	create: (data: ICreateUpdateOfferLabelPayload): THttpRequestConfig => ({
		url: 'operations/promotions/offer-labels',
		method: 'POST',
		data,
	}),
	update: (id: IOfferLabel['id'], data: ICreateUpdateOfferLabelPayload): THttpRequestConfig => ({
		url: `operations/promotions/offer-labels/${id}`,
		method: 'PATCH',
		data,
	}),
	updateStatus: (id: IOfferLabel['id'], data: IUpdateOfferLabelStatusPayload): THttpRequestConfig => ({
		url: `operations/promotions/offer-labels/${id}/status`,
		method: 'PATCH',
		data,
	}),
	delete: (id: IOfferLabel['id']): THttpRequestConfig => ({
		url: `operations/promotions/offer-labels/${id}`,
		method: 'DELETE',
	}),
};

export default OFFERS_LABELS_API;
