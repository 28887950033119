import { THttpRequestConfig } from 'hooks';
import dayjs from 'dayjs';

export const INVOICES_API = {
	list: (params?: {
		search?: string;
		start_date?: dayjs.Dayjs;
		end_date?: dayjs.Dayjs;
		vendor_id: number;
	}): THttpRequestConfig => ({
		url: `operations/vendors/invoices`,
		method: 'GET',
		params,
	}),
};
