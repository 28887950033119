import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, generatePath } from 'react-router-dom';
import { getSelectedCountryIsoTwoCode } from 'store/selectors';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { DESCRIPTIONS_PROPS } from '../configs';
import { ICustomerCariUnlimitedDetailsCardProps } from './types';
import { CariUnlimitedTag } from 'components/CariUnlimitedTag';
import { CustomerCariUnlimitedSubscriptionStatusTag } from './CustomerCariUnlimitedSubscriptionStatusTag';
import { Button, Card, Descriptions, Flex, Space, Typography } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

export const CustomerCariUnlimitedDetailsCard: FC<ICustomerCariUnlimitedDetailsCardProps> = memo(({ details }) => {
	const { t: tCustomerCariUnlimitedDetails } = useTranslation('customers', {
		keyPrefix: 'customer_details.tabs.details.cari_unlimited_card',
	});

	// ! selectors
	const countryIsoTwoCode = useSelector(getSelectedCountryIsoTwoCode);

	const title = (
		<Space>
			<CariUnlimitedTag />
			<Typography.Text style={{ textTransform: 'uppercase' }}>
				{tCustomerCariUnlimitedDetails('customer')}
			</Typography.Text>
		</Space>
	);

	// ! render
	if (!details) {
		return (
			<Card>
				<Flex justify='space-between'>
					{title}
					<CustomerCariUnlimitedSubscriptionStatusTag status={null} />
				</Flex>
			</Card>
		);
	}

	return (
		<Card
			title={title}
			extra={
				<CustomerCariUnlimitedSubscriptionStatusTag
					status={details.status}
					statusDescription={details.status_description}
				/>
			}
		>
			{details && (
				<Descriptions {...DESCRIPTIONS_PROPS}>
					<Descriptions.Item label={tCustomerCariUnlimitedDetails('billing_amount')}>
						{details.billing_amount}
					</Descriptions.Item>

					<Descriptions.Item label={tCustomerCariUnlimitedDetails('next_billing_date')}>
						{details.next_billing_date}
					</Descriptions.Item>

					<Descriptions.Item label={tCustomerCariUnlimitedDetails('plan')}>
						<Flex gap={8}>
							{details.plan.name}
							<Link
								to={generatePath(ABSOLUTE_ROUTES.MANAGEMENT_CARI_UNLIMITED_PLAN_DETAILS, {
									countryIsoTwoCode,
									planId: details.plan.id,
								})}
							>
								<Button
									icon={<EyeOutlined />}
									size='small'
								/>
							</Link>
						</Flex>
					</Descriptions.Item>
				</Descriptions>
			)}
		</Card>
	);
});
