import { THttpRequestConfig, THttpRequestParams } from 'hooks';
import { INewDefaultCategoryFields } from 'types/api';

export const CATEGORY_API = {
	list: (name?: string, params?: THttpRequestParams): THttpRequestConfig => ({
		url: 'operations/categories',
		method: 'GET',
		params: {
			...params,
			name,
		},
	}),

	create: (data: INewDefaultCategoryFields): THttpRequestConfig => ({
		url: 'operations/categories',
		method: 'POST',
		data,
	}),

	update: ({ id, data }: { id: number; data: INewDefaultCategoryFields }): THttpRequestConfig => ({
		url: `operations/categories/${id}`,
		method: 'PUT',
		data,
	}),

	delete: (id: number): THttpRequestConfig => ({
		url: `operations/categories/${id}`,
		method: 'DELETE',
	}),
};
