import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useAuth, useNewHttpClient } from 'hooks';
import { VENDOR_WORK_SHIFTS_API } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { IBranchTiming } from 'types/api';
import { IListResponse, Nullable } from 'types/common';
import { IWorkShiftError } from './types';
import Spinner from 'components/Spinner';
import { IVendorTabProps } from '../../types';
import ApplyToAllModal from './ApplyToAllModal';
import styles from './WorkShiftsEditor.module.css';
import WorkshiftDropdownEditor from './WorkshiftDropdownEditor';
import WorkshiftTableEditor from './WorkshiftTableEditor';
import { MAX_SHIFTS_PER_DAY, emptyScheduleItem } from './config';
import useWorkshift from './useWorkshift';
import { Alert, Button, Col, Flex, Row } from 'antd';

interface IWorkShiftsEditorProps extends IVendorTabProps {
	shiftId: number;
	isEdit?: boolean;
}

export const WorkShiftsEditor: FC<IWorkShiftsEditorProps> = ({ vendorId, shiftId, isEdit = true }) => {
	const { hasPermission } = useAuth();
	const { t: tCommon } = useTranslation('common');
	const { t: tWorkShifts } = useTranslation('vendors', { keyPrefix: 'vendor_details.tabs.working_shifts' });

	const [applyToAllModalOpen, setApplyToAllModalOpen] = useState(false);

	const updateHttpClient = useNewHttpClient<IListResponse<IBranchTiming>>();
	const fetchHttpClient = useNewHttpClient<IListResponse<IBranchTiming>>();

	const isLoading = updateHttpClient.isLoading || fetchHttpClient.isLoading;

	// ! state
	const workShiftController = useWorkshift();
	const [errors, setErrors] = useState<Nullable<string[]>>(null);

	// ! handlers
	const updateTimingsData = (response: IListResponse<IBranchTiming>) => {
		if (!response?.data?.length) return;
		const timingsResp: IBranchTiming[] = response.data;

		workShiftController.setTimingsData((prev) => {
			return prev.map((prevItem) => {
				const responseFound = timingsResp.find((responseItem) => responseItem.day === prevItem.day);
				return {
					...prevItem,
					schedule: responseFound?.schedule || [],
				};
			});
		});
	};

	const onSave = async () => {
		if (workShiftController.timingsData.every(({ schedule }) => schedule.length <= 3)) {
			setErrors(null);

			updateHttpClient.request({
				requestConfig: VENDOR_WORK_SHIFTS_API.postTiming(vendorId, shiftId, {
					timings: workShiftController.timingsData,
				}),
				successCallback: (data) => updateTimingsData(data),
				errorCallback: (error) => {
					if (!axios.isAxiosError(error) && !!error?.data) {
						const workShiftErrors = error.data as IWorkShiftError;

						if (!!workShiftErrors?.errors?.timings) {
							const errorList: string[] = Object.values(workShiftErrors.errors.timings).flatMap(
								(elem) => elem.schedule
							);

							setErrors(errorList);
						}
					}
				},
			});
		} else {
			setErrors([tWorkShifts('errors.more_than_three_workshifts', { limit: MAX_SHIFTS_PER_DAY })]);
		}
	};

	const handleCloseModal = () => {
		workShiftController.setTimesSelectedToAll((prev) => ({
			...prev,
			schedule: [emptyScheduleItem],
		}));
		setApplyToAllModalOpen(false);
	};

	// ! effects
	useEffect(() => {
		const ctrl = new AbortController();

		fetchHttpClient.request(
			{
				requestConfig: VENDOR_WORK_SHIFTS_API.getTiming(vendorId, shiftId),
				successCallback: (data) => updateTimingsData(data),
			},
			ctrl.signal
		);

		// Unsubscribing
		return () => ctrl.abort();
	}, [vendorId, shiftId]); // eslint-disable-line react-hooks/exhaustive-deps

	const buttonsDisabled = isLoading || !hasPermission(APP_PERMISSIONS.vendor.shift.update);

	// ! render
	if (isLoading)
		return (
			<Spinner
				defaultAntdSpinner
				hasOverlay={false}
			/>
		);

	return (
		<>
			<Row gutter={[16, 16]}>
				{errors && (
					<Col span={24}>
						<Alert
							showIcon
							type='error'
							message={tCommon('workshift.invalid')}
							description={errors.map((error, index) => (
								<div key={index}>{error}</div>
							))}
						/>
					</Col>
				)}

				<Col flex='1'>
					<WorkshiftDropdownEditor
						isEdit={isEdit}
						workshiftController={workShiftController}
					/>

					{/* BUTTONS */}
					{isEdit && (
						<Flex
							justify='center'
							gap={8}
						>
							<Button
								onClick={() => setApplyToAllModalOpen(true)}
								disabled={buttonsDisabled}
							>
								{tWorkShifts('apply_to_all_modal.open_btn')}
							</Button>

							<Button
								type='primary'
								className={styles.save_button}
								onClick={onSave}
								disabled={buttonsDisabled}
							>
								{tCommon('action_buttons.save')}
							</Button>
						</Flex>
					)}
				</Col>

				<Col
					flex='1'
					span={18}
				>
					<WorkshiftTableEditor
						isEdit={isEdit}
						workshiftController={workShiftController}
					/>
				</Col>
			</Row>

			<ApplyToAllModal
				open={applyToAllModalOpen}
				selectedAllShift={workShiftController.timesSelectedToAll}
				onClose={handleCloseModal}
				onOk={workShiftController.applyTimingToAll}
				onTimeChange={workShiftController.timeChange}
				onAddScheduleItem={workShiftController.addScheduleItem}
				onRemoveScheduleItem={workShiftController.removeScheduledItem}
			/>
		</>
	);
};
