import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth, useForceRefreshData, useNewHttpClient, useVendor } from 'hooks';
import { transformIPresenceBranchListResponseToTableData } from './helper';
import { VENDOR_PRESENCE_API } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { IBranch, IPatchPresenceRequestData, IPresenceBranchListResponse } from 'types/api';
import { EChoiceOption } from 'types/common';
import { IWebPresenceBranchTableData } from './types';
import LinkButton from 'components/LinkButton';
import TableWrapper from 'components/Table';
import TranslatedLabel from 'components/TranslatedLabel';
import VendorPresenceCard from 'pages/Vendors/components/VendorPresenceCard';
import { EditableField } from './EditableField/EditableField';
import { App, Flex, Switch } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { ShopOutlined } from '@ant-design/icons';

const VendorPresenceTabContent: FC = () => {
	const { vendorId, data } = useVendor();
	const { t: tCommon } = useTranslation();
	const { t: tPresence } = useTranslation('vendors', { keyPrefix: 'presence' });
	const { message } = App.useApp();

	const { forceRefreshData, refreshingData } = useForceRefreshData();
	const { hasPermission } = useAuth();

	const [isEditing, setIsEditing] = useState(false);

	// ! http clients
	const changeHttpClient = useNewHttpClient();

	// ! memos
	const canUpdate = useMemo(() => hasPermission(APP_PERMISSIONS.vendor.presence.update), [hasPermission]);
	const vendorBranchesConfig = useMemo(() => VENDOR_PRESENCE_API.getBranchListPresence(vendorId), [vendorId]);

	// ! handlers
	const onChangeWebStatus = (branchId: number, updateData: IPatchPresenceRequestData) => {
		if (changeHttpClient.isLoading || !data) return;

		changeHttpClient.request({
			requestConfig: VENDOR_PRESENCE_API.updateVendorBranchPresence(vendorId, branchId, updateData),
			successCallback: () => {
				refreshingData();
				setIsEditing(false);
				message.success(tPresence('presence_updated'), 3);
			},
		});
	};

	const onEditSlug = (branchId: IBranch['id'], value: string) => {
		return changeHttpClient
			.request({
				requestConfig: VENDOR_PRESENCE_API.updateVendorBranchPresence(vendorId, branchId, {
					slug: value,
				}),
			})
			.finally(() => {
				refreshingData();
			});
	};

	// ! table
	const BRANCH_LIST_TABLE_COLUMNS: ColumnsType<IWebPresenceBranchTableData> = useMemo(
		() => [
			{
				title: (
					<TranslatedLabel
						nameSpace='vendors'
						i18nKey='presence.table.headers.branch_name'
					/>
				),
				render: (_, record) => {
					return (
						<LinkButton
							label={record.name}
							tooltip={record.name}
							icon={<ShopOutlined />}
							to={`../branches/${record.id}`}
							permissions={[APP_PERMISSIONS.vendor.store.view]}
						/>
					);
				},
			},

			{
				title: (
					<TranslatedLabel
						nameSpace='vendors'
						i18nKey='presence.table.headers.available_on_mobile'
					/>
				),
				width: 160,
				render: (_, record) => (
					<Switch
						checkedChildren={tCommon('on_off_switch.yes')}
						unCheckedChildren={tCommon('on_off_switch.no')}
						checked={record.available_on_mobile === EChoiceOption.YES}
						onChange={(checked) =>
							onChangeWebStatus(record.id, {
								available_on_mobile: checked ? EChoiceOption.YES : EChoiceOption.NO,
							})
						}
						disabled={changeHttpClient.isLoading || isEditing || !canUpdate}
					/>
				),
			},
			{
				title: (
					<TranslatedLabel
						nameSpace='vendors'
						i18nKey='presence.table.headers.available_on_web'
					/>
				),
				width: 150,
				render: (_, record) => (
					<Switch
						checkedChildren={tCommon('on_off_switch.yes')}
						unCheckedChildren={tCommon('on_off_switch.no')}
						checked={record.available_on_web === EChoiceOption.YES}
						onChange={(checked) =>
							onChangeWebStatus(record.id, {
								available_on_web: checked ? EChoiceOption.YES : EChoiceOption.NO,
							})
						}
						disabled={changeHttpClient.isLoading || isEditing || !canUpdate}
					/>
				),
			},
			{
				title: (
					<TranslatedLabel
						nameSpace='vendors'
						i18nKey='presence.table.headers.slug'
					/>
				),
				render: (_, record) => (
					<EditableField
						value={record.slug}
						isLoading={changeHttpClient.isLoading}
						canOpenEdit={!changeHttpClient.isLoading && !isEditing}
						onEdit={setIsEditing}
						onSuccessSubmit={(value) => onEditSlug(record.id, value)}
					/>
				),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[canUpdate, changeHttpClient, isEditing, vendorId]
	);

	// ! render
	return (
		<Flex
			vertical
			gap={16}
		>
			<VendorPresenceCard />

			<TableWrapper<IWebPresenceBranchTableData, IPresenceBranchListResponse>
				defaultControlSizes='middle'
				refetchData={forceRefreshData}
				columns={BRANCH_LIST_TABLE_COLUMNS}
				requestConfig={vendorBranchesConfig}
				transformDataToTableData={transformIPresenceBranchListResponseToTableData}
			/>
		</Flex>
	);
};

export default VendorPresenceTabContent;
