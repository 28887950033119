import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store';
import { getSelectedCountryIsoTwoCode } from 'store/selectors';
import { useIntervalTimer } from 'hooks/useIntervalTimer';
import { transformICompensationRequestToTableData } from './helpers';
import { ICompensationRequestListItem } from 'types/api';
import { ICompensationTableData } from '../types';
import ActionsMenu, { IActionMenuItem, getActionColumnWidth } from 'components/ActionsMenu';
import DataRefreshSwitch from 'components/DataRefreshSwitch';
import TableWrapper from 'components/Table';
import { COMPENSATION_REQUEST_TABLE_REQUEST_CONFIG, COMPENSATION_TABLE_COLUMNS } from './config';
import { ColumnsType } from 'antd/es/table';
import { EyeOutlined } from '@ant-design/icons';

export const COMP_REQ_REFRESH_DATA_TTL = 60000; // milliseconds

const CompensationRequestList: FC = () => {
	const { t: tCompensations } = useTranslation('compensation-requests');

	const { isEnable, intervalUpdate, toggleRefreshEnable } = useIntervalTimer(COMP_REQ_REFRESH_DATA_TTL);

	// ! selectors
	const countryIsoTwoCode = useSelector(getSelectedCountryIsoTwoCode);

	// ! handlers
	const onDataRefreshSwitchChange = (checked: boolean) => toggleRefreshEnable(checked);

	// ! config
	const tableColumns = useMemo<ColumnsType<ICompensationTableData>>(
		() => [
			...COMPENSATION_TABLE_COLUMNS(countryIsoTwoCode),
			{
				key: 'actions',
				title: tCompensations('shared.entity.actions'),
				width: getActionColumnWidth(2),
				fixed: 'right',
				render: (_, record) => {
					const actions: IActionMenuItem[] = [
						{
							title: tCompensations('action_buttons.view_details'),
							icon: <EyeOutlined />,
							type: 'link',
							navigateTo: `${record.id}`,
						},
					];
					return <ActionsMenu actions={actions} />;
				},
			},
		],
		[countryIsoTwoCode, tCompensations]
	);

	// ! render
	return (
		<TableWrapper<ICompensationTableData, ICompensationRequestListItem>
			disableSearch
			scrollX={true}
			columns={tableColumns}
			refetchData={intervalUpdate}
			pageTitle={tCompensations('title')}
			requestConfig={COMPENSATION_REQUEST_TABLE_REQUEST_CONFIG}
			transformDataToTableData={transformICompensationRequestToTableData}
			headerExtraActions={[
				<DataRefreshSwitch
					key='data-refresh-switch'
					loading={false}
					isEnable={isEnable}
					resetTrigger={intervalUpdate}
					refreshTTL={COMP_REQ_REFRESH_DATA_TTL}
					onSwitchChange={onDataRefreshSwitchChange}
				/>,
			]}
		/>
	);
};

export default CompensationRequestList;
