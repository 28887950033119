import { createContext, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { IVerticalContextData, IVerticalProviderProps } from './types';
import { useVerticalService } from './useVerticalService';

const SubVerticalContext = createContext<IVerticalContextData>({} as IVerticalContextData);

const useSubVertical = () => {
	return useContext(SubVerticalContext);
};

const SubVerticalProvider = ({
	children = <Outlet />,
	id,
	verticalIdSearchParameterName = 'subVerticalId',
}: IVerticalProviderProps) => {
	const providerData = useVerticalService({
		id,
		verticalIdSearchParameterName,
	});

	return (
		<SubVerticalContext.Provider value={providerData ?? ({} as IVerticalContextData)}>
			{children}
		</SubVerticalContext.Provider>
	);
};

export default useSubVertical;
export { useSubVertical, SubVerticalProvider };
