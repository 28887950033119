import { THttpRequestConfig, THttpRequestParams } from 'hooks';
import { transformToBEAcceptanceFormat } from 'utils/date';
import {
	EDocumentStatus,
	EDriverAccountStatus,
	ERatingStatus,
	IDriverCitcData,
	IDriverVehicleDetails,
	ISetNewDriverPassPayload,
	IUpdateDriverBankDetailsConfigArgs,
	IUpdateDriverCitcData,
	IUpdateDriverPayload,
} from 'types/api';
import { EOnOffStatus } from 'types/common';
import dayjs from 'dayjs';

export const DRIVER_API = {
	list: (): THttpRequestConfig => ({
		url: 'operations/drivers',
		method: 'GET',
	}),
	// ! CRUD
	get: (id: number): THttpRequestConfig => ({
		url: `operations/drivers/${id}`,
		method: 'GET',
	}),
	update: (id: number, data: IUpdateDriverPayload): THttpRequestConfig => ({
		url: `operations/drivers/${id}`,
		method: 'PATCH',
		data,
	}),
	delete: (id: number): THttpRequestConfig => ({
		url: `operations/drivers/${id}`,
		method: 'DELETE',
	}),

	// ! Orders
	getDriverOrderList: (id: number, params?: THttpRequestParams): THttpRequestConfig => ({
		url: `operations/drivers/${id}/orders`,
		method: 'GET',
		params,
	}),

	// ! Location
	getDriverLocation: (id: number): THttpRequestConfig => ({
		url: `operations/drivers/${id}/location`,
		method: 'GET',
	}),

	// ! Ratings
	getDriverRatings: (): THttpRequestConfig => ({
		url: 'operations/drivers/ratings',
		method: 'GET',
	}),

	patchDriverRatingStatus: (driverId: number, ratingId: number, status: ERatingStatus): THttpRequestConfig => ({
		url: `operations/drivers/${driverId}/ratings/${ratingId}/status`,
		method: 'PATCH',
		data: { status },
	}),

	// ! Status
	getDriverStatusUpdate: (id: number, account_status: EDriverAccountStatus): THttpRequestConfig => ({
		url: `operations/drivers/${id}/status`,
		method: 'PATCH',
		data: { account_status },
	}),

	getDriverCurrentStatusUpdate: (id: number, current_status: EOnOffStatus): THttpRequestConfig => ({
		url: `operations/drivers/${id}/status`,
		method: 'PATCH',
		data: { current_status },
	}),

	// ! Reset Password
	setNewDriverPass: (id: number, data: ISetNewDriverPassPayload): THttpRequestConfig => ({
		url: `operations/drivers/${id}/reset-password`,
		method: 'POST',
		data,
	}),

	// ! Vehicle Details
	getDriverVehicleDetails: (id: number): THttpRequestConfig => ({
		url: `operations/drivers/${id}/vehicle-details`,
		method: 'GET',
	}),

	getDriverVehicleDetailsUpdate: (id: number, data: Partial<IDriverVehicleDetails>): THttpRequestConfig => ({
		url: `operations/drivers/${id}/vehicle-details`,
		method: 'PATCH',
		data,
	}),

	// ! Documents
	getDriverDocuments: (id: number): THttpRequestConfig => ({
		url: `operations/drivers/${id}/documents`,
		method: 'GET',
	}),

	getDriverDocumentsUpdate: (id: number, documentId: number, status: EDocumentStatus): THttpRequestConfig => ({
		url: `operations/drivers/${id}/documents/${documentId}`,
		method: 'PATCH',
		data: { status },
	}),

	getDriverDocumentsDelete: (id: number, documentId: number): THttpRequestConfig => ({
		url: `operations/drivers/${id}/documents/${documentId}`,
		method: 'DELETE',
	}),

	// ! Bank Info
	getDriverBankInfo: (id: number): THttpRequestConfig => ({
		url: `operations/drivers/${id}/bank-info`,
		method: 'GET',
	}),

	updateDriverBankInfo: ({ id, data }: IUpdateDriverBankDetailsConfigArgs): THttpRequestConfig => ({
		url: `operations/drivers/${id}/bank-info`,
		method: 'PATCH',
		data,
	}),

	// ! Clear Driver's Orders
	getClearDriverOrders: (id: number): THttpRequestConfig => ({
		url: `operations/drivers/${id}/clear-orders`,
		method: 'POST',
	}),

	// ! Logs
	getDriverLogs: (id: number, start_date: dayjs.Dayjs, end_date: dayjs.Dayjs): THttpRequestConfig => ({
		url: `operations/drivers/${id}/logs`,
		method: 'GET',
		params: {
			start_date: transformToBEAcceptanceFormat(start_date),
			end_date: transformToBEAcceptanceFormat(end_date),
		},
	}),

	// ! CITC
	getDriverCitcInfo: (id: number): THttpRequestConfig => ({
		url: `operations/drivers/${id}/citc`,
		method: 'GET',
	}),

	createDriverCitcInfo: (id: number, data: IDriverCitcData): THttpRequestConfig => ({
		url: `operations/drivers/${id}/citc`,
		method: 'POST',
		data,
	}),

	updateDriverCitcInfo: (id: number, data: IUpdateDriverCitcData): THttpRequestConfig => ({
		url: `operations/drivers/${id}/citc`,
		method: 'PUT',
		data,
	}),
};
