import { RouteObject } from 'react-router-dom';
import { IRoute } from 'configs/routes';

export const getNavItems = (routes: RouteObject[], parentRoute?: string): IRoute[] => {
	const result = routes.reduce<IRoute[]>((acc, route) => {
		if (route.handle?.showOnNav) {
			const newRoutePath = (parentRoute ? `${parentRoute}/` : '') + route.path;

			const childrenNavRoutes = getNavItems(route.children ?? [], newRoutePath);

			const routeToBePushed: IRoute = {
				path: route.path,
				translationKey: route.handle?.translationKey,
				permissions: route.handle?.permissions,
				icon: route.handle?.icon,
				showOnNav: route.handle?.showOnNav ?? false,
				isNavigateable: route.handle?.isNavigateable,
				defaultListParams: route.handle?.defaultListParams,
				children: childrenNavRoutes.length === 0 ? undefined : childrenNavRoutes,
			};

			if (route.index) {
				routeToBePushed.path = parentRoute + '/';
			} else {
				routeToBePushed.path = newRoutePath;
			}

			acc.push(routeToBePushed);
		}
		return acc;
	}, []);

	return result;
};
