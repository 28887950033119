import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import { useCustomSearchParams, useGlobalConfigs } from 'hooks';
import { THttpRequestParams } from 'hooks';
import { mergeABConfigurations } from './helpers';
import {
	IFeatureVariantCurrentSelection,
	IFeatureVariantFeatureName,
	IFeatureVariantMapConfiguration,
	IFeatureVariantPartition,
} from './types';
import {
	fetchABVersioningServerConfiguration,
	getClientVersionSelectionFromServer,
} from './FeatureVariantTogglingServer/FeatureVariantTogglingServer';

export const FEATURE_VARIANT_LOCAL_STORAGE_KEY = 'ABVersions';

export interface IFeatureVariantConfigData {
	currentFeatureVariantSelection: IFeatureVariantCurrentSelection;
	versionConfiguration: IFeatureVariantMapConfiguration;
	setNewPartitionToFeature: (
		featureName: IFeatureVariantFeatureName,
		newPartition: IFeatureVariantPartition['name']
	) => void;
	clearLocalABs: () => void;
}

const FeatureVariantContext = createContext<IFeatureVariantConfigData>({} as IFeatureVariantConfigData);

const useFeatureVariantContext = () => {
	return useContext(FeatureVariantContext);
};

// NAIVE start implementation for AB configurations
const ABConfigProvider = ({ children }: PropsWithChildren) => {
	const { storage } = useGlobalConfigs();
	const [currentFeatureVariantSelection, setCurrentFeatureVariantSelection] =
		useState<IFeatureVariantCurrentSelection>({});
	const [versionConfiguration] = useState<IFeatureVariantMapConfiguration>(fetchABVersioningServerConfiguration());

	const [, groupParams] = useCustomSearchParams();

	const saveToLocalStorage = (state: IFeatureVariantCurrentSelection) => {
		if (Object.keys(state).length) {
			storage.set(FEATURE_VARIANT_LOCAL_STORAGE_KEY, JSON.stringify(state));
		}
	};

	const clearLocalStorageABs = () => {
		storage.remove(FEATURE_VARIANT_LOCAL_STORAGE_KEY);

		processCurrentABselection();
	};

	const getLocalStorageABs = (): IFeatureVariantCurrentSelection => {
		let localStorageABs: IFeatureVariantCurrentSelection = {};
		try {
			localStorageABs = JSON.parse(storage.get(FEATURE_VARIANT_LOCAL_STORAGE_KEY) ?? '');
		} catch (err) {}

		return localStorageABs;
	};

	const getABSelectionBySearchParams = (
		validServerConfiguration: IFeatureVariantCurrentSelection,
		groupParams: THttpRequestParams
	): IFeatureVariantCurrentSelection => {
		const result: IFeatureVariantCurrentSelection = {};

		Object.keys(validServerConfiguration).forEach((validKey) => {
			if (groupParams[validKey]) {
				result[validKey] = groupParams[validKey].toString();
			}
		});

		return result;
	};

	const processCurrentABselection = async () => {
		const localStorageABs = getLocalStorageABs();
		const serverABs = await getClientVersionSelectionFromServer();

		const paramsABSelection = getABSelectionBySearchParams(serverABs, groupParams);

		const localVersions = mergeABConfigurations(localStorageABs, paramsABSelection);
		saveToLocalStorage(localVersions);

		const merged = mergeABConfigurations(serverABs, localVersions);

		setCurrentFeatureVariantSelection(merged);
	};

	// ! useEffects
	useEffect(() => {
		processCurrentABselection();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// ! handlers
	const setNewPartitionToFeature = (
		featureName: IFeatureVariantFeatureName,
		newPartition: IFeatureVariantPartition['name']
	) => {
		setCurrentFeatureVariantSelection((prev) => {
			const next = { ...prev };

			next[featureName] = newPartition;

			saveToLocalStorage(next);
			return next;
		});
	};

	if (!currentFeatureVariantSelection) return null;

	const data = {
		currentFeatureVariantSelection,
		versionConfiguration,
		setNewPartitionToFeature,
		clearLocalABs: clearLocalStorageABs,
	};

	return <FeatureVariantContext.Provider value={data}>{children}</FeatureVariantContext.Provider>;
};

export default useFeatureVariantContext;
export { useFeatureVariantContext, ABConfigProvider };
