import { IHttpClientData } from 'hooks/useHttpClient/types';
import { clearDispute, updateOrderStatus } from './helper';
import { EOrderDisputedStatus, EOrderStatus } from 'types/api';
import { ISelectOption, Nullable, TEmptyFunction } from 'types/common';
import { EDispatcherOrderCardActionsBtnKeys, EDispatcherTab, IDispatcherTabConfig, TDispatcherTabs } from './types';
import {
	ORDER_DISPATCHED_DELIVERY_STATUS_FILTERS_CONFIG,
	ORDER_PREPARING_DELIVERY_STATUS_FILTERS_CONFIG,
	ORDER_READY_DELIVERY_STATUS_FILTERS_CONFIG,
} from './DeliveryStatusFilters/config';
import { IOrderStatusUpdate } from './OrderStatusUpdateModal';
import { MessageInstance } from 'antd/es/message/interface';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

export const ORDERS_PER_PAGE = '60';

export const REFRESH_DATA_TTL = 30000; // milliseconds

export const DATE_SORT_BUTTONS_CONFIG = [
	{ key: 'newest', icon: <ArrowDownOutlined /> },
	{ key: 'oldest', icon: <ArrowUpOutlined /> },
];

// ! tabs
export const getDispatcherTabKey = (key: string) => EDispatcherTab[key as TDispatcherTabs];

export const DISPATCHER_DEFAULT_ACTIVE_TAB = EDispatcherTab.NEW;

export const DISPATCHER_TAB_CONFIG_SET: Record<EDispatcherTab, IDispatcherTabConfig> = {
	[EDispatcherTab.AWAITING_PAYMENT]: {
		key: EDispatcherTab.AWAITING_PAYMENT,
		labelKey: EDispatcherTab.AWAITING_PAYMENT.toLocaleLowerCase(),
		filter: {
			status: EOrderStatus.AWAITING_PAYMENT,
		},
		extractMetricFn: (metrics) => metrics.awaiting_payment,
	},
	[EDispatcherTab.SCHEDULED]: {
		key: EDispatcherTab.SCHEDULED,
		labelKey: EDispatcherTab.SCHEDULED.toLocaleLowerCase(),
		filter: {
			status: EOrderStatus.SCHEDULED,
		},
		extractMetricFn: (metrics) => metrics.scheduled,
	},
	[EDispatcherTab.NEW]: {
		key: EDispatcherTab.NEW,
		labelKey: EDispatcherTab.NEW.toLocaleLowerCase(),
		filter: {
			status: EOrderStatus.NEW,
		},
		extractMetricFn: (metrics) => metrics.new,
	},
	[EDispatcherTab.PREPARING]: {
		key: EDispatcherTab.PREPARING,
		labelKey: EDispatcherTab.PREPARING.toLocaleLowerCase(),
		filter: {
			status: EOrderStatus.PREPARING,
		},
		extractMetricFn: (metrics) => metrics.preparing,
	},
	[EDispatcherTab.READY_FOR_PICK_UP]: {
		key: EDispatcherTab.READY_FOR_PICK_UP,
		labelKey: EDispatcherTab.READY_FOR_PICK_UP.toLocaleLowerCase(),
		filter: {
			status: EOrderStatus.READY,
		},
		extractMetricFn: (metrics) => metrics.ready.total,
	},
	[EDispatcherTab.DISPATCHED]: {
		key: EDispatcherTab.DISPATCHED,
		labelKey: EDispatcherTab.DISPATCHED.toLocaleLowerCase(),
		filter: {
			status: EOrderStatus.DISPATCHED,
		},
		extractMetricFn: (metrics) => metrics.dispatched.total,
	},
	[EDispatcherTab.DISPUTED]: {
		key: EDispatcherTab.DISPUTED,
		labelKey: EDispatcherTab.DISPUTED.toLocaleLowerCase(),
		filter: {
			dispute_status: [EOrderDisputedStatus.DISPUTED],
		},
		extractMetricFn: (metrics) => metrics.disputed,
	},
	[EDispatcherTab.COMPLETED]: {
		key: EDispatcherTab.COMPLETED,
		labelKey: EDispatcherTab.COMPLETED.toLocaleLowerCase(),
		filter: {
			status: EOrderStatus.COMPLETED,
		},
		extractMetricFn: (metrics) => metrics.completed,
	},
	[EDispatcherTab.CANCELLED]: {
		key: EDispatcherTab.CANCELLED,
		labelKey: EDispatcherTab.CANCELLED.toLocaleLowerCase(),
		filter: {
			status: [EOrderStatus.CANCELLED, EOrderStatus.REJECTED],
		},
		extractMetricFn: (metrics) => metrics.interrupted.cancelled + metrics.interrupted.rejected,
	},
	[EDispatcherTab.FAILED]: {
		key: EDispatcherTab.FAILED,
		labelKey: EDispatcherTab.FAILED.toLocaleLowerCase(),
		filter: {
			status: EOrderStatus.FAILED,
		},
		extractMetricFn: (metrics) => metrics.interrupted.failed,
	},
	[EDispatcherTab.INTERVENTION]: {
		key: EDispatcherTab.INTERVENTION,
		labelKey: EDispatcherTab.INTERVENTION.toLocaleLowerCase(),
		filter: {
			status: EOrderStatus.INTERVENTION,
		},
		extractMetricFn: (metrics) => metrics.intervention,
	},
};

export const DISPATCHER_FILTER_TABS: EDispatcherTab[] = [
	EDispatcherTab.AWAITING_PAYMENT,
	EDispatcherTab.SCHEDULED,
	EDispatcherTab.NEW,
	EDispatcherTab.PREPARING,
	EDispatcherTab.READY_FOR_PICK_UP,
	EDispatcherTab.DISPATCHED,
	EDispatcherTab.DISPUTED,
	EDispatcherTab.COMPLETED,
	EDispatcherTab.CANCELLED,
	EDispatcherTab.FAILED,
	EDispatcherTab.INTERVENTION,
];

export const DELIVERY_STATUS_FILTER_BY_TAB: Record<string, ISelectOption[]> = {
	[EDispatcherTab.NEW]: ORDER_PREPARING_DELIVERY_STATUS_FILTERS_CONFIG,
	[EDispatcherTab.PREPARING]: ORDER_PREPARING_DELIVERY_STATUS_FILTERS_CONFIG,
	[EDispatcherTab.READY_FOR_PICK_UP]: ORDER_READY_DELIVERY_STATUS_FILTERS_CONFIG,
	[EDispatcherTab.DISPATCHED]: ORDER_DISPATCHED_DELIVERY_STATUS_FILTERS_CONFIG,
	[EDispatcherTab.DISPUTED]: ORDER_READY_DELIVERY_STATUS_FILTERS_CONFIG,
};

const DISPATCHER_ORDER_CARD_ACTION_TO_STATUS: Record<EDispatcherOrderCardActionsBtnKeys, Nullable<EOrderStatus>> = {
	[EDispatcherOrderCardActionsBtnKeys.CAN_TRANSITION_TO_NEW]: EOrderStatus.NEW,
	[EDispatcherOrderCardActionsBtnKeys.CAN_PREPARE]: EOrderStatus.PREPARING,
	[EDispatcherOrderCardActionsBtnKeys.CAN_MARK_AS_READY]: EOrderStatus.READY,
	[EDispatcherOrderCardActionsBtnKeys.CAN_ASSIGN_DRIVER]: EOrderStatus.PREPARING,
	[EDispatcherOrderCardActionsBtnKeys.CAN_UNASSIGN_DRIVER]: EOrderStatus.PREPARING,
	[EDispatcherOrderCardActionsBtnKeys.CAN_COMPLETE]: EOrderStatus.COMPLETED,
	[EDispatcherOrderCardActionsBtnKeys.CAN_CANCEL]: EOrderStatus.CANCELLED,
	[EDispatcherOrderCardActionsBtnKeys.CAN_REJECT]: EOrderStatus.REJECTED,
	[EDispatcherOrderCardActionsBtnKeys.CAN_CLEAR_DISPUTE]: null,
};

export const handleOrderActionButtonApiRequest = (
	order: IOrderStatusUpdate,
	comment: string,
	successCb: TEmptyFunction,
	http: IHttpClientData,
	message: MessageInstance
) => {
	switch (order.statusActionKey) {
		case EDispatcherOrderCardActionsBtnKeys.CAN_CLEAR_DISPUTE:
			clearDispute(order.id, comment, successCb, http, message);
			break;

		default:
			if (DISPATCHER_ORDER_CARD_ACTION_TO_STATUS[order.statusActionKey])
				updateOrderStatus(
					order.id,
					{ status: DISPATCHER_ORDER_CARD_ACTION_TO_STATUS[order.statusActionKey] as number, comment },
					successCb,
					http,
					message
				);
			break;
	}
};
