import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAllowedFileFormats } from './helpers';
import { formatSizeToString } from 'utils/formatSize';
import { EFileType, Nullable } from 'types/common';
import { ICommonUploadFileProps } from './types';
import { Button, Space, Typography as T, Upload } from 'antd';
import { RcFile, UploadFile } from 'antd/es/upload/interface';
import { ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons';

const CommonUploadFile: FC<ICommonUploadFileProps> = ({
	fileList,
	setFileList,
	maxFileSize,
	allowedFileTypes,
	translationNameSpace = 'common',
	translationKeyPrefix = 'file',
}) => {
	const { t: tUploadTranslations } = useTranslation(translationNameSpace, {
		keyPrefix: translationKeyPrefix ?? undefined,
	});

	// ! state
	const [errorMsg, setErrorMsg] = useState<Nullable<string>>(null);

	// ! helpers
	const validatingFile = (file: RcFile) => {
		// check file type
		const isAllowedFileType = allowedFileTypes.includes(file.type as EFileType);
		if (!isAllowedFileType) {
			const allowedFormats = getAllowedFileFormats(allowedFileTypes);
			setErrorMsg(tUploadTranslations('validation.file.type', { type: allowedFormats }));
			return false;
		}

		// check file size
		const isFileSizeValid = file.size < maxFileSize;
		if (!isFileSizeValid) {
			const formattedSize = formatSizeToString(maxFileSize);
			setErrorMsg(tUploadTranslations('validation.file.size', { maxFileSize: formattedSize }));
			return false;
		}

		// everything is valid
		setErrorMsg(null);
		setFileList([...fileList, file]);

		// Upload files manually after beforeUpload returns false.
		// Ref: https://ant.design/components/upload/#components-upload-demo-upload-manually
		return false;
	};

	// ! handlers
	const handleRemoveFile = (file: UploadFile) => {
		const index = fileList.indexOf(file);
		const newFileList = fileList.slice();
		newFileList.splice(index, 1);
		setFileList(newFileList);
	};

	// ! render
	return (
		<Upload
			name='common_upload_file'
			listType='picture'
			fileList={fileList}
			onRemove={handleRemoveFile}
			beforeUpload={validatingFile}
		>
			<Space direction='vertical'>
				<Button
					icon={<UploadOutlined />}
					disabled={!!fileList.length}
				>
					{tUploadTranslations('actions.choose_file')}
				</Button>

				{errorMsg && (
					<T.Text type='danger'>
						<Space size={4}>
							<ExclamationCircleOutlined />
							{errorMsg}
						</Space>
					</T.Text>
				)}
			</Space>
		</Upload>
	);
};

export default CommonUploadFile;
