import { FC, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { IListDatesFilterWrapperContext } from './types';
import dayjs from 'dayjs';
import { RangeValue } from 'rc-picker/lib/interface';

const ListDatesFilterWrapper: FC = () => {
	const filterDatesRef = useRef<RangeValue<dayjs.Dayjs>>(null);

	const handleDateChange = (dates: RangeValue<dayjs.Dayjs>) => {
		filterDatesRef.current = dates;
	};

	const outletData: IListDatesFilterWrapperContext = {
		filterDatesRef,
		handleDateChange,
	};

	return <Outlet context={outletData} />;
};

export default ListDatesFilterWrapper;
