import { EStatus } from 'types/common';
import { IArea } from './areas';

export enum EVerticalType {
	DEFAULT = 'default', // Could be parent or child but only the child can have vendors and the parent will have sub-verticals.
	INDEPENDENT = 'independent', // parent non-premium verticals that cannot have children. They can have vendors assigned directly.
	PREMIUM = 'premium', // They must be parent without any sub-vertical. Vendors are assigned to them directly.
}

export enum EVerticalLevel {
	VERTICAL = 'parent',
	SUB_VERTICAL = 'child',
}

// ! interfaces
export interface IVerticalVendor {
	id: number;
	name: string;
}

export interface IVertical {
	id: number;
	name: string;
	name_ar: string;
	internal_name: string;
	type: EVerticalType;
	image: string;
	status: EStatus;
	sort_order: number;
	areas: IArea[];
	associated_sub_verticals?: number[];
	level: EVerticalLevel;

	total_sub_verticals: string;
	total_vendors: string;
}

export interface IGenericVertical {
	id: number;
	name: string;
	name_ar: string;
}

// * CRUD
export interface IVerticalCreatePayload {
	name: string;
	name_ar: string;
	internal_name: string;
	type: EVerticalType;
	level: EVerticalLevel;
	parent_id?: number;
	area_ids: number[];
	sort_order: number;
	status?: EStatus;
}

export interface IVerticalUpdatePayload {
	name: string;
	name_ar: string;
	sort_order: number;
	status?: EStatus;
	area_ids: number[];
	level: EVerticalLevel;
	number_of_columns?: number;
}

export interface IVerticalAddVendorsPayload {
	vendor_ids: number[];
}
