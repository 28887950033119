import { lazy } from 'react';
import { LIST_DEFAULT_PARAMS } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import { DollarOutlined } from '@ant-design/icons';

const RefundList = lazy(() => import('pages/Refunds/RefundList'));
const RefundDetails = lazy(() => import('pages/Refunds/RefundDetails'));

export const REFUNDS_ROUTES: TRouteObject = {
	path: 'refunds',
	element: <ListParamsRouteWrapper key='refunds' />,
	handle: {
		translationKey: 'refunds',
		permissions: [APP_PERMISSIONS.refund.view],
		defaultListParams: LIST_DEFAULT_PARAMS,
		icon: <DollarOutlined />,
		showOnNav: true,
	},
	children: [
		{
			index: true,
			element: <RefundList />,
			handle: {
				translationKey: 'refunds',
				permissions: [APP_PERMISSIONS.refund.view],
				defaultListParams: LIST_DEFAULT_PARAMS,
				icon: <DollarOutlined />,
				showOnNav: false,
			},
		},
		{
			path: ':refundId',
			element: <RefundDetails />,
			handle: {
				translationKey: 'refund_details',
				permissions: [APP_PERMISSIONS.refund.view],
				showOnNav: false,
			},
		},
	],
};
