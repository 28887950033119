import { THttpRequestConfig } from 'hooks';
import {
	ECariPrizeCampaignStatus,
	IAssociateVendors,
	ICariPrizeCampaignCreatePayload,
	ICariPrizeUpdatePayload,
	IRemoveAssociatedVendors,
} from 'pages/CariPrizeCampaign/types';

export const CARI_PRIZE_CAMPAIGN_API = {
	list: (searchParams?: Record<string, undefined | string>): THttpRequestConfig => ({
		url: '/operations/cari-prize-campaign',
		method: 'GET',
		params: searchParams,
	}),

	create: (payload: ICariPrizeCampaignCreatePayload): THttpRequestConfig => ({
		url: '/operations/cari-prize-campaign',
		method: 'POST',
		data: payload,
	}),
	delete: (id: React.Key): THttpRequestConfig => ({
		url: `/operations/cari-prize-campaign/${id}`,
		method: 'DELETE',
	}),
	get: (id: React.Key): THttpRequestConfig => ({
		url: `/operations/cari-prize-campaign/${id}`,
		method: 'GET',
	}),
	patch: (id: React.Key, payload: ICariPrizeUpdatePayload): THttpRequestConfig => ({
		url: `/operations/cari-prize-campaign/${id}`,
		method: 'PATCH',
		data: payload,
	}),
	statusChange: (id: React.Key, status: ECariPrizeCampaignStatus): THttpRequestConfig => ({
		url: `/operations/cari-prize-campaign/${id}/status`,
		method: 'PATCH',
		data: { status },
	}),

	// operations
	associateVendors: (id: React.Key, payload: IAssociateVendors): THttpRequestConfig => ({
		url: `/operations/cari-prize-campaign/${id}/associate-vendors`,
		method: 'POST',
		data: payload,
	}),
	removeVendor: (id: React.Key, payload: IRemoveAssociatedVendors): THttpRequestConfig => ({
		url: `/operations/cari-prize-campaign/${id}/remove-vendors`,
		method: 'POST',
		data: payload,
	}),
	// images
	updateImage: (id: React.Key, data: FormData): THttpRequestConfig => ({
		url: `/operations/cari-prize-campaign/${id}/image`,
		method: 'POST',
		data,
	}),
};
