import { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { To, useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useSelector } from 'store';
import { getAccessibleCountryMap, getSelectedCountryIsoTwoCode } from 'store/selectors';
import { setSelectedCountry } from 'store/slices/settings';
import { useGlobalConfigs } from 'hooks';
import { Spinner } from 'components/Spinner/Spinner';

export const CountryRouteWrapper: FC<PropsWithChildren> = ({ children }) => {
	const dispatcher = useDispatch();
	const location = useLocation();
	const match = useMatch('/:countryIsoTwoCode/*');
	const { storage } = useGlobalConfigs();
	const navigate = useNavigate();

	// ! selectors
	const accessibleCountryMap = useSelector(getAccessibleCountryMap);
	const currentCountryIsoTwoCode = useSelector(getSelectedCountryIsoTwoCode);

	// ! params
	const urlCountryParam = match?.params.countryIsoTwoCode?.toLowerCase() ?? '';

	// ! memos

	const isSameCountryUrlAndState = useMemo(
		() => urlCountryParam === currentCountryIsoTwoCode.toLowerCase(),
		[urlCountryParam, currentCountryIsoTwoCode]
	);

	// ! effects
	useEffect(() => {
		const foundValidUrlCountry =
			accessibleCountryMap[urlCountryParam.toUpperCase()] ??
			accessibleCountryMap[currentCountryIsoTwoCode.toUpperCase()] ??
			Object.values(accessibleCountryMap).at(0);

		if (!isSameCountryUrlAndState && foundValidUrlCountry?.id) {
			dispatcher(setSelectedCountry({ newSelectedCountryId: foundValidUrlCountry.id, storage }));

			const foundValidUrlCountryInLowerCase = foundValidUrlCountry.iso_two_code.toLowerCase();

			if (urlCountryParam !== foundValidUrlCountryInLowerCase) {
				const locale = foundValidUrlCountryInLowerCase;
				const to: To = {
					pathname: `/${locale}${location.pathname}`,
					search: location.search,
				};

				navigate(to);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		accessibleCountryMap,
		currentCountryIsoTwoCode,
		isSameCountryUrlAndState,
		location.pathname,
		location.search,
		urlCountryParam,
	]);

	if (!isSameCountryUrlAndState) {
		return <Spinner />;
	}

	return <>{children}</>;
};
