import { DEFAULT_EMPTY_VALUE_PLACEHOLDER } from 'configs/common';
import { IPresenceBranchListResponse } from 'types/api';
import { IWebPresenceBranchTableData } from './types';

// ! table
export const transformIPresenceBranchListResponseToTableData = (
	branch: IPresenceBranchListResponse
): IWebPresenceBranchTableData => {
	return {
		...branch,
		key: branch.id,
		name: branch.name || DEFAULT_EMPTY_VALUE_PLACEHOLDER,
		name_ar: branch.name_ar || DEFAULT_EMPTY_VALUE_PLACEHOLDER,
	};
};
