import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IBranchTiming, IBranchTimingsSchedule } from 'types/api';
import { TShortWeekDay } from 'types/common';
import {
	IWorkshiftController,
	TApplyScheduleWithConfirmation,
	TAppyTimingToAll,
	TOnAddShiftItem,
	TOnAddShiftItemWithConfirmation,
	TOnRemoveScheduleItem,
	TOnRemoveScheduleItemWithConfirmation,
	TOnTimeChange,
	TOnTimeChangeWithConfitmation,
	TOnToggleSlot,
} from './types';
import { ToStringDate } from './TimingsRange/helpers';
import { IBranchTimingsScheduleConverted } from './WorkshiftTableEditor/types';
import {
	DEFAULT_CLOSE_TIME,
	DEFAULT_CLOSE_TIME_NUMBER,
	DEFAULT_SHIFT_DURATION,
	EMPTY_TIMINGS_DATA,
	MAX_SHIFTS_PER_DAY,
	TIME_SLOTS_IN_MINUTES,
	emptyScheduleItem,
} from './config';

const ALL_DAYS_KEY = 'ALL';

const useWorkshift = (): IWorkshiftController => {
	const [timingsData, setTimingsData] = useState<IBranchTiming[]>(EMPTY_TIMINGS_DATA);

	const [selectedDay, setSelectedDay] = useState<TShortWeekDay>('MON');
	const [timesOfSelectedDay, setTimesOfSelectedDay] = useState<IBranchTiming>(() => {
		return timingsData.filter((item) => item.day === selectedDay)[0];
	});

	const [timesSelectedToAll, setTimesSelectedToAll] = useState<IBranchTiming>({
		day: ALL_DAYS_KEY,
		schedule: [emptyScheduleItem],
	});

	const { t: tWorkShifts } = useTranslation('vendors', { keyPrefix: 'vendor_details.tabs.working_shifts' });

	const splitScheduleToSlots = (schedule: IBranchTimingsScheduleConverted[], shift: number) => {
		let splitSlots: IBranchTimingsScheduleConverted[] = [];

		schedule.forEach((item) => {
			if (item.open_time % shift !== 0) {
				splitSlots.push({
					open_time: item.open_time,
					close_time: Math.round(item.open_time / shift) * shift + shift,
					rounded_open_time: item.open_time,
					rounded_close_time: Math.round(item.open_time / shift) * shift + shift,
				});
			}

			for (let i = 0; i < TIME_SLOTS_IN_MINUTES.length; i++) {
				if (item.open_time <= TIME_SLOTS_IN_MINUTES[i] && TIME_SLOTS_IN_MINUTES[i] < item.close_time) {
					const calcCloseTime =
						item.close_time <= TIME_SLOTS_IN_MINUTES[i] + shift
							? item.close_time
							: TIME_SLOTS_IN_MINUTES[i] + shift;

					splitSlots.push({
						open_time: TIME_SLOTS_IN_MINUTES[i],
						rounded_open_time: TIME_SLOTS_IN_MINUTES[i],
						close_time: calcCloseTime,
						rounded_close_time: calcCloseTime,
					});
				}
			}
		});

		return splitSlots;
	};

	const convertTimeSlotToString = (timeSlot: number, midNightAs24: boolean = false): string => {
		const hours: number = Math.floor(timeSlot / 60);
		const minutes = timeSlot - hours * 60;
		const d = new Date();
		d.setHours(hours, minutes, 0);

		if (midNightAs24) {
			return DEFAULT_CLOSE_TIME;
		}

		return d.toTimeString().slice(0, 5);
	};

	const generateGroupSlots = (openTime: number, closeTime: number, shiftDuration: number): number[] => {
		let slots: number[] = [];
		const aux: number = (closeTime - openTime) / shiftDuration;
		const limit: number = openTime === closeTime ? 1 : aux === 0 ? aux : Math.ceil(aux) + 1;

		for (let i = 0; i < limit; i++) {
			slots.push(openTime + i * shiftDuration);
		}

		return slots;
	};

	const toggleSlot: TOnToggleSlot = (
		dayIndex: number,
		openTimeSlot: number,
		closeTimeSlot: number,
		scheduleInMinutes: IBranchTimingsScheduleConverted[],
		openTimeSlotOpenState: boolean
	) => {
		const shiftDuration = DEFAULT_SHIFT_DURATION;
		let splitSchedule = splitScheduleToSlots(scheduleInMinutes, shiftDuration);
		const selectedSlots = generateGroupSlots(openTimeSlot, closeTimeSlot, DEFAULT_SHIFT_DURATION);

		selectedSlots.forEach((openTimeSlot: number) => {
			const openedPositionIndex: number = splitSchedule.findIndex((item) => item.open_time === openTimeSlot);

			if (!openTimeSlotOpenState) {
				const calcCloseTime = openTimeSlot + shiftDuration;
				splitSchedule.push({
					open_time: openTimeSlot,
					close_time: calcCloseTime,
					rounded_open_time: openTimeSlot,
					rounded_close_time: calcCloseTime,
				});

				splitSchedule.sort((a, b) => a.open_time - b.open_time);
			} else if (openedPositionIndex !== -1) {
				splitSchedule.splice(openedPositionIndex, 1);
			}
		});

		const scheduleGroups = splitSchedule.length !== 0 ? groupSchedule(splitSchedule) : [];

		const stringifyGroupedSchedule = scheduleGroups.map(({ open_time, close_time }) => {
			return {
				open_time: convertTimeSlotToString(open_time, false),
				close_time: convertTimeSlotToString(close_time, close_time === DEFAULT_CLOSE_TIME_NUMBER),
			};
		});

		if (stringifyGroupedSchedule.length > MAX_SHIFTS_PER_DAY) {
			throw new Error(tWorkShifts('errors.more_than_three_workshifts', { limit: MAX_SHIFTS_PER_DAY }));
		}

		setTimingsData((prev) => {
			let temp = [...prev];
			temp[dayIndex].schedule = [];
			temp[dayIndex].schedule = stringifyGroupedSchedule;
			return temp;
		});
	};

	const groupSchedule = (schedule: IBranchTimingsScheduleConverted[]): IBranchTimingsScheduleConverted[] => {
		let groupedSchedule: IBranchTimingsScheduleConverted[] = [];
		groupedSchedule[0] = schedule[0];

		for (let i = 1; i < schedule.length; i++) {
			const matchedGroupIndex = groupedSchedule.findIndex(
				({ close_time }) => close_time === schedule[i].open_time || close_time === schedule[i].close_time
			);

			if (matchedGroupIndex === -1) {
				groupedSchedule.push(schedule[i]);
			} else {
				groupedSchedule[matchedGroupIndex].close_time = schedule[i].close_time;
			}
		}

		return groupedSchedule;
	};

	const removeScheduledItem: TOnRemoveScheduleItem = (day, index) => {
		if (day === ALL_DAYS_KEY) {
			setTimesSelectedToAll((prev) => {
				const schedule = prev.schedule.filter((_, scheduleIndex) => {
					return scheduleIndex !== index;
				});

				return {
					...prev,
					schedule,
				};
			});
		} else {
			setTimingsData((prev) =>
				prev.map((dayItem) => {
					if (dayItem.day !== day) return dayItem;

					const schedule: IBranchTimingsSchedule[] = dayItem.schedule.filter((item, scheduleIndex) => {
						return scheduleIndex !== index;
					});

					return {
						...dayItem,
						schedule,
					};
				})
			);
		}
	};

	const addScheduleItem: TOnAddShiftItem = (day) => {
		if (day === ALL_DAYS_KEY) {
			setTimesSelectedToAll((prev) => {
				return {
					...prev,
					schedule: [...prev.schedule, emptyScheduleItem],
				};
			});
		} else {
			setTimingsData((prev) =>
				prev.map((dataItem) => {
					if (dataItem.day === day) {
						return {
							...dataItem,
							schedule: [...dataItem.schedule, emptyScheduleItem],
						};
					}
					return dataItem;
				})
			);
		}
	};

	const timeChange: TOnTimeChange = (day, index, timeType, timeValue) => {
		if (day === ALL_DAYS_KEY) {
			setTimesSelectedToAll((prev) => {
				const schedule = prev.schedule.map((scheduleItem, scheduleIndex) => {
					if (scheduleIndex !== index) return scheduleItem;

					return {
						...scheduleItem,
						[timeType]: ToStringDate(timeValue, timeType),
					};
				});

				return {
					...prev,
					schedule,
				};
			});
		} else {
			setTimingsData((prev) =>
				prev.map((dayItem) => {
					if (dayItem.day !== day) return dayItem;

					const schedule = dayItem.schedule.map((scheduleItem, scheduleIndex) => {
						if (scheduleIndex !== index) return scheduleItem;

						return {
							...scheduleItem,
							[timeType]: ToStringDate(timeValue, timeType),
						};
					});

					return {
						...dayItem,
						schedule,
					};
				})
			);
		}
	};

	// with confirmation
	const applyTimingToAll: TAppyTimingToAll = () => {
		setTimingsData((prev) =>
			prev.map((dayItem) => {
				if (!dayItem.schedule.length) {
					dayItem.schedule = [
						{
							...emptyScheduleItem,
						},
					];
				}

				const schedule = [...timesSelectedToAll.schedule];

				return {
					...dayItem,
					schedule,
				};
			})
		);
	};

	const removeScheduledItemWithConfirmation: TOnRemoveScheduleItemWithConfirmation = (index) => {
		setTimesOfSelectedDay((prev) => {
			const filteredScheduled = prev.schedule.filter((_, scheduleIndex) => {
				return scheduleIndex !== index;
			});

			return {
				day: selectedDay,
				schedule: filteredScheduled,
			};
		});
	};

	const addScheduleItemWithConfirmation: TOnAddShiftItemWithConfirmation = () => {
		setTimesOfSelectedDay((prev) => {
			prev.schedule.push(emptyScheduleItem);
			return {
				...prev,
			};
		});
	};

	const timeChangeWithConfirmation: TOnTimeChangeWithConfitmation = (index, timeType, timeValue) => {
		setTimesOfSelectedDay((prev) => {
			const schedule = prev.schedule.map((scheduleItem, scheduleIndex) => {
				if (scheduleIndex !== index) return scheduleItem;

				return {
					...scheduleItem,
					[timeType]: ToStringDate(timeValue, timeType),
				};
			});

			return {
				...prev,
				schedule,
			};
		});
	};

	const applyScheduleWithConfirmation: TApplyScheduleWithConfirmation = () => {
		setTimingsData((prev) => {
			return prev.map((item) => {
				if (item.day !== timesOfSelectedDay.day) return item;

				return {
					...item,
					schedule: timesOfSelectedDay.schedule,
				};
			});
		});
	};

	useEffect(() => {
		setTimesOfSelectedDay(timingsData.filter((item) => item.day === selectedDay)[0]);
	}, [timingsData, selectedDay]);

	return {
		// getters
		timingsData,
		timesSelectedToAll,
		timesOfSelectedDay,

		selectedDay,
		//setters
		setSelectedDay,
		setTimingsData,
		setTimesSelectedToAll,
		setTimesOfSelectedDay,
		toggleSlot,
		removeScheduledItem,
		addScheduleItem,
		timeChange,
		applyTimingToAll,

		removeScheduledItemWithConfirmation,
		addScheduleItemWithConfirmation,
		timeChangeWithConfirmation,
		applyScheduleWithConfirmation,
	};
};

export default useWorkshift;
