import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNewHttpClient } from 'hooks';
import { isNumber } from 'utils/validationUtils/isNumber';
import { VERTICALS_API } from 'configs/api';
import { IVertical, IVerticalUpdatePayload } from 'types/api';
import { IListResponse, Nullable } from 'types/common';
import { IVerticalContextData, IVerticalProviderProps } from './types';
import { IVerticalVendorTableData } from '../ManageVerticalVendors/types';

export const useVerticalService = ({
	id: idArg,
	verticalIdSearchParameterName = 'verticalId',
}: IVerticalProviderProps): Nullable<IVerticalContextData> => {
	const params = useParams();

	const idParam = params[verticalIdSearchParameterName];

	const id: string = idArg ?? idParam ?? '';

	const [data, setData] = useState<Nullable<IVertical>>(null);

	const fetchHttpCLient = useNewHttpClient<IVertical>();
	const editHttpCLient = useNewHttpClient<IVertical>();
	const associatedVendorsHttpCLient = useNewHttpClient<IListResponse<IVerticalVendorTableData>>();

	const fetchData = () =>
		fetchHttpCLient.request({
			requestConfig: VERTICALS_API.read(+id),
			successCallback: (response) => {
				setData(response);
			},
		});

	const editVertical = (data: Partial<IVerticalUpdatePayload>) =>
		editHttpCLient.request({
			requestConfig: VERTICALS_API.update(+id, data),
			successCallback: (response) => {
				setData(response);
			},
		});

	const fetchAssociatedVendors = () =>
		associatedVendorsHttpCLient.request({ requestConfig: VERTICALS_API.getVendors(id) });

	useEffect(() => {
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!id || isNumber(id)) return null;

	const providerData: IVerticalContextData = {
		id: +id,
		data,
		loading: fetchHttpCLient.isLoading,
		error: fetchHttpCLient.error,

		setData,
		fetchData,
		editVertical,

		// associatedVendors
		associatedVendors: associatedVendorsHttpCLient.response,
		fetchAssociatedVendors,
		associatedLoading: associatedVendorsHttpCLient.isLoading,
	};

	return providerData;
};
