import { CSSProperties, FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { THttpRequestConfig } from 'hooks';
import { VENDOR_BRANCH_IMAGE_BANNER_API } from 'configs/api';
import { TBranchImg } from './types';
import { Button, Tooltip } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

export const CARD_IMG_BODY_STYLES: CSSProperties = {
	height: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
};

export const BRANCH_IMG_DELETE_CONFIG: Record<TBranchImg, (id: number, branchId: number) => THttpRequestConfig> = {
	image: VENDOR_BRANCH_IMAGE_BANNER_API.deleteImage,
	banner: VENDOR_BRANCH_IMAGE_BANNER_API.deleteBanner,
};

export const DeleteImgBtn: FC<{ type: TBranchImg; onDelete: (type: TBranchImg) => void; disabled?: boolean }> = memo(
	({ type, onDelete, disabled }) => {
		const { t: tCommon } = useTranslation('common');

		// ! render
		return (
			<Tooltip title={tCommon('action_buttons.delete')}>
				<Button
					size='small'
					icon={<DeleteOutlined />}
					onClick={() => onDelete(type)}
					disabled={disabled}
				/>
			</Tooltip>
		);
	}
);
