import { FC } from 'react';
import TranslatedLabel from 'components/TranslatedLabel';
import ErrorPage from 'pages/ErrorPage';

const ErrorPage403: FC = () => {
	return (
		<ErrorPage
			status='403'
			title={
				<TranslatedLabel
					nameSpace='error-page'
					i18nKey='forbidden.title'
				/>
			}
			subTitle={
				<TranslatedLabel
					nameSpace='error-page'
					i18nKey='forbidden.subtitle'
				/>
			}
		/>
	);
};

export default ErrorPage403;
