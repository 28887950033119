import { Property } from 'csstype';
import { WithRequired } from 'types/common';
import { MapToken } from 'antd/lib/theme/interface';

// ! types
export type TBrand = 'getdukan' | 'getcari';

interface IThemesColors {
	'--info-color': Property.Color;
	'--font-color': Property.Color;
	'--border-color': Property.Color;
	'--background-color': Property.Color;
	'--error-text-color': Property.Color;
	'--error-background-color': Property.Color;

	/* GREY SCALE */
	'--grey-scale-1': Property.Color;
	'--grey-scale-2': Property.Color;
	'--grey-scale-3': Property.Color;
	'--grey-scale-4': Property.Color;
	'--grey-scale-7': Property.Color;

	'--rgb-black': Property.Color;
	'--dark-grey-scale-6': Property.Color;

	'--spinner-background': Property.Color;
	'--layout-background': Property.Color;

	'--live-tracking-filters-scrollbar': Property.Color;

	'--green-scale-5': Property.Color;
	'--green-scale-6': Property.Color;
	'--green-scale-7': Property.Color;

	'--cyan-scale-5': Property.Color;
	'--cyan-scale-6': Property.Color;
	'--cyan-scale-7': Property.Color;

	'--light-cyan-scale-5': Property.Color;
	'--light-cyan-scale-6': Property.Color;
	'--light-cyan-scale-7': Property.Color;

	'--purple-scale-5': Property.Color;
	'--purple-scale-6': Property.Color;
	'--purple-scale-7': Property.Color;

	//-- ANTD 4.0
	//-- ANTD color generator https://ant.design/docs/spec/colors
	'--ant-primary-color': Property.Color;
	'--ant-primary-1': Property.Color;
	'--ant-primary-2': Property.Color;
	'--ant-primary-7': Property.Color;
	'--ant-warning-color': Property.Color;
	'--ant-error-color': Property.Color;

	'--ant-green-1': Property.Color;
	'--ant-green-3': Property.Color;
	'--ant-green-7': Property.Color;

	'--ant-red-1': Property.Color;
	'--ant-red-2': Property.Color;
	'--ant-red-3': Property.Color;
	'--ant-red-4': Property.Color;
	'--ant-red-5': Property.Color;
	'--ant-red-6': Property.Color;
	'--ant-red-7': Property.Color;

	'--ant-purple-1': Property.Color;
	'--ant-purple-3': Property.Color;
	'--ant-purple-7': Property.Color;

	'--ant-gold-1': Property.Color;
	'--ant-gold-3': Property.Color;
	'--ant-gold-7': Property.Color;

	'--ant-orange-1': Property.Color;
	'--ant-orange-2': Property.Color;
	'--ant-orange-3': Property.Color;
	'--ant-orange-4': Property.Color;
	'--ant-orange-5': Property.Color;
	'--ant-orange-6': Property.Color;
	'--ant-orange-7': Property.Color;

	'--ant-cyan-1': Property.Color;
	'--ant-cyan-3': Property.Color;
	'--ant-cyan-7': Property.Color;

	'--ant-blue-1': Property.Color;
	'--ant-blue-3': Property.Color;
	'--ant-blue-7': Property.Color;

	'--ant-geekblue-1': Property.Color;
	'--ant-geekblue-3': Property.Color;
	'--ant-geekblue-7': Property.Color;
}

// ! const
export const DEFAULT_TOKENS: Partial<MapToken> = {
	fontFamily: 'Jost',
	colorText: '#3b424b',
};

const DEFAULT_THEME_COLORS = {
	'--info-color': '#808080',
	'--font-color': '#161616',
	'--border-color': '#d9d9d9',
	'--background-color': '#ffffff',

	'--spinner-background': '#b9c7c9',
	'--layout-background': '#f2f4f7',

	'--live-tracking-filters-scrollbar': '#9b9797',

	/* Error colors */
	'--error-text-color': '#b80101',
	'--error-background-color': '#ffd1d1',

	/* Grey Scale */
	'--grey-scale-1': '#fafafa',
	'--grey-scale-2': '#e1e1e1',
	'--grey-scale-3': '#f5f5f5',
	'--grey-scale-4': '#c8c8c8',
	'--grey-scale-7': '#8c8c8c',
	'--rgb-black': '0, 0, 0', // defined as rgb to be used inside rgba

	/* Dark grey Scale */
	'--dark-grey-scale-6': '#605e5e',

	/* Start Custom scales */
	'--purple-scale-5': '#6e81cc',
	'--purple-scale-6': '#485cc0',
	'--purple-scale-7': '#313f99',

	'--green-scale-5': '#33c47c',
	'--green-scale-6': '#12B76A',
	'--green-scale-7': '#079155',

	'--cyan-scale-5': '#38c7c7',
	'--cyan-scale-6': '#16b5bb',
	'--cyan-scale-7': '#0a8b94',

	'--light-cyan-scale-5': '#97c8cc',
	'--light-cyan-scale-6': '#6eb5be',
	'--light-cyan-scale-7': '#518e99',
	/* End Custom scales */

	// ? ANTD Colors Scales
	/* Green Scale */
	'--ant-green-1': '#f6ffed',
	'--ant-green-3': '#b7eb8f',
	'--ant-green-7': '#389e0d',

	/* Red Scale */
	'--ant-red-1': '#fff1f0',
	'--ant-red-2': '#ffccc7',
	'--ant-red-3': '#ffa39e',
	'--ant-red-4': '#ff7875',
	'--ant-red-5': '#ff4d4f',
	'--ant-red-6': '#f5222d',
	'--ant-red-7': '#cf1322',

	/* Purple Scale */
	'--ant-purple-1': '#f9f0ff',
	'--ant-purple-3': '#d3adf7',
	'--ant-purple-7': '#531dab',

	/* Gold Scale */
	'--ant-gold-1': '#fffbe6',
	'--ant-gold-3': '#ffe58f',
	'--ant-gold-7': '#d48806',

	/* Orange Scale */
	'--ant-orange-1': '#fff7e6',
	'--ant-orange-2': '#ffe7ba',
	'--ant-orange-3': '#ffd591',
	'--ant-orange-4': '#ffc069',
	'--ant-orange-5': '#ffa940',
	'--ant-orange-6': '#fa8c16',
	'--ant-orange-7': '#d46b08',

	/* Cyan Scale */
	'--ant-cyan-1': '#e6fffb',
	'--ant-cyan-3': '#87e8de',
	'--ant-cyan-7': '#08979c',

	/* Blue Scale */
	'--ant-blue-1': '#e6f7ff',
	'--ant-blue-3': '#91d5ff',
	'--ant-blue-7': '#096dd9',

	/* Geekblue Scale */
	'--ant-geekblue-1': '#f0f5ff',
	'--ant-geekblue-3': '#adc6ff',
	'--ant-geekblue-7': '#1d39c4',
};

export const TOKEN_CONFIG_BY_BRAND: Record<
	TBrand,
	WithRequired<Partial<MapToken>, 'colorPrimary' | 'colorWarning'> & IThemesColors
> = {
	getdukan: {
		colorPrimary: '#d81f51',
		colorWarning: '#f7be11',

		'--ant-primary-color': '#d81f51',
		'--ant-primary-1': '#fff0f1',
		'--ant-primary-2': '#ffc7ce',
		'--ant-primary-7': '#b31041',
		'--ant-warning-color': '#f7be11',
		'--ant-error-color': '#ff4d4f',

		...DEFAULT_THEME_COLORS,
	},
	getcari: {
		colorPrimary: '#cc0071',
		colorWarning: '#ffae00',

		'--ant-primary-color': '#cc0071',
		'--ant-primary-1': '#ffe6ef',
		'--ant-primary-2': '#ffa3c9',
		'--ant-primary-7': '#a60061',
		'--ant-warning-color': '#ffae00',
		'--ant-error-color': '#ff4d4f',

		...DEFAULT_THEME_COLORS,
	},
};
