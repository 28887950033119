import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { DEFAULT_TAG_STYLES } from 'configs/common';
import { IDeliveryModeTagProps } from './types';
import { Tag, Tooltip } from 'antd';

export const DeliveryModeTag: FC<IDeliveryModeTagProps> = memo(
	({ deliveryMode, deliveryModeDescription, deliveryModeDescriptionDetailed }) => {
		const { t: tCommonDM } = useTranslation('common', { keyPrefix: 'delivery_mode' });

		if (!deliveryModeDescription?.length && !deliveryMode?.length) return null;

		const tooltipTitle = deliveryModeDescriptionDetailed || tCommonDM('name');
		const tagLabel = deliveryModeDescription || tCommonDM(`labels.${deliveryMode}`);

		// ! render
		return (
			<Tooltip title={tooltipTitle}>
				<Tag
					style={{
						...DEFAULT_TAG_STYLES,
						border: '1px solid rgb(var(--rgb-black))',
						textTransform: 'uppercase',
					}}
				>
					{tagLabel}
				</Tag>
			</Tooltip>
		);
	}
);
