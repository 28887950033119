import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
// import i18n, needs to be bundled :)
import './i18n';
import store from './store';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

// DISABLE consoles outside of development
if (process.env.NODE_ENV !== 'development') {
	console.log = () => {};
	console.debug = () => {};
	console.info = () => {};
	console.warn = () => {};
	console.error = () => {};
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
	<ReduxProvider store={store}>
		<App />
	</ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
