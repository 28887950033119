import { useEffect, useState } from 'react';
import { AxiosRequestConfig } from 'axios';
import { useNewHttpClient } from 'hooks';
import { transformCITCEntryResponseToSelectOption } from './helper';
import { ICITCListResponse } from 'types/api';
import { ISelectOption } from 'types/common';

export const useCitcController = (requestConfig: AxiosRequestConfig) => {
	const [options, setOptions] = useState<ISelectOption[]>([]);

	// ! http client
	const fetchHttpClient = useNewHttpClient<ICITCListResponse>();

	// ! handlers
	const fetchData = () => {
		fetchHttpClient.request({
			requestConfig,
			successCallback: (response) => {
				const formattedOptions = response.data.map(transformCITCEntryResponseToSelectOption);
				setOptions(formattedOptions);
			},
		});
	};

	// ! effects
	useEffect(() => {
		fetchData();
	}, []); // eslint-disable-line

	return { selectOptionList: options, loading: fetchHttpClient.isLoading };
};
