export enum ETabView {
	LIST = 'list',
	CREATE = 'create',
	EDIT = 'edit',
	DETAILS = 'details',
}

export enum EVendorTabsKeys {
	BANK_INFO = 'bank-info',
	BRANCHES = 'branches',
	CATEGORIES = 'categories',
	DEEP_LINK = 'deep-link',
	DETAILS = 'details',
	DOCUMENTS = 'documents',
	MENUS = 'menus',
	PRODUCTS = 'products',
	STAFF = 'staff',
	WORKING_SHIFTS = 'working-shifts',
	INVOICES = 'invoices',
	PRESENCE = 'presence',
}

export interface IVendorTabProps {
	vendorId: number;
}
