import { FC, PropsWithChildren } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { isNumber } from 'utils/validationUtils/isNumber';
import ErrorPage404 from 'pages/ErrorPage404';

export const UrlIdParamValidatorWrapper: FC<PropsWithChildren> = ({ children = <Outlet /> }) => {
	const params = useParams();

	const regexToFindParamThatEndsWithId = new RegExp('Id$');

	const invalidIdParam = Object.keys(params).find(
		(paramKey) => regexToFindParamThatEndsWithId.test(paramKey) && !isNumber(Number(params[paramKey]))
	);

	if (invalidIdParam) {
		return <ErrorPage404 />;
	}

	return <>{children}</>;
};
