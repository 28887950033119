import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, generatePath, useNavigate } from 'react-router-dom';
import { useSelector } from 'store';
import { getSelectedCountryIsoTwoCode, getVendorIntegrationType } from 'store/selectors';
import { getIntegrationTypeSettings } from 'store/selectors/integrations';
import { useBranch } from 'hooks';
import useVendor from 'hooks/useVendor';
import { DEFAULT_EMPTY_VALUE_PLACEHOLDER } from 'configs/common';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { EShiftOrigin } from 'types/api';
import WorkShiftsEditor from '../../../WorkShifts/WorkShiftsEditor';
import { EVendorTabsKeys } from '../../../types';
import styles from './BranchTimingsTabContent.module.css';
import { IntegrationAlert } from './Menus/IntegrationAlert';
import { Alert, Button, Card, Col, Row, Space } from 'antd';

const BranchTimingsTabContent: FC = () => {
	const { vendorId, data: vendorData } = useVendor();
	const { data: branchData } = useBranch();
	const navigate = useNavigate();

	const { t: tIntegrations } = useTranslation('integrations');
	const { t: tVendorTimings } = useTranslation('vendors', {
		keyPrefix: 'vendor_details.tabs.branches.branch_details.tabs.timings',
	});

	// ! selectors
	const countryIsoTwoCode = useSelector(getSelectedCountryIsoTwoCode);
	const vendorDataIntegrationType = useSelector(getVendorIntegrationType);
	const integrationSettings = useSelector(getIntegrationTypeSettings(vendorDataIntegrationType));
	const isWorkShiftIntegrationManaged = vendorData?.info.shift_source === EShiftOrigin.INTEGRATION ?? false;

	// ! render
	return (
		<Row gutter={[16, 16]}>
			{isWorkShiftIntegrationManaged && (
				<Col span={24}>
					<IntegrationAlert
						pageType={EVendorTabsKeys.WORKING_SHIFTS}
						description={
							!branchData?.schedule.shift_id && (
								<Col
									className={styles.no_work_shift_descriptions}
									span={24}
								>
									{tIntegrations('workshift.no_work_shift.warning.description', {
										integration_type: integrationSettings?.name,
									})}
								</Col>
							)
						}
					/>
				</Col>
			)}

			{branchData?.schedule.shift_id && (
				<Col span={24}>
					<Space className='w-100'>
						{tVendorTimings('current_work_shift')}

						{!!branchData.schedule.shift_id && !isWorkShiftIntegrationManaged && (
							<NavLink
								to={generatePath(ABSOLUTE_ROUTES.VENDOR_TAB, {
									countryIsoTwoCode,
									vendorId,
									vendorTab: 'working-shifts',
								})}
							>{`#${branchData.schedule.shift_id}`}</NavLink>
						)}

						{!!branchData.schedule.shift_id &&
							isWorkShiftIntegrationManaged &&
							`#${branchData.schedule.shift_id}`}

						{!branchData.schedule.shift_id && DEFAULT_EMPTY_VALUE_PLACEHOLDER}
					</Space>

					<Card bordered={false}>
						<WorkShiftsEditor
							isEdit={false}
							vendorId={vendorId}
							shiftId={branchData.schedule.shift_id}
						/>
					</Card>
				</Col>
			)}
			{!branchData?.schedule.shift_id && !isWorkShiftIntegrationManaged && (
				<Col>
					<Alert
						showIcon
						type='warning'
						message={tVendorTimings('no_work_shift.warning.title')}
						description={
							<Space size='large'>
								{tVendorTimings('no_work_shift.warning.description')}
								<Button
									onClick={() => {
										navigate(
											generatePath(ABSOLUTE_ROUTES.VENDOR_TAB, {
												countryIsoTwoCode,
												vendorId,
												vendorTab: 'working-shifts',
											})
										);
									}}
								>
									{tVendorTimings('no_work_shift.warning.action')}
								</Button>
							</Space>
						}
					/>
				</Col>
			)}
		</Row>
	);
};

export default BranchTimingsTabContent;
