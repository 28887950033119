import { PropsWithChildren, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNewHttpClient } from 'hooks';
import { DEFAULT_MODAL_PROPS } from 'configs/common';
import { EFileType } from 'types/common';
import { IImgEditModalProps } from './types';
import CommonUploadFile from 'components/CommonUploadFile';
import styles from './ImgEditModal.module.css';
import { App, Flex, Modal, Space, Typography } from 'antd';
import type { RcFile, UploadFile } from 'antd/es/upload/interface';
import { InfoCircleOutlined } from '@ant-design/icons';

// FC<PropsWithChildren<IImgEditModalProps<T>>>

const ImgEditModal = <T,>({
	open,
	title,
	maxImgSize,
	uploadFileDataName,
	httpRequestConfig,
	onCloseModal,
	extraInfo,
	children,
}: PropsWithChildren<IImgEditModalProps<T>>) => {
	const { message } = App.useApp();

	const { t: tCommon } = useTranslation('common');
	const { t: tImage } = useTranslation('common', { keyPrefix: 'image' });

	// ! state
	const [fileList, setFileList] = useState<UploadFile<File>[]>([]);

	// ! http client
	const uploadHttpClient = useNewHttpClient<T>();

	// ! handlers
	const resetFileListAndCloseModal = (refresh = false, data?: T) => {
		setFileList([]);
		onCloseModal(refresh, data);
	};

	const handleUpload = () => {
		const formData = new FormData();
		formData.append(uploadFileDataName, fileList[0] as RcFile);

		uploadHttpClient.request({
			requestConfig: { ...httpRequestConfig(formData), headers: { 'Content-Type': 'multipart/form-data' } },
			successCallback: (response) => {
				message.success(tImage('feedback.success'), 3);
				resetFileListAndCloseModal(true, response);
			},
		});
	};

	// ! render
	return (
		<Modal
			{...DEFAULT_MODAL_PROPS}
			open={open}
			forceRender
			title={title ?? tImage('actions.edit')}
			okText={uploadHttpClient.isLoading ? tImage('uploading') : tCommon('action_buttons.save')}
			onCancel={() => resetFileListAndCloseModal()}
			onOk={handleUpload}
			okButtonProps={{
				loading: uploadHttpClient.isLoading,
				disabled: !fileList.length,
			}}
		>
			<Flex
				gap='large'
				vertical
			>
				{children}
				<div>
					{open && (
						<CommonUploadFile
							fileList={fileList}
							maxFileSize={maxImgSize}
							setFileList={setFileList}
							allowedFileTypes={[EFileType.PNG, EFileType.JPEG]}
							translationKeyPrefix='image'
						/>
					)}

					{extraInfo && (
						<Space className={styles.extra_info_wrapper}>
							<InfoCircleOutlined />
							<Typography.Text className={styles.info}>{extraInfo}</Typography.Text>
						</Space>
					)}
				</div>
			</Flex>
		</Modal>
	);
};

export default memo(ImgEditModal) as typeof ImgEditModal;
