import { lazy } from 'react';
import { LIST_DEFAULT_PARAMS } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import { FlagOutlined } from '@ant-design/icons';

const AreasList = lazy(() => import('pages/Areas/AreasList'));
const AreaDetails = lazy(() => import('pages/Areas/AreaDetails'));
const AddArea = lazy(() => import('pages/Areas/AddArea'));

export const AREAS_ROUTES: TRouteObject = {
	path: 'areas',
	element: <ListParamsRouteWrapper key='areas' />,
	handle: {
		translationKey: 'areas',
		icon: <FlagOutlined />,
		showOnNav: true,
	},
	children: [
		{
			index: true,
			element: <AreasList />,
			handle: {
				defaultListParams: LIST_DEFAULT_PARAMS,
				showOnNav: false,
			},
		},
		{
			path: 'new',
			element: <AddArea />,
			handle: {
				translationKey: 'add_new_area',
				permissions: [APP_PERMISSIONS.areas.add],
				showOnNav: false,
			},
		},
		{
			path: ':areaId',
			children: [
				{
					index: true,
					element: <AreaDetails />,
					handle: {
						translationKey: 'area_details',
						showOnNav: false,
					},
				},
			],
		},
	],
};
