import { THttpRequestConfig } from 'hooks';

export const CITC_API = {
	authoritiesList: (): THttpRequestConfig => ({
		url: 'operations/citc/authorities',
		method: 'GET',
	}),
	regionList: (): THttpRequestConfig => ({
		url: 'operations/citc/regions',
		method: 'GET',
	}),
	categoriesList: (): THttpRequestConfig => ({
		url: 'operations/citc/categories',
		method: 'GET',
	}),
	identityTypesList: (): THttpRequestConfig => ({
		url: 'operations/citc/identity-types',
		method: 'GET',
	}),
	carTypesList: (): THttpRequestConfig => ({
		url: 'operations/citc/car-types',
		method: 'GET',
	}),
	getCitiesList: (region_id: string): THttpRequestConfig => ({
		url: `operations/citc/cities?region_id=${encodeURIComponent(region_id)}`,
		method: 'GET',
	}),
};
