import { ICustomerAddress } from 'types/api';
import { ICustomerAddressListTableData } from './types';

export const transformICustomerAddressToTableData = (address: ICustomerAddress): ICustomerAddressListTableData => {
	const { id, address_description, address_name, address_type, lat, lng, fields } = address;
	return {
		key: id,
		id,
		address_description,
		address_name,
		address_type,
		lat,
		lng,
		fields,
	};
};
