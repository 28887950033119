import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { ECariPrizeCampaignTabKeys } from './CariPrizeCampaignView/configs';
import { CariPrizeCampaignProvider } from './useCariPrizeCampaign';

// ! lazy imports
const CariPrizeCampaignList = lazy(() => import('./CariPrizeCampaignList'));
const CariPrizeCampaignCreate = lazy(() => import('./CariPrizeCampaignCreate'));
const CariPrizeCampaignViewLayout = lazy(() => import('./CariPrizeCampaignView'));
const CariPrizeCampaignViewVendors = lazy(() => import('./CariPrizeCampaignView/tabs/CariPrizeCampaignsViewVendors'));
const CariPrizeCampaignViewSettings = lazy(() => import('./CariPrizeCampaignView/tabs/CariPrizeCampaignViewSettings'));
const CariPrizeCampaignViewDetails = lazy(() => import('./CariPrizeCampaignView/tabs/CariPrizeCampaignViewDetails'));

export const CARI_PRIZE_CAMPAIGN_ROUTES: RouteObject[] = [
	{
		index: true,
		element: <CariPrizeCampaignList />,
	},
	{
		path: 'new',
		element: <CariPrizeCampaignCreate />,
	},
	{
		path: ':campaignId',
		element: (
			<CariPrizeCampaignProvider>
				<CariPrizeCampaignViewLayout />
			</CariPrizeCampaignProvider>
		),
		children: [
			{
				index: true,
				element: (
					<Navigate
						to={`${ECariPrizeCampaignTabKeys.DETAILS}`}
						replace
					/>
				),
			},
			{
				path: ECariPrizeCampaignTabKeys.DETAILS,
				element: <CariPrizeCampaignViewDetails />,
			},
			{
				path: ECariPrizeCampaignTabKeys.SETTINGS,
				element: <CariPrizeCampaignViewSettings />,
			},

			{
				path: ECariPrizeCampaignTabKeys.VENDORS,
				element: <CariPrizeCampaignViewVendors />,
			},
		],
	},
];
