import { FC, PropsWithChildren } from 'react';
import { Outlet, useMatches } from 'react-router-dom';
import { isFeatureAvailableByABVersion, isFeatureAvailableByRouteMatchABVersion } from './helpers';
import { IFeatureVariantCurrentSelection } from '.';
import useFeatureVariantContext from './useFeatureVariant';

export const FeatureVariantWrapper: FC<PropsWithChildren & { mustHaveABs?: IFeatureVariantCurrentSelection }> = ({
	mustHaveABs,
	children = <Outlet />,
}) => {
	const featureVersionContext = useFeatureVariantContext();
	const matches = useMatches();

	const isFeatureAvailableByRouteABVersion = isFeatureAvailableByRouteMatchABVersion(
		featureVersionContext.currentFeatureVariantSelection,
		matches
	);

	const isABvalid =
		(mustHaveABs
			? isFeatureAvailableByABVersion(featureVersionContext.currentFeatureVariantSelection, mustHaveABs)
			: true) && isFeatureAvailableByRouteABVersion;

	const validReturnComponent = <>{children}</>;

	// check if all props are equal in ab current selection
	if (isABvalid) {
		return validReturnComponent;
	}

	return null;
};
