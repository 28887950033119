import { useEffect, useRef, useState } from 'react';
import { AxiosRequestConfig } from 'axios';
import { useAuth, useGlobalConfigs, useHandleErrors } from 'hooks';
import { DEFAULT_USER_INTERACTION_DEBOUNCE, debounce } from 'utils/debounce';
import { SEARCH_DEBOUNCE_TIMER } from 'configs/common';
import { IPermissionConfigItem } from 'configs/permissions';
import { Nullable } from 'types/common';
import { THttpRequestParams } from './useHttpClient';

export function useSearchDataList<IDataType = object>(
	configHttp: (search?: string, params?: THttpRequestParams) => AxiosRequestConfig,
	initialValues?: Nullable<string | string[]>,
	searchRequestOnlyOnDemand?: Nullable<boolean>,
	permissions?: Nullable<IPermissionConfigItem[]>
) {
	const { hasPermission } = useAuth();
	const { http } = useGlobalConfigs();
	const { handleError } = useHandleErrors();

	// ! state
	const [total, setTotal] = useState<number>();
	const [list, setList] = useState<IDataType[]>([]);
	const [loading, setLoading] = useState(false);
	const timerRef = useRef<Nullable<NodeJS.Timer>>(null);

	// ! helpers
	const fetchData = async (config: AxiosRequestConfig): Promise<Nullable<Array<IDataType>>> => {
		try {
			setLoading(true);
			const res = await http(config);
			setTotal(res?.data?.count);
			return res?.data?.data || null;
		} catch (error) {
			handleError(error, true);
			return null;
		} finally {
			setLoading(false);
		}
	};

	// ! handlers
	const onSearch = async (search: string, params?: THttpRequestParams) => {
		if (!loading) setLoading(true);
		if (timerRef.current) clearTimeout(timerRef.current);

		timerRef.current = setTimeout(async () => {
			const requestConfig = configHttp(search, params);

			const updatedList = await fetchData(requestConfig);

			setList(updatedList || []);
			setLoading(false);
		}, SEARCH_DEBOUNCE_TIMER);
	};

	const cleanup = () => {
		setList([]);
		setTotal(undefined);
	};

	// ! effects
	useEffect(() => {
		if (!searchRequestOnlyOnDemand) {
			const canFetch = hasPermission(permissions || []);
			if (!canFetch) return;

			onSearch('', { ids: initialValues ?? [] });
		}
	}, [initialValues, hasPermission]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(
		() => () => {
			if (timerRef.current) clearTimeout(timerRef.current);
		},
		[]
	);

	const debouncedSearch = debounce(onSearch, DEFAULT_USER_INTERACTION_DEBOUNCE);

	// ! result
	return {
		list,
		total,
		loading,
		onSearch: debouncedSearch,
		cleanup,
	};
}
