import { AxiosRequestConfig } from 'axios';
import { THttpRequestConfig } from 'hooks';
import { IBranchesAssociationPayload, IVendorUserListItemResponse, IVendorUserPayload } from 'types/api';

export const VENDOR_STAFF_API = {
	list: (vendorId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/users`,
		method: 'GET',
	}),

	create: (vendorId: number, data: IVendorUserPayload): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/users`,
		method: 'POST',
		data,
	}),

	// * by ID
	update: (
		vendorId: number,
		userId: number,
		data: Partial<IVendorUserPayload> | Pick<IVendorUserListItemResponse, 'status'>
	): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/users/${userId}`,
		method: 'PATCH',
		data,
	}),
	delete: (vendorId: number, userId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/users/${userId}`,
		method: 'DELETE',
	}),
	resetPassword: (vendorId: number, userId: number, password: string): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/users/${userId}/change-password`,
		method: 'POST',
		data: { password },
	}),

	// Associable Branches
	associableBranches: (vendorId: number, userId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/users/${userId}/associable-stores`,
		method: 'GET',
	}),
	associateBranch: (vendorId: number, userId: number, data: IBranchesAssociationPayload): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/users/${userId}/stores`,
		method: 'POST',
		data,
	}),
	getAssociableStaff: (vendorId: number, search?: string): AxiosRequestConfig => ({
		url: `/operations/vendors/${vendorId}/users/associable`,
		method: 'GET',
		params: { search },
	}),
	associateStaff: (vendorId: number, newStaffId: number): AxiosRequestConfig => ({
		url: `/operations/vendors/${vendorId}/users/${newStaffId}/associate`,
		method: 'POST',
	}),
	dissociateStaff: (vendorId: number, staffId: number): AxiosRequestConfig => ({
		url: `/operations/vendors/${vendorId}/users/${staffId}/dissociate`,
		method: 'POST',
	}),
};
