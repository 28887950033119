import { lazy } from 'react';
import { LIST_DEFAULT_PARAMS } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import { UndoOutlined } from '@ant-design/icons';

const CompensationRequestList = lazy(() => import('pages/CompensationRequests/CompensationRequestList'));
const CompensationRequestDetails = lazy(() => import('pages/CompensationRequests/CompensationRequestDetails'));

export const COMPENSATION_REQUESTS_ROUTES: TRouteObject = {
	path: 'compensation-requests',
	element: <ListParamsRouteWrapper key='compensation-requests' />,
	handle: {
		translationKey: 'compensation_requests',
		permissions: [APP_PERMISSIONS.compensation_request.view],
		defaultListParams: LIST_DEFAULT_PARAMS,
		icon: <UndoOutlined />,
		showOnNav: true,
	},
	children: [
		{
			index: true,
			element: <CompensationRequestList />,
			handle: {
				translationKey: 'compensation_requests',
				permissions: [APP_PERMISSIONS.compensation_request.view],
				defaultListParams: LIST_DEFAULT_PARAMS,
				icon: <UndoOutlined />,
				showOnNav: false,
			},
		},
		{
			path: ':compensationRequestId',
			element: <CompensationRequestDetails />,
			handle: {
				translationKey: 'compensation_request_details',
				permissions: [APP_PERMISSIONS.compensation_request.view],
				showOnNav: false,
			},
		},
	],
};
