import { ID_COLUMN_WIDTH } from 'configs/common';
import { ICustomerAddressListTableData } from './types';
import TranslatedLabel from 'components/TranslatedLabel';
import { ColumnsType } from 'antd/es/table';

const translationsKeyPrefix: string = 'customer_details.tabs.addresses.shared';

export const DEFAULT_CUSTOMER_ADDRESS_LIST_COLUMNS: ColumnsType<ICustomerAddressListTableData> = [
	{
		key: 'id',
		title: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey='id'
				extra={{ keyPrefix: translationsKeyPrefix }}
			/>
		),
		dataIndex: 'id',
		width: ID_COLUMN_WIDTH,
	},
	{
		key: 'address_name',
		title: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey='name'
				extra={{ keyPrefix: translationsKeyPrefix }}
			/>
		),
		dataIndex: 'address_name',
	},
	{
		key: 'address_description',
		title: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey='description'
				extra={{ keyPrefix: translationsKeyPrefix }}
			/>
		),
		dataIndex: 'address_description',
	},
	{
		key: 'address_type',
		title: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey='type'
				extra={{ keyPrefix: translationsKeyPrefix }}
			/>
		),
		dataIndex: 'address_type',
	},
];
