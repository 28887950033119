import { FC, ReactNode, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetFormValues, useNewHttpClient, useSearchDataList } from 'hooks';
import {
	getBannerPositionsOptionList,
	getStoresOptionList,
	getVendorsOptionList,
	getVerticalsOptionList,
} from './helpers';
import { BANNERS_API, STORES_API, VENDOR_API, VERTICALS_API } from 'configs/api';
import { DEFAULT_MODAL_PROPS } from 'configs/common';
import { APP_PERMISSIONS } from 'configs/permissions';
import {
	BANNER_INTERACTION_TYPE_OPTIONS,
	BANNER_MOBILE_TARGET_OPTIONS,
	BANNER_TYPE_OPTIONS,
	PROMOTION_AND_SWIMLANE_OPTIONS,
} from './configs';
import {
	EBannerInteractionType,
	EBannerType,
	EBranchStatus,
	EVendorStatus,
	EVerticalType,
	ICreateUpdateBannerPayload,
	IGenericBranch,
	IGenericVendor,
	IGenericVertical,
} from 'types/api';
import { EStatus, IListResponse, ISelectOption } from 'types/common';
import { IAddEditBannerModalProps } from './types';
import { EBannerTypeTabs } from '../../types';
import { App, Form, Input, Modal, Select } from 'antd';
import { useWatch } from 'antd/es/form/Form';

const AddEditBannerModal: FC<IAddEditBannerModalProps> = ({ open, banner, onClose }) => {
	const { message } = App.useApp();
	const { getFormValues } = useGetFormValues();
	const { t: tBanners } = useTranslation('banners');

	const isEditing = !!banner?.id;
	const { bannerType } = useParams<{ bannerType: EBannerType }>();
	const bannerTabSelected = bannerType;

	// * form
	const [form] = Form.useForm<ICreateUpdateBannerPayload>();
	const bannerTypeFormValue = useWatch('type', form);
	const bannerInteractionTypeFormValue = useWatch('interaction_type', form);
	const NAME_MAX_LENGTH = 200;
	const TITLE_MAX_LENGTH = 200;

	// * areas search
	// !! TEMPORARILY HIDDEN !! - @Ricardo requirement
	// const {
	// 	list: areas,
	// 	loading: fetchingAreas,
	// 	onSearch: onAreasSearch,
	// } = useSearchDataList<IGenericArea>(AREAS_API.genericList, null, true, []);

	// * stores search
	const {
		list: stores,
		loading: fetchingStores,
		onSearch: onStoresSearch,
	} = useSearchDataList<IGenericBranch>(
		(search) => STORES_API.genericList(search, { status: EBranchStatus.APPROVED }),
		null,
		true,
		[APP_PERMISSIONS.vendor.store.view]
	);

	// * vendors search
	const {
		list: vendors,
		loading: fetchingVendors,
		onSearch: onVendorsSearch,
	} = useSearchDataList<IGenericVendor>(
		(search) => VENDOR_API.genericList(search, { status: EVendorStatus.APPROVED }),
		null,
		true,
		[APP_PERMISSIONS.vendor.view]
	);

	// * verticals search
	const {
		list: verticals,
		loading: fetchingVerticals,
		onSearch: onVerticalsSearch,
	} = useSearchDataList<IGenericVertical>(
		(search) =>
			VERTICALS_API.genericList(search, {
				type: [EVerticalType.INDEPENDENT, EVerticalType.DEFAULT],
				status: EStatus.ACTIVE,
			}),
		null,
		true,
		[APP_PERMISSIONS.vertical.view]
	);

	// ! http client
	const createHttpClient = useNewHttpClient();
	const editBannerHttpClient = useNewHttpClient();

	const fetchVendorHttpClient = useNewHttpClient<IListResponse<IGenericVendor>>();
	const fetchStoreHttpClient = useNewHttpClient<IListResponse<IGenericBranch>>();
	const fetchVerticalHttpClient = useNewHttpClient<IListResponse<IGenericVertical>>();
	// const fetchAreaHttpClient = useNewHttpClient<IListResponse<IArea>>();

	const isLoading =
		createHttpClient.isLoading ||
		editBannerHttpClient.isLoading ||
		fetchingStores ||
		fetchingVendors ||
		fetchingVerticals;
	// fetchingAreas ||

	const isPromotionalSwimlane = bannerTabSelected === EBannerTypeTabs.PROMOTIONAL_SWIMLANE.toString();

	// ! handlers
	const onAfterCloseModal = () => form.resetFields();

	const onFormSubmit = async () => {
		const formValues = await getFormValues(form);
		if (!formValues) return;

		if (isEditing) {
			return editBannerHttpClient.request({
				requestConfig: BANNERS_API.update(banner.id, formValues),
				successCallback: () => {
					message.success(
						tBanners(
							formValues.type === EBannerType.SWIMLANE
								? 'success_messages.update_swimlane'
								: 'success_messages.update'
						),
						3
					);
					onClose(true);
				},
			});
		} else {
			return createHttpClient.request({
				requestConfig: BANNERS_API.create(formValues),
				successCallback: () => {
					message.success(
						tBanners(
							formValues.type === EBannerType.SWIMLANE
								? 'success_messages.create_swimlane'
								: 'success_messages.create'
						),
						3
					);
					onClose(true);
				},
			});
		}
	};

	// ! effects
	// * fetch the additional needed data (areas, stores, vendors and general settings)
	// TEMPORARILY HIDDEN - @Ricardo requirement
	// useEffect(() => {
	// 	if (!open) return;
	// 	onAreasSearch();
	// }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (!bannerInteractionTypeFormValue) return;

		// fill store || vendor || verticals list
		switch (bannerInteractionTypeFormValue) {
			case EBannerInteractionType.STORE: {
				onStoresSearch();
				break;
			}
			case EBannerInteractionType.VENDOR: {
				onVendorsSearch();
				break;
			}
			case EBannerInteractionType.VERTICAL: {
				onVerticalsSearch();
				break;
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bannerInteractionTypeFormValue]);

	useEffect(() => {
		if (!open) return;

		if (isEditing) {
			// request area // ! TEMPORARILY HIDDEN - @Ricardo requirement
			// if (banner?.area_ids?.length) {
			// 	fetchAreaHttpClient.request({
			// 		requestConfig: AREAS_API.list(undefined, {
			// 			id: banner?.area_ids.map((id) => id.toString()),
			// 		}),
			// 	});
			// }
			// request vendor
			if (banner?.vendor_id) {
				fetchVendorHttpClient.request({
					requestConfig: VENDOR_API.genericList(undefined, { ids: [banner.vendor_id.toString()] }),
				});
			}
			// request store
			if (banner?.store_id) {
				fetchStoreHttpClient.request({
					requestConfig: STORES_API.genericList(undefined, { ids: [banner.store_id.toString()] }),
				});
			}

			// request vertical
			if (banner?.vertical_id) {
				fetchVerticalHttpClient.request({
					requestConfig: VERTICALS_API.genericList(undefined, {
						id: [banner.vertical_id.toString()],
						type: [EVerticalType.INDEPENDENT, EVerticalType.DEFAULT],
						status: EStatus.ACTIVE,
					}),
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, isEditing]);

	// * on edit
	useEffect(() => {
		if (!open || !banner) return;

		// when editing, set the form values with existing banner data
		form.setFieldsValue(banner);

		if (Array.isArray(banner.areas) && banner.areas?.length > 0) {
			form.setFieldValue(
				'area_ids',
				banner.areas?.map((a) => a.id)
			);
		}
	}, [open, banner]); // eslint-disable-line react-hooks/exhaustive-deps

	// * on create
	useEffect(() => {
		if (!open || isEditing || !bannerTabSelected?.length) return;

		const defaultType = isPromotionalSwimlane ? EBannerType.PROMOTIONAL : bannerTabSelected;

		form.setFieldValue('type', defaultType);
	}, [open, banner, bannerTabSelected]); // eslint-disable-line react-hooks/exhaustive-deps

	// ! memos
	// const areasOptionList = useMemo(() => {
	// 	const areasToConcat = fetchAreaHttpClient.response?.data ?? [];

	// 	return getAreasOptionList(areas, areasToConcat);
	// }, [fetchAreaHttpClient.response, areas]);

	const vendorsOptionList = useMemo(() => {
		const vendorToConcat = fetchVendorHttpClient.response?.data ?? [];

		return getVendorsOptionList(vendors, vendorToConcat);
	}, [fetchVendorHttpClient.response, vendors]);

	const storesOptionList = useMemo(() => {
		const storesToConcat = fetchStoreHttpClient.response?.data ?? [];

		return getStoresOptionList(stores, storesToConcat);
	}, [fetchStoreHttpClient.response, stores]);

	const verticalsOptionList = useMemo(() => {
		const verticalsToConcat = fetchVerticalHttpClient.response?.data ?? [];

		return getVerticalsOptionList(verticals, verticalsToConcat);
	}, [fetchVerticalHttpClient.response, verticals]);

	const modalTitle = useMemo(() => {
		return isEditing
			? tBanners(`edit_modal.${banner?.type}_title`, { id: banner?.id })
			: tBanners(`add_banner.${bannerTabSelected}_title`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [banner, bannerTabSelected, isEditing]);

	const selectTypeOptions = useMemo(() => {
		return isPromotionalSwimlane ? PROMOTION_AND_SWIMLANE_OPTIONS : BANNER_TYPE_OPTIONS;
	}, [isPromotionalSwimlane]);

	// ! render
	const OptionalFieldEByInteractionType: Record<EBannerInteractionType, ReactNode> = {
		[EBannerInteractionType.EXTERNAL_LINK]: (
			// Redirection Link
			<Form.Item
				name='redirection_link'
				initialValue=''
				label={tBanners('entity.redirection_link')}
				rules={[{ required: true }, { type: 'string', max: 100 }]}
			>
				<Input placeholder={tBanners('placeholders.redirection_link')} />
			</Form.Item>
		),
		[EBannerInteractionType.INTERNAL_LINK]: (
			// Mobile App Target
			<Form.Item
				name='mobile_app_target'
				label={tBanners('entity.mobile_app_target')}
				rules={[{ required: true }]}
			>
				<Select
					options={BANNER_MOBILE_TARGET_OPTIONS}
					placeholder={tBanners('placeholders.mobile_app_target')}
				/>
			</Form.Item>
		),
		[EBannerInteractionType.INFO]: null,
		[EBannerInteractionType.STORE]: (
			// Store ID
			<Form.Item
				name='store_id'
				label={tBanners('entity.store')}
				rules={[{ required: true }]}
			>
				<Select
					allowClear
					showSearch
					filterOption={false}
					loading={fetchingStores}
					placeholder={tBanners('placeholders.store')}
					options={storesOptionList}
					onSearch={onStoresSearch}
					onSelect={() => onStoresSearch('')}
				/>
			</Form.Item>
		),
		[EBannerInteractionType.VENDOR]: (
			// Vendor ID
			<Form.Item
				name='vendor_id'
				label={tBanners('entity.vendor')}
				rules={[{ required: true }]}
			>
				<Select
					allowClear
					showSearch
					filterOption={false}
					loading={fetchingVendors}
					placeholder={tBanners('placeholders.store')}
					options={vendorsOptionList}
					onSearch={onVendorsSearch}
					onSelect={() => onVendorsSearch('')}
				/>
			</Form.Item>
		),
		[EBannerInteractionType.VERTICAL]:
			bannerType !== EBannerType.HIGHLIGHTED_ACCOUNT ? (
				// Vertical ID
				<Form.Item
					name='vertical_id'
					label={tBanners('entity.vertical')}
					rules={[{ required: true }]}
				>
					<Select
						allowClear
						showSearch
						filterOption={false}
						loading={fetchingVerticals}
						placeholder={tBanners('placeholders.vertical')}
						options={verticalsOptionList}
						onSearch={onVerticalsSearch}
						onSelect={() => onVerticalsSearch('')}
					/>
				</Form.Item>
			) : null,
	};

	const BANNER_POSITION_OPTIONS: Record<EBannerType, ISelectOption[]> = {
		[EBannerType.HIGHLIGHTED_HOME]: getBannerPositionsOptionList(20),
		[EBannerType.HIGHLIGHTED_ACCOUNT]: getBannerPositionsOptionList(20),
		[EBannerType.PROMOTIONAL]: getBannerPositionsOptionList(100),
		[EBannerType.SWIMLANE]: getBannerPositionsOptionList(100),
	};

	const bannerInteractionOptions = useMemo(() => {
		return BANNER_INTERACTION_TYPE_OPTIONS.filter(
			(item) => bannerType !== EBannerType.HIGHLIGHTED_ACCOUNT || item.value !== EBannerInteractionType.VERTICAL
		);
	}, [bannerType]);

	return (
		<Modal
			open={open}
			forceRender
			{...DEFAULT_MODAL_PROPS}
			title={modalTitle}
			confirmLoading={isLoading}
			onOk={onFormSubmit}
			onCancel={() => onClose()}
			afterClose={onAfterCloseModal}
		>
			<Form
				form={form}
				layout='vertical'
				name='add_edit_banner_form'
			>
				{/* Type */}
				<Form.Item
					name='type'
					label={tBanners('entity.type')}
					rules={[{ required: true }]}
					hidden={!isPromotionalSwimlane}
				>
					<Select
						options={selectTypeOptions}
						disabled={isEditing}
					/>
				</Form.Item>

				{/* Names */}
				<Form.Item
					name='name'
					initialValue=''
					label={tBanners('entity.name')}
					rules={[{ required: true }, { type: 'string', max: NAME_MAX_LENGTH }]}
				>
					<Input
						placeholder={tBanners('placeholders.name')}
						maxLength={NAME_MAX_LENGTH}
						showCount
					/>
				</Form.Item>

				<Form.Item
					name='name_ar'
					initialValue=''
					label={tBanners('entity.name_ar')}
					rules={[{ required: true }, { type: 'string', max: NAME_MAX_LENGTH }]}
				>
					<Input
						placeholder={tBanners('placeholders.name_ar')}
						maxLength={NAME_MAX_LENGTH}
						showCount
					/>
				</Form.Item>

				{/* Title */}
				{bannerTypeFormValue === EBannerType.SWIMLANE && (
					<>
						<Form.Item
							name='title'
							initialValue=''
							label={tBanners('entity.title')}
							rules={[{ required: true }, { type: 'string', max: TITLE_MAX_LENGTH }]}
						>
							<Input
								placeholder={tBanners('placeholders.title')}
								maxLength={TITLE_MAX_LENGTH}
								showCount
							/>
						</Form.Item>

						<Form.Item
							name='title_ar'
							initialValue=''
							label={tBanners('entity.title_ar')}
							rules={[{ required: true }, { type: 'string', max: TITLE_MAX_LENGTH }]}
						>
							<Input
								placeholder={tBanners('placeholders.title_ar')}
								maxLength={TITLE_MAX_LENGTH}
								showCount
							/>
						</Form.Item>
					</>
				)}

				{/* Positions */}
				<Form.Item
					name='position'
					label={tBanners('entity.position')}
					rules={[{ required: true }]}
					extra={tBanners('add_banner.info')}
				>
					<Select
						allowClear
						placeholder={tBanners('placeholders.position')}
						options={BANNER_POSITION_OPTIONS[bannerTypeFormValue]}
					/>
				</Form.Item>

				{/* Areas */}
				{/* // !! TEMPORARILY HIDDEN !! - @Ricardo requirement */}
				{/* <Form.Item
					name='area_ids'
					label={tBanners('entity.areas')}
					rules={[{ required: false }]}
				>
					<Select
						allowClear
						showSearch
						mode='multiple'
						filterOption={false}
						loading={fetchingAreas}
						options={areasOptionList}
						placeholder={tBanners('placeholders.areas')}
						onSearch={onAreasSearch}
						onSelect={() => onAreasSearch('')}
					/>
				</Form.Item> */}

				{/* Interaction Type */}
				{bannerTypeFormValue !== EBannerType.SWIMLANE && (
					<>
						<Form.Item
							name='interaction_type'
							label={tBanners('entity.interaction_type')}
							rules={[{ required: true }]}
						>
							<Select
								options={bannerInteractionOptions}
								placeholder={tBanners('placeholders.interaction_type')}
								onChange={() => {
									// reset the optional fields that depend on the interaction type
									form.resetFields([
										'redirection_link',
										'mobile_app_target',
										'store_id',
										'vendor_id',
										'vertical_id',
									]);
								}}
							/>
						</Form.Item>

						{/* Optional Fields (by Interaction Type choice) */}
						{OptionalFieldEByInteractionType[bannerInteractionTypeFormValue]}
					</>
				)}
			</Form>
		</Modal>
	);
};

export default AddEditBannerModal;
