export enum ERatingStatus {
	DISABLED = 'disabled',
	ENABLED = 'enabled',
}

export interface IRating {
	id: number;
	rating: number;
	status: ERatingStatus;
	comment: string;
	order: {
		id: number;
		code: string;
	};
	customer: {
		id: number;
		name: string;
	};
}

export interface IBranchRatingListItemResponse extends IRating {
	store: {
		id: number;
		name: string;
		vendor_id: number;
	};
}

export interface IDriverRatingListItemResponse extends IRating {
	driver: {
		id: number;
		name: string;
	};
}
