import { EButtonColorTypes, IColorSet } from './types';

export const BUTTON_COLOR_SETS: Record<EButtonColorTypes, IColorSet> = {
	[EButtonColorTypes.btn_green]: {
		colorPrimary: 'var(--green-scale-6)',
		colorPrimaryHover: 'var(--green-scale-5)',
		colorPrimaryActive: 'var(--green-scale-7)',
	},
	[EButtonColorTypes.btn_details]: {
		colorPrimary: 'var(--ant-orange-5)',
		colorPrimaryHover: 'var(--ant-orange-4)',
		colorPrimaryActive: 'var(--ant-orange-7)',
	},
	[EButtonColorTypes.btn_purple]: {
		colorPrimary: 'var(--purple-scale-6)',
		colorPrimaryHover: 'var(--purple-scale-5)',
		colorPrimaryActive: 'var(--purple-scale-7)',
	},
	[EButtonColorTypes.btn_cyan]: {
		colorPrimary: 'var(--cyan-scale-6)',
		colorPrimaryHover: 'var(--cyan-scale-5)',
		colorPrimaryActive: 'var(--cyan-scale-7)',
	},
	[EButtonColorTypes.btn_danger]: {
		colorPrimary: 'var(--ant-red-5)',
		colorPrimaryHover: 'var(--ant-red-4)',
		colorPrimaryActive: 'var(--ant-red-7)',
	},
	[EButtonColorTypes.btn_can_clear_dispute]: {
		colorPrimary: 'var(--light-cyan-scale-6)',
		colorPrimaryHover: 'var(--light-cyan-scale-5)',
		colorPrimaryActive: 'var(--light-cyan-scale-7)',
	},
};
