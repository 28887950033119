import { THttpRequestConfig, THttpRequestParams } from 'hooks';
import { ICreateUpdateAreaPayload, IUpdateAreaPolygonPayload } from 'types/api';

export const AREAS_API = {
	list: (name?: string, params?: THttpRequestParams): THttpRequestConfig => ({
		url: 'operations/areas',
		method: 'GET',
		params: {
			name,
			...params,
		},
	}),

	genericList: (name?: string, params?: THttpRequestParams): THttpRequestConfig => ({
		url: 'operations/areas/generic',
		method: 'GET',
		params: { name, ...params },
	}),

	get: (id: React.Key): THttpRequestConfig => ({
		url: `operations/areas/${id}`,
		method: 'GET',
	}),

	create: (data: ICreateUpdateAreaPayload): THttpRequestConfig => ({
		url: 'operations/areas',
		method: 'POST',
		data,
	}),

	update: (id: React.Key, data: ICreateUpdateAreaPayload): THttpRequestConfig => ({
		url: `operations/areas/${id}`,
		method: 'PATCH',
		data,
	}),

	delete: (id: React.Key): THttpRequestConfig => ({
		url: `operations/areas/${id}`,
		method: 'DELETE',
	}),

	updatePolygon: (id: React.Key, data: IUpdateAreaPolygonPayload): THttpRequestConfig => ({
		url: `operations/areas/${id}/polygon`,
		method: 'PATCH',
		data,
	}),

	deletePolygon: (id: React.Key): THttpRequestConfig => ({
		url: `operations/areas/${id}/polygon`,
		method: 'DELETE',
	}),

	// orders
	shrink: (id: number, radius: number): THttpRequestConfig => ({
		url: `operations/areas/${id}/shrink`,
		method: 'POST',
		data: {
			radius,
		},
	}),

	unShrink: (id: number): THttpRequestConfig => ({
		url: `operations/areas/${id}/unshrink`,
		method: 'POST',
	}),

	ordersSuspend: (id: number): THttpRequestConfig => ({
		url: `operations/areas/${id}/suspend-orders`,
		method: 'POST',
	}),

	ordersResume: (id: number): THttpRequestConfig => ({
		url: `operations/areas/${id}/resume-orders`,
		method: 'POST',
	}),
};
