import { createSelector } from '@reduxjs/toolkit';
import { ISelector } from '../types';
import { IPermissionItem } from '../../types/api';
import { Nullable } from '../../types/common';

// ! get permissions list
type AllPermissions = Nullable<IPermissionItem[]>;

const PERMISSIONS_LIST: ISelector<void, AllPermissions> = (state) => state.permissions.permissionsList;

export const selectAllPermissionsList = createSelector([PERMISSIONS_LIST], (list) => list);
