import { Nullable } from 'types/common';
import { TTimeType } from '../types';
import { DEFAULT_CLOSE_TIME, DEFAULT_OPEN_TIME, TIMINGS_DATE_PICKER_FORMAT } from '../config';
import dayjs, { Dayjs } from 'dayjs';

export const reformatDate = (unformatted: string, timeType: TTimeType): Dayjs => {
	const formatted = dayjs(unformatted, TIMINGS_DATE_PICKER_FORMAT);

	if (formatted.isValid()) return formatted;

	return dayjs(timeType === 'open_time' ? DEFAULT_OPEN_TIME : DEFAULT_CLOSE_TIME, TIMINGS_DATE_PICKER_FORMAT);
};

export const ToStringDate = (date: Nullable<dayjs.Dayjs>, timeType: TTimeType) => {
	if (!date || !date.isValid()) {
		const validDate = dayjs(
			timeType === 'open_time' ? DEFAULT_OPEN_TIME : DEFAULT_CLOSE_TIME,
			TIMINGS_DATE_PICKER_FORMAT
		);
		return validDate.format(TIMINGS_DATE_PICKER_FORMAT)?.toString();
	}

	return date.format(TIMINGS_DATE_PICKER_FORMAT).toString();
};
export const disabledHours = (selectedTime: dayjs.Dayjs) => {
	const hours = [];
	const selectedHour = selectedTime.hour();
	for (let i = 0; i < selectedHour; i += 1) hours.push(i);

	return hours;
};

export const disabledMinutes = (selectedTime: dayjs.Dayjs, currentHour: number) => {
	const minutes = [];
	const selectedHour = selectedTime.hour();
	const selectedMinute = selectedTime.minute();

	if (selectedHour === currentHour) {
		for (let i = 0; i < selectedMinute + 1; i += 1) minutes.push(i);
	}

	return minutes;
};
