import { EOrderPaymentMethods, EOrderStatus, ICariUnlimitedPlan, IRoleItem } from 'types/api';
import { EUserTypes, Nullable } from 'types/common';
import { ICountry } from './countries';

// ! Admins
export interface IUserLoginInfo {
	id: number;
	name: string;
	email: string;
	user_type: EOperationsUserType;
	accessible_countries: Array<ICountry['id']>;

	access_token: string;
	refresh_token: string;
}

export interface IUserLogin {
	access_token: string;
	refresh_token: string;
	user: IUserLoginInfo;
}

// ! Customers
export enum ECustomerStatus {
	BLOCKED = 'blocked',
	ACTIVE = 'active',
	DELETED = 'deleted',
}

export enum ECariUnlimitedSubscriptionStatus {
	ACTIVE = 'active',
	EXPIRED = 'expired',
	CANCELLED = 'cancelled',
	FROZEN = 'frozen',
	AWAITING_PAYMENT = 'awaiting_payment',
	PAYMENT_ERROR = 'payment_error',
	// HIDDEN = 'hidden',
}

export interface ICustomerCariUnlimitedSubscription {
	status: ECariUnlimitedSubscriptionStatus;
	status_description: string;
	billing_amount: string;
	next_billing_date: string;
	plan: Pick<ICariUnlimitedPlan, 'id' | 'name'>;
}

export interface ICustomer {
	id: number;
	first_name: string;
	last_name: string;
	email: string;
	phone_number: string;
	country_code: string;
	language: string;
	status: ECustomerStatus;
	created_at: string;
	cari_unlimited_subscription: Nullable<ICustomerCariUnlimitedSubscription>;
}

export interface ICustomerListItemResponse {
	id: ICustomer['id'];
	first_name: ICustomer['first_name'];
	last_name: ICustomer['last_name'];
	email: ICustomer['email'];
	status: ICustomer['status'];
	created_at: ICustomer['created_at'];
	phone_number: ICustomer['phone_number'];
	country_code: ICustomer['country_code'];
}

// * Orders
export interface IOperationsCustomerOrderActions {
	can_refund: boolean;
}
export interface ICustomerOrder {
	id: number;
	info: {
		code: string;
		status: EOrderStatus;
	};
	customer: {
		id: number;
		name: string;
	};
	store: {
		id: number;
		name: string;
		vendor_id: number;
		vendor_name: string;
	};
	payment: {
		amount_payable: number;
		method: EOrderPaymentMethods;
		paid_at: string;
		currency_code: string;
	};
	actions: IOperationsCustomerOrderActions;
}

// * Addresses
export enum ECustomerAddressType {
	APARTMENT = 'apartment',
	HOUSE = 'house',
	OFFICE = 'office',
	OTHER = 'other',
}

export interface ICustomerAddressEntryField {
	key: string;
	type: string;
	value?: string;
	limit: number;
	label: string;
	required: boolean;
	visible: boolean;
}

export interface ICustomerAddress {
	id: number;
	lat: string;
	lng: string;
	address_name?: string;
	address_type: ECustomerAddressType;
	address_description: string;
	fields: ICustomerAddressEntryField[];
}

export interface ICustomerAddressFieldPayload extends Pick<ICustomerAddressEntryField, 'key' | 'value'> {}

export interface ICustomerAddressPayloadData extends Pick<ICustomerAddress, 'address_name' | 'address_type'> {
	latitude: string;
	longitude: string;
	country_iso_code: string;
	fields: ICustomerAddressFieldPayload[];
}

// * Wallet
export type TTransactionModalType = 'credit' | 'debit';

export enum ECustomerWalletTransaction {
	CREDIT = 'credit',
	DEBIT = 'debit',
	DECLINED = 'declined',
	REFUND = 'refund',
	CASHBACK = 'cashback',
}

export interface ICustomerWalletBalance {
	balance: number;
	currency: string;
}

export interface ICustomerWalletTransactionAuthor {
	id: number;
	name: string;
	role: EUserTypes;
}

export interface ICustomerWalletTransaction {
	id: number;
	amount: number;
	comment: string;
	currency: string;
	transaction_date: Date | string;
	transaction_type: ECustomerWalletTransaction;
	author: ICustomerWalletTransactionAuthor;
}

// Debit && Credit
export interface INewDebitCreditForm {
	amount: string;
	comment: string;
}

export interface IPostNewDebitCreditData extends Omit<INewDebitCreditForm, 'amount'> {
	amount: number;
	currency: string;
}

// ! Operation Users
export enum EOperationsUserType {
	SUPER_ADMIN = 'super_admin',
	ADMIN = 'admin',
	REGULAR = 'regular',
}

export interface IOperationUser {
	id: number;
	name: string;
	email: string;
	user_type: EOperationsUserType;
	roles?: IRoleItem[];
}

export interface ICreateOperationUserForm {
	name: string;
	email: string;
	password: string;
}

export interface ICreateOperationUserData extends ICreateOperationUserForm {
	role_ids: number[];
	permission_ids: number[];
}

export interface IUpdateOperationUserDetailsData extends ICreateOperationUserData {}

export interface IUpdateOperationUserRolesItem {
	id: number;
	country_id: number;
}

export interface IUpdateOperationUserRolesPermissionsData {
	permission_ids: number[];
	roles: IUpdateOperationUserRolesItem[];
}
