import { ICustomerWalletTransaction } from 'types/api';
import { ICustomerWalletTransactionTableData } from './types';

// ! data format
export const transformCustomerTransactionsToTableData = (
	transaction: ICustomerWalletTransaction
): ICustomerWalletTransactionTableData => {
	return {
		...transaction,
		key: transaction.id,
		amount: `${transaction.amount} ${transaction.currency}`,
	};
};
