import { FC, ReactNode, useMemo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'hooks';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import { EVendorUserType } from 'types/api';

export const ProtectedAccessByRoleWrapper: FC<{
	children?: ReactNode;
	allowRoles: EVendorUserType[];
}> = ({ allowRoles, children = <Outlet /> }) => {
	const { user } = useAuth();

	const isRouteAccessible = useMemo(() => {
		if (!user) return false;

		return allowRoles.length === 0 || allowRoles.includes((user as any).type);
	}, [allowRoles, user]);

	if (!user) return null;

	if (isRouteAccessible) {
		return <>{children}</>;
	}

	return (
		<Navigate
			to={ABSOLUTE_ROUTES.FORBIDDEN}
			replace
		/>
	);
};
