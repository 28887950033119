import { THttpRequestConfig } from 'hooks';

export const PERMISSIONS_LIST: THttpRequestConfig = {
	url: 'operations/permissions',
	method: 'GET',
};

export const getUserPermissionConfig = (): THttpRequestConfig => ({
	url: 'operations/profile/permissions',
	method: 'GET',
});
