import { THttpRequestConfig, THttpRequestParams } from 'hooks';
import {
	EBranchScheduledOrderSupport,
	EDocumentStatus,
	EIsSubCategory,
	ERatingStatus,
	EShiftOrigin,
	IBranchesAssociationPayload,
	ICITCVendorBranchData,
	ICreateVendorDeepLinkInfo,
	IMenu,
	IMenuAddCategory,
	IMenuAddProduct,
	IMenuCreateRequest,
	IMenuDeleteItemsRequest,
	IMenuEntityAssociationPayload,
	IMenuPatchRequest,
	IMenuUpdateItemsStatusRequest,
	IMigrateVendorPayload,
	INewBranchPayload,
	IOptionGroupsSavePayload,
	IPatchPresenceRequestData,
	IProductDetailsPayload,
	IToggleSnoozeProductPayload,
	IUpdateVendorBankInfo,
	IVariantBulkSavePayload,
	IVendorBranchIntegrationSettingsUpdatePayload,
	IVendorPayload,
	IVendorUpdateCategoryPayload,
	IWorkShiftTiming,
	TUpdateBranchOperationAreaPayload,
	TVendorAddEditCategoryPayload,
} from 'types/api';
import { EOnOffStatus, EStatus } from 'types/common';
import { TemporarySerializedProduct } from 'pages/Vendors/VendorDetails/Tabs/Products/UploadCsvModal/helpers';

// ! IMAGE & BANNER ---
export const VENDOR_IMAGE_BANNER_API = {
	imageUpdate: (vendorId: number, data: FormData): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/image`,
		method: 'POST',
		data,
	}),

	bannerUpdate: (vendorId: number, data: FormData): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/banner`,
		method: 'POST',
		data,
	}),
};

// ! CATEGORIES ---
export const VENDOR_CATEGORIES_API = {
	list: (
		vendorId: number,
		filterBySubCategory: EIsSubCategory = EIsSubCategory.NO,
		name?: string,
		params: THttpRequestParams = {}
	): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/categories`,
		method: 'GET',
		params: {
			name,
			is_sub_category: filterBySubCategory,
			...params,
		},
	}),
	genericList: (
		vendorId: number,
		filterBySubCategory: EIsSubCategory = EIsSubCategory.NO,
		name?: string,
		params?: THttpRequestParams
	): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/categories/generic`,
		method: 'GET',
		params: {
			name,
			is_sub_category: filterBySubCategory,
			...params,
		},
	}),

	create: (vendorId: number, data: TVendorAddEditCategoryPayload): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/categories`,
		method: 'POST',
		data,
	}),
	update: (vendorId: number, { id, ...data }: IVendorUpdateCategoryPayload): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/categories/${id}`,
		method: 'PATCH',
		data,
	}),
	uploadImage: (vendorId: number, categoryId: number, data: FormData): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/categories/${categoryId}/images`,
		method: 'POST',
		data,
	}),
	delete: (vendorId: number, categoryId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/categories/${categoryId}`,
		method: 'DELETE',
	}),
};

// ! PRODUCTS ---
export const VENDOR_PRODUCT_API = {
	fetchList: (vendorId: number, params: THttpRequestParams = {}): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/products`,
		method: 'GET',
		params,
	}),
	createProduct: (vendorId: number, data: IProductDetailsPayload): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/products`,
		method: 'POST',
		data,
	}),
	getProduct: (vendorId: number, productId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/products/${productId}`,
		method: 'GET',
	}),
	patchProduct: (
		vendorId: number,
		productId: number,
		data: Partial<IProductDetailsPayload> | { status: EStatus }
	): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/products/${productId}`,
		method: 'PATCH',
		data,
	}),
	deleteProduct: (vendorId: number, productId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/products/${productId}`,
		method: 'DELETE',
	}),
	updateProductImage: (vendorId: number, productId: number, data: FormData): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/products/${productId}/images`,
		method: 'POST',
		data,
	}),
	getOptionGroupsList:
		(vendorId: number) =>
		(search?: string): THttpRequestConfig => ({
			url: `operations/vendors/${vendorId}/products/option-groups`,
			method: 'GET',
			params: {
				search,
			},
		}),
	bulkSaveVariants: (vendorId: number, productId: number, data: IVariantBulkSavePayload): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/products/${productId}/variants`,
		method: 'POST',
		data,
	}),
	bulkSaveOptionGroups: (
		vendorId: number,
		productId: number,
		data: IOptionGroupsSavePayload
	): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/products/${productId}/option-groups`,
		method: 'POST',
		data,
	}),
	bulkOperations: {
		getBulkOperationInsertProductTemplate: (vendorId: number): THttpRequestConfig => ({
			url: `operations/vendors/${vendorId}/products/products-and-variants/insert-template`,
			method: 'GET',
		}),
		getBulkOperationUpdateProductTemplate: (vendorId: number): THttpRequestConfig => ({
			url: `operations/vendors/${vendorId}/products/products-and-variants/update-template`,
			method: 'GET',
		}),
		getBulkOperationInsertOptionsTemplate: (vendorId: number): THttpRequestConfig => ({
			url: `operations/vendors/${vendorId}/products/products-options/insert-template`,
			method: 'GET',
		}),
		getBulkOperationUpdateOptionsTemplate: (vendorId: number): THttpRequestConfig => ({
			url: `operations/vendors/${vendorId}/products/products-options/update-template`,
			method: 'GET',
		}),
		getBulkOperationInsertProductsConfig: (
			vendorId: string | number,
			data: Record<string, any>
		): THttpRequestConfig => ({
			url: `operations/vendors/${vendorId}/products/products-and-variants/bulk`,
			method: 'POST',
			data,
		}),
		getBulkOperationUpdateProductsConfig: (
			vendorId: string | number,
			data: {
				products: TemporarySerializedProduct[];
			}
		): THttpRequestConfig => ({
			url: `operations/vendors/${vendorId}/products/products-and-variants/bulk`,
			method: 'PATCH',
			data,
		}),
		getBulkOperationBulkInsertProductOptionGroupsAndOptionsConfig: (
			vendorId: string | number,
			data: {
				products: TemporarySerializedProduct[];
			}
		): THttpRequestConfig => ({
			url: `operations/vendors/${vendorId}/products/products-options/bulk`,
			method: 'POST',
			data,
		}),
		getBulkOperationBulkUpdateProductOptionGroupsAndOptionsConfig: (
			vendorId: string | number,
			data: Record<string, any>
		): THttpRequestConfig => ({
			url: `operations/vendors/${vendorId}/products/products-options/bulk`,
			method: 'PATCH',
			data,
		}),
	},
};

export const VENDOR_PRODUCT_SNAPSHOT_API = {
	getProduct: (
		vendorId: number,
		branchId: number,
		menuId: number,
		categoryId: number,
		productId: number,
		subCategoryId?: number
	): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/stores/${branchId}/menus/${menuId}/products/${productId}`,
		params: {
			category_id: categoryId,
			sub_category_id: subCategoryId,
		},
		method: 'GET',
	}),
	patchProduct: (
		vendorId: number,
		branchId: number,
		menuId: number,
		categoryId: number,
		productId: number,
		data: Partial<IProductDetailsPayload> | { status: EStatus },
		subCategoryId?: number
	): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/stores/${branchId}/menus/${menuId}/products/${productId}`,
		params: {
			category_id: categoryId,
			sub_category_id: subCategoryId,
		},
		method: 'PATCH',
		data,
	}),
	resetPrice: (vendorId: number, branchId: number, menuId: number, productId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/stores/${branchId}/menus/${menuId}/products/${productId}/reset-price`,
		method: 'PATCH',
	}),
	bulkSaveVariants: (
		vendorId: number,
		branchId: number,
		menuId: number,
		categoryId: number,
		productId: number,
		data: IVariantBulkSavePayload,
		subCategoryId?: number
	): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/stores/${branchId}/menus/${menuId}/products/${productId}/variants`,
		params: {
			category_id: categoryId,
			sub_category_id: subCategoryId,
		},
		method: 'POST',
		data,
	}),
	bulkSaveOptionGroups: (
		vendorId: number,
		branchId: number,
		menuId: number,
		categoryId: number,
		productId: number,
		data: IOptionGroupsSavePayload,
		subCategoryId?: number
	): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/stores/${branchId}/menus/${menuId}/products/${productId}/option-groups`,
		params: {
			category_id: categoryId,
			sub_category_id: subCategoryId,
		},
		method: 'POST',
		data,
	}),
};

// ! Menus
export const VENDOR_MENU_API = {
	fetchList: (vendorId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/menus`,
		method: 'GET',
	}),

	createMenu: (vendorId: number, data: IMenuCreateRequest): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/menus`,
		method: 'POST',
		data,
	}),
	getMenu: (vendorId: number, menuId: number, search?: string): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/menus/${menuId}`,
		method: 'GET',
		params: { search },
	}),
	patchMenu: (vendorId: number, menuId: number, data: IMenuPatchRequest): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/menus/${menuId}`,
		method: 'PATCH',
		data,
	}),
	deleteMenu: (vendorId: number, menuId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/menus/${menuId}`,
		method: 'DELETE',
	}),

	// stores
	getStores: (vendorId: number, menuId: number, params?: THttpRequestParams): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/menus/${menuId}/associable-stores`,
		method: 'GET',
		params: {
			is_associated: true,
			...params,
		},
	}),
	postStores: (vendorId: number, menuId: number, data: IMenuEntityAssociationPayload): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/menus/${menuId}/stores`,
		method: 'POST',
		data,
	}),
	getAssociableStores: (vendorId: number, menuId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/menus/${menuId}/associable-stores`,
		method: 'GET',
	}),
	getUnassociatedBranches: (vendorId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/menus/unassociated-branches`,
		method: 'GET',
	}),

	// menu entities
	addProduct: (vendorId: number, menuId: number, categoryId: number, data: IMenuAddProduct): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/menus/${menuId}/categories/${categoryId}/products`,
		method: 'POST',
		data,
	}),
	addCategory: (vendorId: number, menuId: number, data: IMenuAddCategory): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/menus/${menuId}/categories`,
		method: 'POST',
		data,
	}),
	sortMenuEntity: (vendorId: number, menuId: number, menu: IMenu): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/menus/${menuId}/sort-order`,
		method: 'PATCH',
		data: menu,
	}),
	deleteTreeNode: (vendorId: number, menuId: number, data: IMenuDeleteItemsRequest): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/menus/${menuId}/delete-items`,
		method: 'POST',
		data,
	}),
	updateTreeNodeStatus: (
		vendorId: number,
		menuId: number,
		data: IMenuUpdateItemsStatusRequest
	): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/menus/${menuId}/items-status`,
		method: 'PATCH',
		data,
	}),
};

export const VENDOR_MENU_SNAPSHOT_API = {
	getMenu: (vendorId: number, branchId: number, menuId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/stores/${branchId}/menus/${menuId}`,
		method: 'GET',
	}),
	updateTreeNodeStatus: (
		vendorId: number,
		branchId: number,
		menuId: number,
		data: IMenuUpdateItemsStatusRequest
	): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/stores/${branchId}/menus/${menuId}/items-status`,
		method: 'PATCH',
		data,
	}),

	toggleSnooze: (
		vendorId: number,
		menuId: number,
		branchId: number,
		productId: number,
		data: IToggleSnoozeProductPayload
	): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/stores/${branchId}/menus/${menuId}/products/${productId}/snooze`,
		method: 'PATCH',
		data,
	}),

	manualSync: (vendorId: number, branchId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/stores/${branchId}/menus/sync`,
		method: 'POST',
	}),
};
// ! Work Schedules
export const VENDOR_WORK_SHIFTS_API = {
	fetchList: (vendorId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/shifts`,
		method: 'GET',
	}),
	get: (vendorId: number, shiftId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/shifts/${shiftId}`,
		method: 'GET',
	}),
	create: (vendorId: number, data: { name: string; name_ar: string }): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/shifts`,
		method: 'POST',
		data,
	}),
	update: (vendorId: number, shiftId: number, data: { name: string; name_ar: string }): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/shifts/${shiftId}`,
		method: 'PATCH',
		data,
	}),
	delete: (vendorId: number, shiftId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/shifts/${shiftId}`,
		method: 'DELETE',
	}),

	// timings
	getTiming: (vendorId: number, shiftId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/shifts/${shiftId}/timings`,
		method: 'GET',
	}),
	postTiming: (vendorId: number, shiftId: number, data: IWorkShiftTiming): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/shifts/${shiftId}/timings`,
		method: 'POST',
		data,
	}),
	resetWorkShiftToIntegration: (vendorId: number, source: EShiftOrigin): THttpRequestConfig => ({
		url: `/operations/vendors/${vendorId}/shifts/management`,
		method: 'POST',
		data: { source },
	}),

	// Branches
	getBranches: (vendorId: number, shiftId: number, params?: THttpRequestParams): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/shifts/${shiftId}/associable-branches`,
		method: 'GET',
		params,
	}),
	getUnassociatedBranches: (vendorId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/shifts/unassociated-branches`,
		method: 'GET',
	}),
	associateToBranches: (
		vendorId: number,
		shiftId: number,
		data: IBranchesAssociationPayload
	): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/shifts/${shiftId}/branches`,
		method: 'POST',
		data,
	}),
};

// ! DEEP LINK ---
export const VENDOR_DEEP_LINK_API = {
	get: (vendorId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/deep-link`,
		method: 'GET',
	}),
	create: (vendorId: number, data: ICreateVendorDeepLinkInfo): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/deep-link`,
		method: 'POST',
		data,
	}),
	imageUpload: (vendorId: number, data: FormData): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/deep-link/image`,
		method: 'POST',
		data,
	}),
	generate: (vendorId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/deep-link/generate`,
		method: 'POST',
	}),
	getAnalytics: (vendorId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/deep-link/analytics`,
		method: 'GET',
	}),
};

// ! DOCUMENTS ---
export const VENDOR_DOCUMENTS_API = {
	get: (vendorId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/documents`,
		method: 'GET',
	}),

	create: (vendorId: number, data: FormData): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/documents/upload`,
		method: 'POST',
		data,
		headers: { 'Content-Type': 'multipart/form-data' },
	}),

	update: (vendorId: number, documentId: number, status: EDocumentStatus): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/documents/${documentId}`,
		method: 'PATCH',
		data: { status },
	}),

	delete: (vendorId: number, documentId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/documents/${documentId}`,
		method: 'DELETE',
	}),
};

// ! BANK DETAILS ---
export const VENDOR_BANK_DETAILS_API = {
	get: (vendorId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/bank-info`,
		method: 'GET',
	}),
	update: (vendorId: number, data: IUpdateVendorBankInfo): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/bank-info`,
		method: 'PATCH',
		data,
	}),
};

// ! PRESENCE ---
export const VENDOR_PRESENCE_API = {
	getVendorPresence: (vendorId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/presence`,
		method: 'GET',
	}),
	updateVendorPresence: (vendorId: number, data: IPatchPresenceRequestData): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/presence`,
		method: 'PATCH',
		data,
	}),
	getBranchListPresence: (vendorId: number, search?: string, params?: THttpRequestParams): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/presence/stores`,
		method: 'GET',
		params: { search, ...params },
	}),
	updateVendorBranchPresence: (
		vendorId: number,
		branchId: number,
		data: IPatchPresenceRequestData
	): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/presence/stores/${branchId}`,
		method: 'PATCH',
		data,
	}),
};

// ! CITC
export const VENDOR_BRANCH_CITC_API = {
	get: (vendorId: number, branchId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/stores/${branchId}/citc`,
		method: 'GET',
	}),
	update: (vendorId: number, branchId: number, data: ICITCVendorBranchData): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/stores/${branchId}/citc`,
		method: 'POST',
		data,
	}),
};
export const VENDOR_BRANCH_IMAGE_BANNER_API = {
	updateImage: (vendorId: number, branchId: number, data: FormData): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/stores/${branchId}/images`,
		method: 'POST',
		data,
	}),

	deleteImage: (vendorId: number, branchId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/stores/${branchId}/images`,
		method: 'DELETE',
	}),

	updateBanner: (vendorId: number, branchId: number, data: FormData): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/stores/${branchId}/banner`,
		method: 'POST',
		data,
	}),

	deleteBanner: (vendorId: number, branchId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/stores/${branchId}/banner`,
		method: 'DELETE',
	}),
};

export const VENDOR_BRANCH_OPERATIONS_AREA_API = {
	get: (vendorId: number, branchId: string | number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/stores/${branchId}/operation-area`,
		method: 'GET',
	}),

	update: (
		vendorId: number,
		branchId: string | number,
		data: TUpdateBranchOperationAreaPayload
	): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/stores/${branchId}/operation-area`,
		method: 'PATCH',
		data,
	}),

	delete: (vendorId: number, branchId: string | number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/stores/${branchId}/operation-area`,
		method: 'DELETE',
	}),
};

// ! BRANCHES ---
export const VENDOR_BRANCH_API = {
	// * CRUD
	list: (vendorId: number, search?: string, params?: THttpRequestParams): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/stores`,
		method: 'GET',
		params: { search, ...params },
	}),
	get: (vendorId: number, branchId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/stores/${branchId}`,
		method: 'GET',
	}),
	create: (vendorId: number, data: INewBranchPayload): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/stores`,
		method: 'POST',
		data,
	}),
	update: (vendorId: number, branchId: number, data: Partial<INewBranchPayload>): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/stores/${branchId}`,
		method: 'PATCH',
		data,
	}),
	delete: (vendorId: number, branchId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/stores/${branchId}`,
		method: 'DELETE',
	}),

	VENDOR_PRODUCT_SNAPSHOT_API,
	VENDOR_MENU_SNAPSHOT_API,
	VENDOR_BRANCH_IMAGE_BANNER_API,
	VENDOR_BRANCH_CITC_API,
	VENDOR_BRANCH_OPERATIONS_AREA_API,

	// -----
	integrations: {
		get: (vendorId: number, branchId: number): THttpRequestConfig => ({
			url: `operations/vendors/${vendorId}/stores/${branchId}/integrations`,
			method: 'GET',
		}),
		patch: (
			vendorId: number,
			branchId: number,
			data: IVendorBranchIntegrationSettingsUpdatePayload
		): THttpRequestConfig => ({
			url: `operations/vendors/${vendorId}/stores/${branchId}/integrations`,
			method: 'PATCH',
			data,
		}),
	},

	// * ON/OFF Status (Bulk Action)
	currentStatusBulkUpdate: (vendorId: number, status: EOnOffStatus): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/stores/current-status`,
		method: 'PATCH',
		data: { status },
	}),

	setBulkScheduleOrderMode: (
		vendorId: number,
		data: { scheduled_order_support: EBranchScheduledOrderSupport; store_ids?: number[] }
	): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/stores/scheduled-order-support`,
		method: 'PATCH',
		data,
	}),

	// * Nearby Drivers
	getNearbyDrivers: (orderId: number): THttpRequestConfig => ({
		url: `operations/orders/${orderId}/nearby-drivers`,
		method: 'GET',
	}),
	patchBranchRatingStatusConfig: (
		vendorId: number,
		branchId: number,
		ratingId: number,
		status: ERatingStatus
	): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/stores/${branchId}/ratings/${ratingId}/status`,
		method: 'PATCH',
		data: { status },
	}),
};

export const VENDOR_API = {
	list: (search?: string, params?: THttpRequestParams): THttpRequestConfig => ({
		url: 'operations/vendors',
		method: 'GET',
		params: { search, ...params },
	}),
	get: (vendorId: number): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}`,
		method: 'GET',
	}),
	create: (data: IVendorPayload): THttpRequestConfig => ({
		url: 'operations/vendors',
		method: 'POST',
		data,
	}),
	update: (vendorId: number, data: Partial<IVendorPayload>): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}`,
		method: 'PATCH',
		data,
	}),

	genericList: (search?: string, params?: THttpRequestParams): THttpRequestConfig => ({
		url: 'operations/vendors/generic',
		method: 'GET',
		params: { search, ...params },
	}),

	// ! others
	VENDOR_IMAGE_BANNER_API,
	VENDOR_CATEGORIES_API,
	VENDOR_PRODUCT_API,
	VENDOR_MENU_API,
	VENDOR_WORK_SHIFTS_API,
	VENDOR_DEEP_LINK_API,
	VENDOR_DOCUMENTS_API,
	VENDOR_BANK_DETAILS_API,
	VENDOR_BRANCH_API,
	VENDOR_PRESENCE_API,

	// * ON/OFF Status all branches (Bulk Action)
	getVendorAllBranchCurrentStatusBulkUpdateConfig: (status: EOnOffStatus): THttpRequestConfig => ({
		url: `operations/vendors/stores/current-status`,
		method: 'PATCH',
		data: { status },
	}),
};

export const INTEGRATIONS_API = {
	migrate: (vendorId: number, data: IMigrateVendorPayload): THttpRequestConfig => ({
		url: `operations/vendors/${vendorId}/integrations/migrate`,
		method: 'POST',
		data,
	}),
};
