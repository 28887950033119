import { THttpRequestConfig } from 'hooks';
import { IAutomaticRefundPayload, ICreateRefundData, IUpdateRefundRequestBody } from 'types/api';

export const REFUNDS_API = {
	list: (): THttpRequestConfig => ({
		url: '/operations/refunds',
		method: 'GET',
	}),

	create: (data: ICreateRefundData): THttpRequestConfig => ({
		url: '/operations/refunds',
		method: 'POST',
		data,
	}),

	getRefundById: (id: string | number): THttpRequestConfig => ({
		url: `/operations/refunds/${id}`,
		method: 'GET',
	}),

	updateCostSource: (id: number, data: IUpdateRefundRequestBody): THttpRequestConfig => ({
		url: `/operations/refunds/${id}/split`,
		method: 'PATCH',
		data,
	}),

	automaticRefund: (data: IAutomaticRefundPayload): THttpRequestConfig => ({
		url: `/operations/refunds/auto`,
		method: 'POST',
		data,
	}),

	rejectRefund: (id: number): THttpRequestConfig => ({
		url: `/operations/refunds/${id}/reject`,
		method: 'PATCH',
	}),
};
