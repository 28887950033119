import { THttpRequestConfig } from 'hooks';
import { ESupportPagesPurpose, ICreateNewSupportPageData, IUpdateNewSupportPageConfig } from 'types/api';

export const SUPPORT_PAGES_API = {
	supportPageList: (purpose?: ESupportPagesPurpose): THttpRequestConfig => ({
		url: 'operations/pages',
		method: 'GET',
		params: {
			purpose,
		},
	}),
	supportPageDetails: (pageId: number): THttpRequestConfig => ({
		url: `operations/pages/${pageId}`,
		method: 'GET',
	}),
	pageTypesList: (purpose?: ESupportPagesPurpose): THttpRequestConfig => ({
		url: 'operations/page-types',
		method: 'GET',
		params: {
			purpose,
		},
	}),

	create: (data: ICreateNewSupportPageData): THttpRequestConfig => ({
		url: 'operations/pages',
		method: 'POST',
		data,
	}),
	update: ({ pageId, data }: IUpdateNewSupportPageConfig): THttpRequestConfig => ({
		url: `operations/pages/${pageId}`,
		method: 'PUT',
		data,
	}),
	delete: (pageId: number | string): THttpRequestConfig => ({
		url: `operations/pages/${pageId}`,
		method: 'DELETE',
	}),
};
