import { THttpRequestConfig } from 'hooks';
import {
	ICreateOperationUserData,
	IUpdateOperationUserDetailsData,
	IUpdateOperationUserRolesPermissionsData,
} from 'types/api';

export const OPERATIONS_USERS_API = {
	list: (): THttpRequestConfig => ({
		url: 'operations/operations-users',
		method: 'GET',
	}),

	// ! CRUD
	create: (data: ICreateOperationUserData): THttpRequestConfig => ({
		url: 'operations/operations-users',
		method: 'POST',
		data,
	}),

	update: (userId: string | number, data: IUpdateOperationUserDetailsData): THttpRequestConfig => ({
		url: `operations/operations-users/${userId}`,
		method: 'PATCH',
		data,
	}),

	delete: (userId: string | number): THttpRequestConfig => ({
		url: `operations/operations-users/${userId}`,
		method: 'DELETE',
	}),
	// ! ----

	getPermissions: (userId: string | number): THttpRequestConfig => ({
		url: `operations/operations-users/${userId}/permissions`,
		method: 'GET',
	}),

	savePermissions: (userId: string | number, data: IUpdateOperationUserRolesPermissionsData): THttpRequestConfig => ({
		url: `operations/operations-users/${userId}/save-roles-and-permissions`,
		method: 'POST',
		data,
	}),
};
