import { TRouteObject } from 'configs/routes';
import { NewLayout } from './NewLayout';
import VersionMasterSwitchPage from './pages/ABMasterSwitchPage/VersionMasterSwitchPage';

const VERSIONING_ROUTES: TRouteObject = {
	path: 'version-switch-board',
	element: <NewLayout />,
	handle: {
		showOnNav: true,
	},
	children: [
		{
			index: true,
			element: <VersionMasterSwitchPage />,
		},
	],
};

export default VERSIONING_ROUTES;
