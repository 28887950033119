import { FC } from 'react';
import { ICountryFlagImageProps } from './types';

const CountryFlagImage: FC<ICountryFlagImageProps> = ({ flag }) => {
	return (
		<img
			src={flag}
			alt='flag'
			loading='lazy'
			className='flag_img'
		/>
	);
};

export default CountryFlagImage;
