import { IFeatureVariantCurrentSelection, TRouteMatches } from '.';

export const findInvalidConfig = (
	config: IFeatureVariantCurrentSelection,
	mustHaveABs: Array<[string, string | undefined]>
) => mustHaveABs.find(([key, value]) => config[key] !== value);

export const isFeatureAvailableByABVersion = (
	currentSelectedABs: IFeatureVariantCurrentSelection,
	mustHaveABs: IFeatureVariantCurrentSelection = {}
): boolean => {
	if (!currentSelectedABs || !mustHaveABs) return true;

	const mustHaveABsArray = Object.entries(mustHaveABs);

	if (!mustHaveABsArray.length) return true;

	const invalidConfigElem = findInvalidConfig(currentSelectedABs, mustHaveABsArray);

	return !invalidConfigElem;
};

export const isFeatureAvailableByRouteMatchABVersion = (
	currentSelectedABs: IFeatureVariantCurrentSelection,
	matches: TRouteMatches[] = []
): boolean => {
	if (!matches || matches.length === 0) return true;

	const matchesWithHandles: IFeatureVariantCurrentSelection[] = matches
		.map((match) => match?.handle?.mustHaveABs)
		.filter((element?: IFeatureVariantCurrentSelection) => element);

	const matchesThatAllowAB = matchesWithHandles.map((mustHaveABs) =>
		isFeatureAvailableByABVersion(currentSelectedABs, mustHaveABs)
	);

	// if find not valid then allMatches are invalid
	const allMatchesABsAreValid = !matchesThatAllowAB.some((isValid) => !isValid);

	return matchesThatAllowAB.length ? allMatchesABsAreValid : true;
};

export const mergeABConfigurations = Object.assign;
