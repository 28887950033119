import { GENERAL_SETTINGS_API } from 'configs/api';
import { ID_COLUMN_WIDTH } from 'configs/common';
import { IGeneralSettingsItem } from 'types/api';
import TranslatedLabel from 'components/TranslatedLabel';
import { ColumnsType } from 'antd/es/table';

export const GENERAL_SETTINGS_TABLE_REQUEST_CONFIG = GENERAL_SETTINGS_API.list();

export const GENERAL_SETTINGS_COLUMNS: ColumnsType<IGeneralSettingsItem> = [
	{
		key: 'id',
		title: (
			<TranslatedLabel
				nameSpace='general-settings'
				i18nKey='table_columns.id'
			/>
		),
		dataIndex: 'id',
		width: ID_COLUMN_WIDTH,
	},
	{
		key: 'description',
		title: (
			<TranslatedLabel
				nameSpace='general-settings'
				i18nKey='table_columns.description'
			/>
		),
		dataIndex: 'description',
		width: 770,
	},
];

export const GENERAL_SETTINGS_URL_PARAMS = {
	page: '1',
	per_page: '20',
};
