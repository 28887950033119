import { FC, memo } from 'react';
import { useSelector } from 'store';
import { getIntegrationState, getIntegrationTypeSettings } from 'store/selectors/integrations';
import useBranch from 'hooks/useBranch';
import useVendor from 'hooks/useVendor';
import Spinner from 'components/Spinner';
import ErrorPage from 'pages/ErrorPage';
import ErrorPage404 from 'pages/ErrorPage404';
import { useIntegrationController } from 'pages/Vendors/VendorDetails/Tabs/Integration/useIntegrationController';
import { IntegrationSettingsCard } from './IntegrationSettingsCard';

const BranchIntegrationContent: FC = () => {
	const { vendorId, data } = useVendor();
	const { branchId } = useBranch();

	const integrationContextData = useIntegrationController({ vendorId, branchId });

	// ! selectors
	const integrationTypeSettings = useSelector(getIntegrationState);
	const integrationSettings = useSelector(getIntegrationTypeSettings(data?.info.integration_type));

	// ! render

	if (integrationContextData.loading) return <Spinner defaultAntdSpinner />;

	if (!integrationSettings?.isIntegration) return <ErrorPage404 />;

	if (!integrationTypeSettings || !integrationContextData.settings?.type) return <ErrorPage />;

	return (
		<IntegrationSettingsCard
			ctrl={integrationContextData}
			type={integrationContextData.settings.type}
			fields={integrationTypeSettings[integrationContextData.settings.type].branch_edit_settings}
			settings={integrationContextData.settings}
		/>
	);
};

export default memo(BranchIntegrationContent);
