import { PropsWithChildren, createContext, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'store';
import { cleanup, fetchVendorInformation, setData, setIsCariUnlimited, setStatus } from 'store/slices/vendor';
import { VENDOR_API } from 'configs/api';
import { EVendorStatus, IVendor } from 'types/api';
import { Nullable, TEmptyFunction } from 'types/common';
import useGlobalConfigs from './useGlobalConfig';
import useHandleErrors from './useHandleErrors';
import { useNewHttpClient } from './useHttpClient';

interface IVendorContextData {
	vendorId: number;
	data: Nullable<IVendor>;
	error: any;
	isLoading: boolean;

	// handlers
	handleToggleStatus: (id: number, status: EVendorStatus, successCallback?: TEmptyFunction) => Promise<void>;
	fetchData: () => void;

	setData: (data: IVendor) => void;
	setIsCariUnlimited: (payload: boolean) => void;
}

const VendorContext = createContext<IVendorContextData>({} as IVendorContextData);

const useVendor = () => {
	return useContext(VendorContext);
};

const VendorProvider = ({ children }: PropsWithChildren) => {
	const dispatch = useDispatch();
	const { http } = useGlobalConfigs();
	const { handleError } = useHandleErrors();
	const { vendorId } = useParams();

	// ! selectors
	const data = useSelector((state) => state.vendor.data);
	const error = useSelector((state) => state.vendor.error);
	const isLoading = useSelector((state) => state.vendor.loading);

	// ! http clients
	const toggleStatusHttpClient = useNewHttpClient();

	// ! handlers
	const handleToggleStatus = (id: number, status: EVendorStatus, successCallback?: () => void) => {
		return toggleStatusHttpClient.request({
			requestConfig: VENDOR_API.update(id, { status }),
			successCallback: () => {
				successCallback?.();

				dispatch(setStatus(status));
			},
		});
	};

	const fetchData = () => {
		if (!vendorId) return;

		dispatch(fetchVendorInformation({ id: +vendorId, http, handleError }));
	};

	const setDataHandle = (data: IVendor) => {
		dispatch(setData(data));
	};
	const setIsCariUnlimitedHandle = (payload: boolean) => {
		dispatch(setIsCariUnlimited(payload));
	};

	// ! useEffects
	useEffect(() => {
		fetchData();

		return () => {
			dispatch(cleanup());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vendorId]);

	// ! render
	if (!vendorId) return null;

	const vendorData = {
		vendorId: +vendorId,
		data,
		error,
		isLoading,

		// * handlers
		handleToggleStatus,
		fetchData,
		setData: setDataHandle,
		setIsCariUnlimited: setIsCariUnlimitedHandle,
	};

	return <VendorContext.Provider value={vendorData}>{children}</VendorContext.Provider>;
};

export default useVendor;
export { VendorProvider, useVendor };
