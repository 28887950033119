import { ReactNode } from 'react';
import { EOrderPaymentMethods } from 'types/api';
import { AppleOutlined, CreditCardOutlined, DollarOutlined, StopOutlined, WalletOutlined } from '@ant-design/icons';

// ! payment types & status
export const ORDER_PAYMENT_TYPES_ICONS: Record<EOrderPaymentMethods, ReactNode> = {
	[EOrderPaymentMethods.CASH]: <DollarOutlined />,
	[EOrderPaymentMethods.CREDIT_CARD]: <CreditCardOutlined />,
	[EOrderPaymentMethods.WALLET]: <WalletOutlined />,
	[EOrderPaymentMethods.KNET]: <CreditCardOutlined />,
	[EOrderPaymentMethods.MADA]: <CreditCardOutlined />,
	[EOrderPaymentMethods.APPLE_PAY]: <AppleOutlined />,
	[EOrderPaymentMethods.UAE_CC]: <CreditCardOutlined />,
	[EOrderPaymentMethods.BANK_ACCOUNT]: <CreditCardOutlined />,
	[EOrderPaymentMethods.PROMOTION]: <DollarOutlined />,
	[EOrderPaymentMethods.NO_PAYMENT_METHOD]: <StopOutlined />,
};
