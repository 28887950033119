import { THttpRequestConfig } from 'hooks';
import { ICreateVehicleTypeConfig, IUpdateVehicleTypeConfig } from 'types/api';

export const VEHICLE_TYPES_API = {
	list: (search?: string): THttpRequestConfig => ({
		url: 'operations/vehicle-types',
		method: 'GET',
		params: {
			search,
		},
	}),
	create: (data: ICreateVehicleTypeConfig): THttpRequestConfig => ({
		url: 'operations/vehicle-types',
		method: 'POST',
		data,
	}),

	update: ({ vehicleTypeId, data }: IUpdateVehicleTypeConfig): THttpRequestConfig => ({
		url: `${'operations/vehicle-types'}/${vehicleTypeId}`,
		method: 'PATCH',
		data,
	}),

	toggleStatus: (vehicleTypeId: number, statusType: 'activate' | 'deactivate'): THttpRequestConfig => ({
		url: `${'operations/vehicle-types'}/${vehicleTypeId}/${statusType}`,
		method: 'PATCH',
	}),

	turnOffDrivers: (vehicle_type_id: number): THttpRequestConfig => ({
		url: 'operations/drivers/take-offline',
		method: 'POST',
		data: {
			vehicle_type_id,
		},
	}),
};
