import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { IErrorPageProps } from './types';
import { DEFAULT_STATUS } from './config';
import { Result } from 'antd';

export const ErrorPage: FC<IErrorPageProps> = memo(({ title, subTitle, status }) => {
	const { t: tError } = useTranslation('error-page');

	return (
		<Result
			status={status ?? DEFAULT_STATUS}
			title={title ?? tError('general_error')}
			subTitle={subTitle}
		/>
	);
});
