import { ICountry } from './countries';

// ! enums
export enum ERoleVisibilityStatus {
	HIDDEN = 'hidden',
	VISIBLE = 'visible',
}

export interface IRoleItem {
	id: number;
	name: string;
	description: string;
	description_ar: string;
	visibility_status: ERoleVisibilityStatus;
	country_id: ICountry['id'];
}

export interface IAddRoleForm {
	name: string;
	description: string;
	visibility_status: ERoleVisibilityStatus;
}

// * ADD USER WITH ROLES
export interface IAddRoleDataConfig extends IAddRoleForm {
	permission_ids: string[];
}
