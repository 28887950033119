import { lazy } from 'react';
import { LIST_DEFAULT_PARAMS } from 'configs/api';
import { APP_PERMISSIONS, SUPER_ADMIN_PERMISSION } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import { FileOutlined } from '@ant-design/icons';

const SupportPages = lazy(() => import('pages/SupportPages'));
const AddEditSupportPage = lazy(() => import('pages/SupportPages/AddEditSupportPage'));

export const SUPPORT_PAGES_ROUTES: TRouteObject = {
	path: 'support-pages',
	element: <ListParamsRouteWrapper key='support-pages' />,
	handle: {
		translationKey: 'support_pages',
		icon: <FileOutlined />,
		permissions: [APP_PERMISSIONS.support_page.view],
		defaultListParams: LIST_DEFAULT_PARAMS,
		showOnNav: true,
	},
	children: [
		{
			index: true,
			element: <SupportPages />,
		},
		{
			path: 'new',
			element: <AddEditSupportPage />,
			handle: {
				permissions: [SUPER_ADMIN_PERMISSION], // * TEMP @ricardo
				// permissions: [APP_PERMISSIONS.support_page.add],
				showOnNav: false,
			},
		},
		{
			path: 'edit/:supportPageId',
			element: <AddEditSupportPage />,
			handle: {
				permissions: [SUPER_ADMIN_PERMISSION], // * TEMP @ricardo
				// permissions: [APP_PERMISSIONS.support_page.update],
				showOnNav: false,
			},
		},
	],
};
