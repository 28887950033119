import { AxiosResponse } from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { errorCase, pendingCase } from 'store/helpers';
import { THttpFunction } from 'utils/axiosInstance';
import { THandleError } from 'utils/handleError';
import { ROOT_API } from 'configs/api';
import { EHttpStatus, ICountry } from 'types/api';
import { Nullable } from 'types/common';
import { IActionArg, IDefaultStateFields } from '../types';
import { AppError } from 'exceptions/AppError';

// ! initial state
export interface ICountriesSlice extends IDefaultStateFields {
	countriesList: ICountry[];

	accessibleCountries: Array<ICountry['id']>;

	error: Nullable<any>;
}

const countriesSliceInitialState: ICountriesSlice = {
	countriesList: [],
	accessibleCountries: [],

	// own state
	error: null,
	loading: false,
};

export const fetchCountries = createAsyncThunk<
	ICountry[],
	{
		http: THttpFunction;
		handleError: THandleError;
	},
	{ state: RootState }
>(
	'countriesSlice/fetchCountries',
	async ({ http, handleError }, { rejectWithValue }) => {
		try {
			const response: AxiosResponse<Array<ICountry>> = await http(ROOT_API.getCountries());

			const countriesList = response.data;

			return countriesList;
		} catch (error) {
			handleError(new AppError(undefined, EHttpStatus.UNAUTHORIZED), true);

			return rejectWithValue(error);
		}
	},
	{
		condition: (_, { getState }) => {
			const { countries }: RootState = getState();

			if (countries.loading) {
				// Already fetched or in progress, don't need to re-fetch
				return false;
			}
		},
	}
);

const countriesSlice = createSlice({
	name: 'countriesSlice',
	initialState: countriesSliceInitialState,
	reducers: {
		setAccessibleCountriesList: (state, action: IActionArg<Array<ICountry['id']>>) => {
			state.accessibleCountries = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchCountries.pending, pendingCase)
			.addCase(fetchCountries.rejected, (state, { payload }) => {
				state.error = payload;
				errorCase(state);
			})
			.addCase(fetchCountries.fulfilled, (state, { payload }) => {
				state.countriesList = payload;
				state.loading = false;
			});
	},
});

export const { setAccessibleCountriesList } = countriesSlice.actions;

export default countriesSlice.reducer;
