import { lazy } from 'react';
import { LIST_DEFAULT_PARAMS } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import { CarOutlined } from '@ant-design/icons';

const VehicleTypes = lazy(() => import('pages/VehicleTypes'));

export const VEHICLE_TYPES_ROUTES: TRouteObject = {
	path: 'vehicle-types',
	element: (
		<ListParamsRouteWrapper key='vehicle-types'>
			<VehicleTypes />
		</ListParamsRouteWrapper>
	),
	handle: {
		translationKey: 'vehicle_types',
		permissions: [APP_PERMISSIONS.vehicle_type.view],
		icon: <CarOutlined />,
		defaultListParams: LIST_DEFAULT_PARAMS,
		showOnNav: true,
	},
};
