import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'store';
import { getSettingsSlice } from 'store/selectors';
import { getIntegrationTypeSettings } from 'store/selectors/integrations';
import { DEFAULT_EMPTY_VALUE_PLACEHOLDER } from 'configs/common';
import { IIntegrationField } from 'types/api';
import { EChoiceOption, EStatus, ESupportedLanguages } from 'types/common';
import { IIntegrationSettingsCardProps } from './types';
import { CopyToClipBoard } from 'components/CopyToClipBoard';
import Spinner from 'components/Spinner';
import { ActiveStatusTag } from 'components/Tags/ActiveStatusTag';
import TranslatedLabel from 'components/TranslatedLabel';
import { App, Button, Card, Col, Descriptions, Form, Input, Row, Space } from 'antd';

export const IntegrationSettingsCard: FC<IIntegrationSettingsCardProps> = ({ type, settings, fields, ctrl }) => {
	const { message } = App.useApp();
	const [form] = Form.useForm<Record<string, string>>();

	const { t: tIntegrations } = useTranslation('integrations');

	const hasEditable = useMemo(
		() => fields.some((field: IIntegrationField) => field.readonly === EChoiceOption.NO),
		[fields]
	);

	// ! selectors
	const { language: selectedAppLanguage } = useSelector(getSettingsSlice);
	const integrationSettings = useSelector(getIntegrationTypeSettings(type));

	// ! handlers
	const onFormSubmit = async (formValues: Record<string, string>) => {
		if (!integrationSettings?.isIntegration) return;

		ctrl.handleSaveBranchSettings(formValues)?.then(() => {
			message.success(tIntegrations('update.success', { integration_type: integrationSettings.name }), 3);
		});
	};

	if (!integrationSettings?.isIntegration) return null;

	// ! render
	return (
		<>
			{ctrl.loading && <Spinner defaultAntdSpinner />}

			<Card
				title={tIntegrations('card_title', { integration_type: integrationSettings.name })}
				size='small'
				extra={
					<Space>
						{tIntegrations('shared.active_status')}
						<ActiveStatusTag status={settings.is_active ? EStatus.ACTIVE : EStatus.INACTIVE} />
					</Space>
				}
			>
				<Form<Record<string, string>>
					form={form}
					onFinish={onFormSubmit}
				>
					<Row gutter={[8, 8]}>
						<Col span={12}>
							<Descriptions
								layout='vertical'
								column={1}
							>
								{fields.map((field: IIntegrationField, index: number) => {
									const fieldKey = field.key;
									const value = settings[fieldKey];

									const readOnlyValue = value ? (
										<CopyToClipBoard>{value}</CopyToClipBoard>
									) : (
										DEFAULT_EMPTY_VALUE_PLACEHOLDER
									);

									return (
										<Descriptions.Item
											key={index}
											label={
												selectedAppLanguage === ESupportedLanguages.ARABIC
													? field.label_ar
													: field.label
											}
										>
											{field.readonly === EChoiceOption.YES && readOnlyValue}

											{field.readonly === EChoiceOption.NO && (
												<Form.Item
													name={fieldKey}
													className='w-100'
													initialValue={value}
													rules={[{ required: true }]}
												>
													<Input />
												</Form.Item>
											)}
										</Descriptions.Item>
									);
								})}
							</Descriptions>
						</Col>
						{hasEditable && (
							<Col
								span={12}
								className='flex justify-end'
							>
								<Button
									type='primary'
									size='middle'
									htmlType='submit'
								>
									<TranslatedLabel i18nKey='action_buttons.save' />
								</Button>
							</Col>
						)}
					</Row>
				</Form>
			</Card>
		</>
	);
};
