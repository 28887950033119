import { generatePath } from 'react-router-dom';
import { LocalTimeDate } from 'hooks/useLocaleTimeUtils';
import { COMPENSATION_REQUEST_API } from 'configs/api/compensationRequest';
import { ID_COLUMN_WIDTH } from 'configs/common';
import { APP_PERMISSIONS } from 'configs/permissions';
import { ABSOLUTE_ROUTES } from 'configs/routes';
import LinkButton from 'components/LinkButton';
import CompensationRequestStatusTag from 'components/Tags/CompensationRequestStatusTag';
import TranslatedLabel from 'components/TranslatedLabel';
import { ICompensationTableData } from 'pages/CompensationRequests/types';
import { ColumnsType } from 'antd/es/table';
import { ColumnFilterItem } from 'antd/es/table/interface';
import { UserOutlined } from '@ant-design/icons';

export const COMPENSATION_REQUEST_TABLE_REQUEST_CONFIG = COMPENSATION_REQUEST_API.list();

// ! config details
export const COMPENSATION_REQUEST_STATUS_FILTERS: ColumnFilterItem[] = [
	{
		text: (
			<TranslatedLabel
				nameSpace='compensation-requests'
				i18nKey='status.pending'
			/>
		),
		value: 'pending',
	},
	{
		text: (
			<TranslatedLabel
				nameSpace='compensation-requests'
				i18nKey='status.refunded'
			/>
		),
		value: 'refunded',
	},
	{
		text: (
			<TranslatedLabel
				nameSpace='compensation-requests'
				i18nKey='status.rejected'
			/>
		),
		value: 'rejected',
	},
];

export const COMPENSATION_REQUEST_PAYMENT_METHOD_FILTERS: ColumnFilterItem[] = [
	{
		text: (
			<TranslatedLabel
				nameSpace='compensation-requests'
				i18nKey='payment_method.wallet'
			/>
		),
		value: 'wallet',
	},
	{
		text: (
			<TranslatedLabel
				nameSpace='compensation-requests'
				i18nKey='payment_method.bank_account'
			/>
		),
		value: 'bank_account',
	},
	{
		text: (
			<TranslatedLabel
				nameSpace='compensation-requests'
				i18nKey='payment_method.promocode'
			/>
		),
		value: 'promocode',
	},
];

// ! config
export const COMPENSATION_TABLE_COLUMNS = (countryIsoTwoCode: string): ColumnsType<ICompensationTableData> => [
	{
		key: 'id',
		title: (
			<TranslatedLabel
				nameSpace='compensation-requests'
				i18nKey='id'
				extra={{ keyPrefix: 'shared.entity' }}
			/>
		),
		dataIndex: 'id',
		width: ID_COLUMN_WIDTH,
	},
	{
		key: 'date',
		title: (
			<TranslatedLabel
				nameSpace='compensation-requests'
				i18nKey='date'
				extra={{ keyPrefix: 'shared.entity' }}
			/>
		),
		width: 200,
		render: (_, { date }) => <LocalTimeDate date={date} />,
	},
	{
		key: 'customer_name',
		title: (
			<TranslatedLabel
				nameSpace='compensation-requests'
				i18nKey='customer_name'
				extra={{ keyPrefix: 'shared.entity' }}
			/>
		),
		ellipsis: true,
		render: (_, { customer }) => (
			<LinkButton
				label={customer.name}
				icon={<UserOutlined />}
				permissions={APP_PERMISSIONS.customer.view}
				to={generatePath(ABSOLUTE_ROUTES.USER_CUSTOMER_DETAILS, { countryIsoTwoCode, customerId: customer.id })}
			/>
		),
	},
	{
		key: 'order_code',
		title: (
			<TranslatedLabel
				nameSpace='compensation-requests'
				i18nKey='order_code'
				extra={{ keyPrefix: 'shared.entity' }}
			/>
		),
		render: (_, { order }) => (
			<LinkButton
				to={generatePath(ABSOLUTE_ROUTES.ORDER_DETAILS, { countryIsoTwoCode, orderId: order.id })}
				label={order.code}
				permissions={APP_PERMISSIONS.order.view}
			/>
		),
	},
	{
		key: 'refund_payment_method_name',
		title: (
			<TranslatedLabel
				nameSpace='compensation-requests'
				i18nKey='refund_payment_method'
				extra={{ keyPrefix: 'shared.entity' }}
			/>
		),
		width: 200,
		filters: COMPENSATION_REQUEST_PAYMENT_METHOD_FILTERS,
		render: (_, { refund_payment_method }) => (
			<TranslatedLabel
				nameSpace='compensation-requests'
				i18nKey={`payment_method.${refund_payment_method}`}
			/>
		),
	},
	{
		key: 'status',
		title: (
			<TranslatedLabel
				nameSpace='compensation-requests'
				i18nKey='status'
				extra={{ keyPrefix: 'shared.entity' }}
			/>
		),
		render: (_, { status }) => <CompensationRequestStatusTag status={status} />,
		width: 120,
		filters: COMPENSATION_REQUEST_STATUS_FILTERS,
	},
];
