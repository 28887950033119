import { THttpRequestConfig } from 'hooks';
import { IConfirmCompensationRequestData, IRejectCompensationRequestData } from 'types/api/compensationRequest';

export const COMPENSATION_REQUEST_API = {
	list: (customer_id?: React.Key): THttpRequestConfig => ({
		url: 'operations/compensation-request',
		method: 'GET',
		params: { customer_id },
	}),

	get: (id: React.Key): THttpRequestConfig => ({
		url: `operations/compensation-request/${id}`,
		method: 'GET',
	}),

	reject: (id: React.Key, data: IRejectCompensationRequestData): THttpRequestConfig => ({
		url: `operations/compensation-request/${id}/reject`,
		method: 'POST',
		data,
	}),

	confirm: (id: React.Key, data: IConfirmCompensationRequestData): THttpRequestConfig => ({
		url: `operations/compensation-request/${id}/confirm`,
		method: 'POST',
		data,
	}),
};
