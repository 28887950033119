import { lazy } from 'react';
import { LIST_DEFAULT_PARAMS } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import { UserOutlined } from '@ant-design/icons';

const ManageUsers = lazy(() => import('pages/ManageUsersAndRoles/ManageUsers'));

export const MANAGE_USERS_ROUTES: TRouteObject = {
	path: 'users',
	element: (
		<ListParamsRouteWrapper key='manage-users'>
			<ManageUsers />
		</ListParamsRouteWrapper>
	),
	handle: {
		translationKey: 'manage_users',
		icon: <UserOutlined />,
		permissions: [APP_PERMISSIONS.operations_user.view],
		defaultListParams: LIST_DEFAULT_PARAMS,
		showOnNav: true,
	},
};
